import React, {FunctionComponent} from 'react';
import Comment from "../../models/Comment";
import Avatar from "../Avatar";
import ElementWithTooltip from "../ElementWithTooltip";
import {timeAgo} from "../../utils";
import {useHistory} from "react-router-dom";
import {tripViewerPath} from "../../helpers/constants";

interface OwnProps {
  tripId?: string
  comment: Comment
  tooltipPosition?: "top" | "bottom"
  showReferencedWaypoint?: boolean
}

type Props = OwnProps;

const CommentCard: FunctionComponent<Props> = (props) => {
  const history = useHistory()
  const date = props.comment.creationDate.toDate();
  const tooltipPosition = props.tooltipPosition ?? "top";

  const handleViewCommentInContext = () => {
    history.push(`${tripViewerPath}?id=${props.tripId}&w=${props.comment.waypointId}&c=true`)
  }
  return (
    <div className="flex flex-row items-start space-x-2">
      <Avatar userId={props.comment.authorId} size="sm"/>
      <div className="flex flex-col items-end">
        <ElementWithTooltip
          tooltip={timeAgo(date)}
          tooltipPosition={tooltipPosition}
          offsetY={tooltipPosition === "top" ? "0.5rem" : "1.5rem"}
          tooltipAlign="left"
          key={props.comment.id}
          className="px-4 py-2 bg-gray-100 rounded-xl relative"
        >
          <div className="absolute top-0 -left-2 w-6 overflow-hidden inline-block">
            <div className="h-6 w-6 bg-gray-100 -rotate-45 transform origin-top-left rounded-lg"/>
          </div>
          {props.comment.text}
        </ElementWithTooltip>
        {props.showReferencedWaypoint && props.comment.waypointId && (
          <div
            onClick={handleViewCommentInContext}
            className="text-xs cursor-pointer hover:text-blue-500"
          >
            view in context
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentCard;
