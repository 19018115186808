import React, {FunctionComponent, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import firebase from "firebase/app";
import SignInWithGoogleButton from "./SignInWithGoogleButton";
import usePasswordValidation from "../../hooks/usePasswordValidation";
import {auth, db} from "../../firebase";
import Heading from "../../components/Heading";
import Card from "../../components/Card";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import Transition from "../../components/Transition";
import useStore from "../../stores/store";
import {homePath, loginPath, Phase, tripsikPhase} from "../../helpers/constants";
import {useAuthState} from "react-firebase-hooks/auth";

interface OwnProps {
  onSignup: () => void
}

type Props = OwnProps;

const SignUpForm: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const setFBUser = useStore(state => state.setFBUser);
  const [isNewUser, setIsNewUser] = useState(false);
  const [user, isLoadingUser,] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<firebase.FirebaseError>();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError] = usePasswordValidation(password);

  // Deal with a connected user that navigated to the sign up page
  useEffect(() => {
    if (user && !isNewUser) {
      history.push(homePath);
    }
  }, [user, isNewUser]);

  if (isLoadingUser && !isNewUser) {
    return <Spinner className="text-primary"/>
  }

  const signup = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setIsNewUser(true) // if true, a redirect to the home page won't automatically happen
    auth.createUserWithEmailAndPassword(email, password).then(async (user) => {
      if (user.user) {
        try {
          await user.user.sendEmailVerification();
          const userPath = `/users/${user.user.uid}`;
          await db.doc(userPath).set({
            signUpDate: firebase.firestore.FieldValue.serverTimestamp(),
            planType: "free",
            email: email,
            name: name
          });
          setFBUser(user.user);
          props.onSignup()
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      }
    }).catch(error => {
      setError(error);
      setLoading(false);
    });
  }

  const signupWithGoogle = () => {
    setIsNewUser(true) // if true, a redirect to the home page won't automatically happen
    let provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider).then(async (result) => {
      try {
        const user = result.user;
        if (user) {
          const userPath = `/users/${user.uid}`;
          await db.doc(userPath).set({
            signUpDate: firebase.firestore.FieldValue.serverTimestamp(),
            planType: "free",
            email: user.email,
            name: user.displayName
          });
          setFBUser(user);
          props.onSignup();
          history.push(homePath);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }).catch((error) => {
      setError(error);
      setLoading(false);
    });
  }


  return (
    <>
      <Transition uniqueKey="title" type="slide-up" isVisible>
        <Heading>
          Let's sign you up
        </Heading>
      </Transition>
      <Card>
        <form onSubmit={signup} className="flex flex-col space-y-4 items-stretch">
          <div className="flex flex-col items-stretch space-y-1">
            <Input
              type="text"
              placeholder="Your full name"
              value={name}
              onChange={e => setName(e.target.value)}
              required
              autoFocus
              disabled={loading}
              maxLength={40}
            />
          </div>
          <div className="flex flex-col items-stretch space-y-1">
            <Input
              type="email"
              placeholder="Your email address"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
              disabled={loading}
              error={error?.message ?? undefined}
              onResetError={() => setError(undefined)}
            />
          </div>
          <div className="flex flex-col items-stretch space-y-1">
            <Input
              type="password"
              placeholder="Set a password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
              disabled={loading}
              pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
              error={passwordError ?? undefined}
            />
          </div>
          <Button
            type="submit"
          >
            {
              loading ? (
                <>
                  <Spinner/>
                  <div>
                    Loading
                  </div>
                </>
              ) : "Sign up"
            }
          </Button>
          <div className="flex flex-row justify-center items-center">
            <div className="border-solid border-t border-gray-300 flex-1"/>
            <div className="flex flex-col items-center justify-center px-4 text-gray-500">
              or sign up with
            </div>
            <div className="border-solid border-t border-gray-300 flex-1"/>
          </div>
          <div className="flex flex-row justify-center items-center">
            <SignInWithGoogleButton onClick={signupWithGoogle}/>
          </div>
        </form>
        <div className="text-sm text-center">
          <div>
            Already have an account?
          </div>
          <div
            onClick={() => history.push(loginPath)}
            className="text-purple-dark cursor-pointer hover:text-purple"
          >
            Log in now
          </div>
        </div>
      </Card>
    </>
  );
};

export default SignUpForm;
