import React, {FunctionComponent} from 'react';

interface OwnProps {
  isVisible?: boolean
  onClick?: () => void
}

type Props = OwnProps;

const BackgroundOverlay: FunctionComponent<Props> = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={`absolute inset-0 bg-black bg-opacity-25 z-10 transition-all transform duration-500 
                  cursor-pointer ${props.isVisible ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"}`}/>
  );
};

export default BackgroundOverlay;
