import React, { FunctionComponent } from 'react';

interface OwnProps {
  icon?: any
  label?: string
  onClick?: () => void
  isActive?: boolean
}

type Props = OwnProps;

const UserMenuItem: FunctionComponent<Props> = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={`w-full transition-all transform duration-300 rounded-lg p-4 
            flex flex-col justify-center items-center space-y-3 group select-none
            ${props.isActive ? "border-primary border shadow" : "cursor-pointer hover:bg-offWhite"}`}
    >
      <div className="text-primary group-hover:scale-105 transform transition-all duration-300">
        {props.icon}
      </div>
      <div className="text-sm group-hover:scale-105 transform transition-all duration-300 select-none">
        {props.label}
      </div>
    </div>
  );
};

export default UserMenuItem;
