import React, { FunctionComponent } from 'react';
import Button from "../../components/Button";
import ProgressBar from "../../components/ProgressBar";
import {motion} from "framer-motion"

interface OwnProps {
  onNext?: (e: React.MouseEvent) => void
  progress: number
  totalFinishedFiles: number
  totalFiles: number
}

type Props = OwnProps;

const MediaUploaderCallToAction: FunctionComponent<Props> = (props) => {
  return (
    <motion.div
      layout="position"
      className="overflow-none backdrop-filter backdrop-blur-sm bg-opacity-90 bg-white p-10
                    flex flex-col rounded-lg justify-center items-center cursor-pointer text-center space-y-4 w-80
                    pointer-events-auto"
    >
      <p className="font-sans text-lg">Drag your trip photos here</p>
      <div>or</div>
      <Button outlined>
        Choose photos to upload
      </Button>
      {
        props.progress < 1 && (
          <ProgressBar progress={props.progress} smooth/>
        )
      }
      {
        props.totalFiles > 0 && (
          <div className="text-sm text-gray-500">
            {props.totalFinishedFiles}/{props.totalFiles} files
          </div>
        )
      }
      {
        props.onNext && (
          <div>
            <Button onClick={props.onNext} className="mt-4 flex flex-row pr-3">
              Continue
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </Button>
          </div>
        )
      }
    </motion.div>
  );
};

export default MediaUploaderCallToAction;
