import React, {FunctionComponent, useState} from 'react';
import Input from "../../components/Input";
import Button from "../../components/Button";

interface OwnProps {
  onReturn: () => void
  onCreateTrip: (tripName: string) => void
}

type Props = OwnProps;

const TripNameForm: FunctionComponent<Props> = (props) => {
  const [tripName, setTripName] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    props.onCreateTrip(tripName)
  }

  return (
    <div
      className={`absolute inset-0 flex flex-col items-center justify-center overflow-hidden p-10 z-30`}
      onClick={props.onReturn}
    >
      <form
        className="overflow-none bg-opacity-90 bg-white p-10
                    flex flex-col rounded-lg justify-center items-center cursor-pointer text-center space-y-4 w-80
                    pointer-events-auto"
        onClick={e => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <Input
          type="text"
          value={tripName}
          onChange={(e) => setTripName(e.target.value)}
          required
          autoFocus
          className="text-2xl"
          placeholder="Your trip name"
          label="Name your trip"
          labelClassName="text-xl"
          hint="Don't worry you can always change this later"
        />
        <Button type="submit" className="flex flex-row pr-3">
          Create your trip
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </Button>
      </form>
    </div>
  );
};

export default TripNameForm;
