import React, {FunctionComponent, useEffect} from 'react';
import Logo from "../../components/Logo";
import SmallTripCard from "./SmallTripCard";
import {useLocation} from "react-use";
import UserCard from "./UserCard";
import {useHistory} from "react-router-dom";
import useStore from "../../stores/store";
import TripCardsSlider from "./TripCardsSlider";
import FirstTripCTA from "./FirstTripCTA";
import {homePath} from "../../helpers/constants";
import {preventPullToRefresh} from "../../helpers/utils";

interface OwnProps {
  uid?: string
}

type Props = OwnProps;

const UserTrips: FunctionComponent<Props> = (props) => {
  const history = useHistory()
  const location = useLocation()
  let requestedUserId: string | null | undefined = new URLSearchParams(location.search).get("id")
  const [
    uid,
    loadUserTripsFromDB,
    allTrips,
    setSelectedWaypoint
  ] = useStore(state => [
    state.uid,
    state.loadUserTripsFromDB,
    state.allTrips,
    state.setSelectedWaypoint
  ])
  requestedUserId = requestedUserId ?? uid;

  // prevent pull to refresh
  useEffect(preventPullToRefresh, [])

  useEffect(() => {
    setSelectedWaypoint(-1);
    if (requestedUserId && (!allTrips || (allTrips.length > 0 && allTrips[0].authorId !== requestedUserId))) {
      loadUserTripsFromDB(requestedUserId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTrips, requestedUserId])

  return (
    <div className="absolute inset-0" tabIndex={0}>
      <Logo
        height={30}
        className="text-white fixed left-4 sm:left-10 top-8 sm:top-10 z-40"
        hasShadow
        onClick={() => history.push(homePath)}
      />
      {
        allTrips !== undefined && allTrips.length === 0 && (
          <div className="absolute inset-0 z-10">
            <FirstTripCTA/>
          </div>
        )
      }
      <div className="absolute bottom-2 left-0 right-0 z-20 ">
        <div className="flex px-4">
          <UserCard
            userId={requestedUserId ?? ""}
            trips={allTrips ?? []}
          />
        </div>
        <TripCardsSlider userTrips={allTrips}/>
      </div>
    </div>
  );
};

export default UserTrips;
