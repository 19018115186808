import {animate} from 'framer-motion';
import React, {ForwardedRef, FunctionComponent, MutableRefObject, useEffect, useRef} from 'react';

interface OwnProps {
  className?: string
  ref?: ForwardedRef<any>
}

type Props = OwnProps;


const HorizontalScroller: FunctionComponent<Props> = React.forwardRef((props, forwardedRef) => {
  let containerRef = useRef<any>(null);
  if (forwardedRef != null) {
    containerRef = forwardedRef as MutableRefObject<any>;
  }

  const updateScrollPosition = (e: React.WheelEvent) => {
    // update the scroll position
    const maxScrollExtent = containerRef.current?.scrollWidth - containerRef.current?.clientWidth;
    const scrollDelta = e.deltaY * 5;
    if ((scrollDelta > 0 && containerRef.current.scrollLeft <= maxScrollExtent - 10)
      || (scrollDelta < 0 && containerRef.current.scrollLeft >= 10)) {
      e.stopPropagation();
      e.preventDefault();
    }
    animate(
      containerRef!.current!.scrollLeft,
      containerRef!.current!.scrollLeft + scrollDelta,
      {
        onUpdate: scroll => containerRef!.current!.scrollLeft = scroll,
      }
    )
  }


  useEffect(() => {
    if (containerRef && containerRef.current) {
      const containerRefCurrent = containerRef.current!;
      containerRefCurrent.addEventListener("wheel", updateScrollPosition, false);
      return function cleanup() {
        containerRefCurrent.removeEventListener("wheel", updateScrollPosition, false);
      };
    }
  }, [containerRef.current]);

  return (
    <div
      ref={containerRef}
      className={`${props.className} overflow-x-auto transition-all`}
    >
      {props.children}
    </div>
  );
});

export default HorizontalScroller;
