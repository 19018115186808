import create, {State} from 'zustand'
import {storage} from "../firebase";
import CustomFile from "../models/CustomFile";
import MediaFile from "../models/MediaFile";


interface useFileStoreInterface extends State {
  uploadedFiles: { [key: string]: CustomFile | MediaFile }
  setUploadedFiles: (uploadedFiles: { [key: string]: CustomFile | MediaFile }) => void
  uploadProgress: { [key: string]: number }
  setUploadProgress: (progress: { [key: string]: number }) => void
  setFile: (file: any) => void,
  setFileProperties: (uniqueId: string, properties: any) => void,
  removeFile: (uniqueId: string) => void
  getFile: (uniqueId: string) => any,
  releaseFileObjectURL: (fileUniqueId: string) => void
}


const useFileStore = create<useFileStoreInterface>((set, get) => ({
  uploadedFiles: {},
  setUploadedFiles: (uploadedFiles: any) => {
    Object.keys(uploadedFiles).forEach(uniqueId => {
      // convert the file to a regular object since otherwise each time we copy it, it will lose all the data
      const file = uploadedFiles[uniqueId];
      uploadedFiles[uniqueId] = {
        ...file,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        uniqueId: file.uniqueId
      }
    })
    set({ uploadedFiles })
  },
  uploadProgress: {},
  setUploadProgress: (uploadProgress: any) => set({ uploadProgress }),
  setFile: (file: any) => {
    const uploadedFiles = {...get().uploadedFiles};
    uploadedFiles[file.uniqueId] = file;
    set({ uploadedFiles })
  },
  setFileProperties: (uniqueId: string, properties: any) => {
    let newFile = get().getFile(uniqueId);
    newFile = {...newFile, ...properties}
    get().setFile(newFile);
  },
  removeFile: (uniqueId: string) => {
    const uploadedFiles = {...get().uploadedFiles};
    const file = uploadedFiles[uniqueId];
    file.storagePath && storage.ref(file.storagePath).delete();
    file.thumbnailStoragePath && storage.ref(file.thumbnailStoragePath).delete();
    delete uploadedFiles[uniqueId];
    const uploadProgress = {...get().uploadProgress};
    delete uploadProgress[uniqueId]
    set({ uploadedFiles, uploadProgress })
  },
  getFile: (uniqueId: string) => {
    return get().uploadedFiles[uniqueId]
  },
  releaseFileObjectURL: (fileUniqueId: string) => {
    let file = get().getFile(fileUniqueId);
    if (file) URL.revokeObjectURL(file.preview);
  }
}))

export default useFileStore;