import React, { FunctionComponent } from 'react';

interface OwnProps {
  onClick: () => void
}

type Props = OwnProps;

const NextTripButton: FunctionComponent<Props> = (props) => {
  return (
    <div
      onClick={props.onClick}
      className="bg-white rounded-full h-10 w-10 flex items-center justify-center cursor-pointer pointer-events-auto"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    </div>
  );
};

export default NextTripButton;
