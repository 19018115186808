import React, {FunctionComponent, useEffect} from 'react';
import Avatar from "../../components/Avatar";
import Trip from "../../models/Trip";
import ElementWithTooltip from "../../components/ElementWithTooltip";
import useStore from "../../stores/store";

interface OwnProps {
  userId?: string
  trips: Array<Trip>
}

type Props = OwnProps;

const UserCard: FunctionComponent<Props> = (props) => {
  const [
    userDetails,
    loadUserDetails
  ] = useStore(state => [
    state.userDetails,
    state.loadUserDetails
  ]);
  useEffect(() => {
    if (props.userId && !(props.userId in userDetails)) {
      loadUserDetails(props.userId)
    }
  }, [userDetails, loadUserDetails, props.userId])

  if (!props.userId) {
    return <div/>
  }
  const countries: Array<string> = []//["CR - Costa Rica", "GT - Guatemala", "US - United States", "NL - Netherlands", "FR - France"]
  return (
    <div className="group bg-white rounded-tl-[2rem] rounded-bl-[2rem] rounded-tr-lg rounded-br-lg hover:rounded-bl-lg
            px-1 py-1 w-90 flex flex-col shadow-lg backdrop-filter backdrop-blur bg-opacity-75 transform
            transition-all duration-300 group">
      {/**/}
      <div className="flex items-center">
        <Avatar
          userId={props.userId}
          size="md"
        />
        <div className="flex flex-col px-4">
          <div className="text-lg">
            {userDetails[props.userId]?.name}
          </div>
          <div className="capitalize text-sm text-gray-500">
            {props.trips.length} trips
          </div>
        </div>
      </div>
      <div className="h-[0px] group-hover:pt-4 group-hover:h-20 group-hover:p-2 transition-all ease-in-out duration-500">
        <div className="invisible group-hover:visible opacity-0 group-hover:opacity-100 group-hover:delay-300
                  group-hover:duration-300 delay-0 duration-0 transition-all grid grid-cols-4 gap-2">
          {
            countries.map(country => (
              <ElementWithTooltip
                key={country}
                tooltip={country}
                tooltipPosition="top"
                offsetY="1rem"
                // tooltipAlign="left"
              >
                <img
                  src={`/flags/${country}.svg`}
                  alt="flag"
                  className="rounded-sm"
                />
              </ElementWithTooltip>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default UserCard;
