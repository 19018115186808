import {GetState, SetState, State} from 'zustand'
import firebase from "firebase/app";
import {db} from "../firebase";
import User from "../models/User";
import produce from "immer";
import {StoreInterface} from "./store";
import {getUserPath, getUserStoragePath} from "../helpers/paths";


export interface userSliceInterface extends State {
  uid?: string
  user?: User
  FBUser?: firebase.User,
  setFBUser: (FBUser?: firebase.User) => void
  dbPath: string
  storagePath: string
  currentEditedTripId: string | undefined
  setCurrentEditedTripId: (tripId?: string) => void
  toggleBookmark: (tripId: string) => void
  resetUser: () => void
}


const userSlice = (set: SetState<StoreInterface>, get: GetState<StoreInterface>) => ({
  uid: undefined,
  user: undefined,
  FBUser: undefined,
  setFBUser: async (FBUser?: firebase.User) => {
    if (!FBUser) {
      get().resetUser()
      return;
    }
    const dbPath = getUserPath(FBUser.uid)
    const userDocSnapshot = await db.doc(dbPath).get()
    set({
      FBUser,
      uid: FBUser.uid,
      dbPath: dbPath,
      storagePath: getUserStoragePath(FBUser.uid),
      user: userDocSnapshot.data() as User
    })
  },
  dbPath: ``,
  storagePath: "",
  currentEditedTripId: undefined,
  setCurrentEditedTripId: (currentEditedTripId?: string) => set({ currentEditedTripId }),
  toggleBookmark: (tripId: string) => {
    const user = get().user;
    let newBookmarks = [...user?.bookmarks ?? []]
    if (newBookmarks.includes(tripId)) {
      newBookmarks.splice(newBookmarks.indexOf(tripId), 1)
    } else {
      newBookmarks.push(tripId)
    }
    db.doc(get().dbPath).update({bookmarks: newBookmarks})
    set(produce(store => {
      store.user.bookmarks = newBookmarks
    }))
  },
  resetUser: () => {
    set({
      uid: undefined,
      user: undefined,
      FBUser: undefined,
      dbPath: "",
      storagePath: "",
      currentEditedTripId: undefined
    })
  }
})

export default userSlice;