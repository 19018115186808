import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import TripPost from "./TripPost";
import NextTripButton from "./NextTripButton";
import PrevTripButton from "./PrevTripButton";
import Trip from "../../models/Trip";
import Logo from "../../components/Logo";
import BackgroundOverlay from "./BackgroundOverlay";
import {useLocation} from "react-use";
import {useHistory} from "react-router-dom";
import {SwipeEventData} from "react-swipeable";
import useIsMobile from "../../hooks/useIsMobile";
import useStore from "../../stores/store";
import {userTripsPath} from "../../helpers/constants";
import {preventPullToRefresh} from "../../helpers/utils";


interface OwnProps {}

type Props = OwnProps;

const TripDiscovery: FunctionComponent<Props> = (props) => {
  const isMobile = useIsMobile();
  const [
    uid,
    trip,
    selectedTripIndex,
    setSelectedTripIndex,
    loadUserTripsFromDB,
    allTrips,
    setHighlightedMedia,
    setIsTripCommentsVisible,
    setSelectedWaypoint,
    setFullScreenSlideShow
  ] = useStore(state => [
    state.uid,
    state.trip,
    state.selectedTripIndex,
    state.setSelectedTripIndex,
    state.loadUserTripsFromDB,
    state.allTrips,
    state.setHighlightedMedia,
    state.setIsTripCommentsVisible,
    state.setSelectedWaypoint,
    state.setFullScreenSlideShow
  ])
  const history = useHistory();
  const [isTripPostVisible, setIsTripPostVisible] = useState(true)
  const scrollRef = useRef<any>(null);
  const trips = allTrips ? allTrips.map((trip: Trip) => trip.id) : []
  const location = useLocation()
  const requestedTripId = new URLSearchParams(location.search).get("id") ?? undefined

  // prevent pull to refresh
  useEffect(preventPullToRefresh, [])

  useEffect(() => {
    if (isMobile) {
      setFullScreenSlideShow(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  useEffect(() => {
    setSelectedWaypoint(-1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setHighlightedMedia(undefined)
    setIsTripCommentsVisible(false)
  }, [setIsTripCommentsVisible, setHighlightedMedia, selectedTripIndex])

  useEffect(() => {
    if (requestedTripId && allTrips) {
      setSelectedTripIndex(allTrips.findIndex(trip => trip.id === requestedTripId))
    }
  }, [setSelectedTripIndex, requestedTripId, allTrips])

  useEffect(() => {
    if (uid && !allTrips) {
      loadUserTripsFromDB(uid, requestedTripId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTrips, uid])

  const scrollToTop = () => {
    scrollRef?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
  const handleNextTrip = () => {
    if (selectedTripIndex < trips.length - 1) {
      setSelectedTripIndex(selectedTripIndex + 1)
      setIsTripPostVisible(false)
      setTimeout(() => setIsTripPostVisible(true), 2500)
      scrollToTop()
    }
  }

  const handlePrevTrip = () => {
    if (selectedTripIndex > 0) {
      setSelectedTripIndex(selectedTripIndex - 1)
      setIsTripPostVisible(false)
      setTimeout(() => setIsTripPostVisible(true), 2500)
      scrollToTop()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'ArrowLeft') {
      handlePrevTrip()
    } else if (e.code === 'ArrowRight') {
      handleNextTrip()
    }
  }

  const onSwiped = (eventData: SwipeEventData) => {
    if (eventData.dir === "Left") {
      handleNextTrip()
    } else if (eventData.dir === "Right") {
      handlePrevTrip()
    }
  }

  return (
    <div className="absolute inset-0" onKeyDown={handleKeyDown} tabIndex={0}>
      <Logo
        height={30}
        className="text-white fixed left-4 sm:left-10 top-8 sm:top-10 z-40"
        hasShadow
      />
      <BackgroundOverlay
        isVisible={isTripPostVisible}
        onClick={() => {trip?.authorId === uid && history.push(`${userTripsPath}?id=${uid}`)}}
      />
      <div className="absolute inset-0 overflow-hidden pointer-events-none" ref={scrollRef}>
        <div className={`fixed right-10 top-0 bottom-0 h-full flex items-center z-30 hidden sm:flex
                        ${selectedTripIndex < trips.length - 1 ? "opacity-100" : "opacity-0 pointer-events-none"}`}>
          <NextTripButton onClick={handleNextTrip}/>
        </div>
        <div className={`fixed left-10 top-0 bottom-0 h-full flex items-center z-30 hidden sm:flex
                      ${selectedTripIndex > 0 ? "opacity-100" : "opacity-0 pointer-events-none"}`}>
          <PrevTripButton onClick={handlePrevTrip}/>
        </div>
        <TripPost
          isVisible={isTripPostVisible}
          onSwiped={onSwiped}
        />
      </div>
    </div>
  );
};

export default TripDiscovery;
