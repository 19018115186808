import React, { FunctionComponent } from 'react';

interface OwnProps {
  onClick: () => void
  isOpen?: boolean
  numComments: number
}

type Props = OwnProps;

const CommentsButton: FunctionComponent<Props> = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={`flex flex-row space-x-1 cursor-pointer ${props.isOpen && "text-blue-500"} 
                      hover:text-blue-500 items-center`}
    >
      <span className="text-sm">{props.numComments}</span>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
      </svg>
    </div>
  );
};

export default CommentsButton;
