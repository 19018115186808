import { FunctionComponent } from 'react';
import ElementWithTooltip from "../../components/ElementWithTooltip";

interface OwnProps {
  onClick: () => void
}

type Props = OwnProps;

const SignInWithGoogleButton: FunctionComponent<Props> = (props) => {
  return (
    <ElementWithTooltip tooltip="Sign up with Google" offsetY="2rem">
      <div className="group p-2 rounded-full cursor-pointer hover:bg-primary hover:text-white bg-white border-2 border-black hover:border-primary"
           onClick={props.onClick}>
        <svg className="fill-current"
             width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.4562 7.35703H8.98828V10.8375H13.8258C13.6172 11.9625 12.9844 12.9141 12.0305 13.5516C11.2242 14.0906 10.1953 14.4094 8.98594 14.4094C6.64453 14.4094 4.66406 12.8273 3.95625 10.7016C3.77812 10.1625 3.675 9.58594 3.675 8.99297C3.675 8.4 3.77812 7.82344 3.95625 7.28437C4.66641 5.16094 6.64687 3.57891 8.98828 3.57891C10.3078 3.57891 11.4914 4.03359 12.4242 4.92422L15.0023 2.34375C13.4437 0.890625 11.4117 0 8.98828 0C5.475 0 2.43516 2.01562 0.95625 4.95469C0.346875 6.16875 0 7.54219 0 8.99531C0 10.4484 0.346875 11.8195 0.95625 13.0336C2.43516 15.9727 5.475 17.9883 8.98828 17.9883C11.4164 17.9883 13.4508 17.182 14.9367 15.8086C16.6359 14.243 17.618 11.9367 17.618 9.19688C17.618 8.55938 17.5617 7.94766 17.4562 7.35703Z"/>
        </svg>
      </div>
    </ElementWithTooltip>
  );
};

export default SignInWithGoogleButton;
