import React, {FunctionComponent} from 'react';
import ElementWithTooltip from "../../components/ElementWithTooltip";
import useIsMobile from "../../hooks/useIsMobile";
import useStore from "../../stores/store";
import {Phase, tripsikPhase} from "../../helpers/constants";

interface OwnProps {
  tripId?: string
  onShare: () => void
}

type Props = OwnProps;

const ActionBar: FunctionComponent<Props> = (props) => {
  const isMobile = useIsMobile();
  const [
    user,
    toggleBookmark,
    isTripCommentsVisible,
    setIsTripCommentsVisible,
    setFullScreenSlideShow,
    fullScreenSlideShow
  ] = useStore(state => [
    state.user,
    state.toggleBookmark,
    state.isTripCommentsVisible,
    state.setIsTripCommentsVisible,
    state.setFullScreenSlideShow,
    state.fullScreenSlideShow
  ])
  const isBookmarked = user?.bookmarks?.includes(props.tripId ?? "")


  const handleClickComments = (e: React.MouseEvent) => {
    setIsTripCommentsVisible(!isTripCommentsVisible);
  }

  const handleClickHighlights = (e: React.MouseEvent) => {
    setFullScreenSlideShow(!fullScreenSlideShow)
  }

  return (
    <div className="sm:bg-white rounded-full flex flex-col sm:space-y-4 px-2 py-8 sm:shadow-lg pointer-events-auto">
      {
        tripsikPhase >= Phase.DISCOVERY && (
          <ElementWithTooltip
            tooltipPosition="top"
            tooltip={isBookmarked ? "Remove Bookmark" : "Add Bookmark"}
            className="text-white sm:text-black cursor-pointer hover:text-primary transition-all transform duration-300
                  p-3 sm:p-4 rounded-full hover:bg-gray-100"
            onClick={() => props.tripId && toggleBookmark(props.tripId)}
          >
            {
              isBookmarked ? (
                <svg className="h-7 w-7 text-primary" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.55556C5 4.87778 5.26925 4.22776 5.7485 3.7485C6.22776 3.26925 6.87778 3 7.55556 3H20.3333C21.0111 3 21.6611 3.26925 22.1404 3.7485C22.6196 4.22776 22.8889 4.87778 22.8889 5.55556V26L13.9444 21.5278L5 26V5.55556Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              ) : (
                <svg className="h-7 w-7" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.55556C5 4.87778 5.26925 4.22776 5.7485 3.7485C6.22776 3.26925 6.87778 3 7.55556 3H20.3333C21.0111 3 21.6611 3.26925 22.1404 3.7485C22.6196 4.22776 22.8889 4.87778 22.8889 5.55556V26L13.9444 21.5278L5 26V5.55556Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

              )
            }
          </ElementWithTooltip>
        )
      }
      {
        (isMobile || fullScreenSlideShow) && (
          <ElementWithTooltip
            tooltipPosition="top"
            tooltip={!fullScreenSlideShow ? "Hide Highlights" : "View Highlights"}
            className={`text-white sm:text-black cursor-pointer hover:text-primary ${!fullScreenSlideShow && "text-primary"} 
             transition-all transform duration-300 p-3 sm:p-4 rounded-full hover:bg-gray-100`}
            onMouseDown={(e) => e.preventDefault()} // prevent the button from taking the focus
            onClick={handleClickHighlights}
          >
            {
              fullScreenSlideShow ? (
                <svg className="h-7 w-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 3V7V3ZM3 5H7H3ZM6 17V21V17ZM4 19H8H4ZM13 3L15.286 9.857L21 12L15.286 14.143L13 21L10.714 14.143L5 12L10.714 9.857L13 3Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              ) : (
                <svg className="h-7 w-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 3V7V3ZM3 5H7H3ZM6 17V21V17ZM4 19H8H4ZM13 3L15.286 9.857L21 12L15.286 14.143L13 21L10.714 14.143L5 12L10.714 9.857L13 3Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              )
            }
          </ElementWithTooltip>
        )
      }
      <ElementWithTooltip
        tooltipPosition="top"
        tooltip={isTripCommentsVisible ? "Hide Comments" : "View Comments"}
        className={`cursor-pointer hover:text-primary ${isTripCommentsVisible ? "text-primary" : "text-white sm:text-black"}  
        transition-all transform duration-300 p-3 sm:p-4 rounded-full hover:bg-gray-100`}
        onMouseDown={(e) => e.preventDefault()} // prevent the button from taking the focus
        onClick={handleClickComments}
      >
        {
          isTripCommentsVisible ? (
            <svg className="h-7 w-7" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M14.0703 24.3033C20.697 24.3033 26.0703 19.5273 26.0703 13.6366C26.0703 7.74597 20.697 2.96997 14.0703 2.96997C7.44365 2.96997 2.07031 7.74597 2.07031 13.6366C2.07031 15.7353 2.75298 17.6926 3.93031 19.3433L2.07031 24.3033L8.39698 23.038C10.17 23.8776 12.1085 24.31 14.0703 24.3033ZM8.66992 11.67C7.56535 11.67 6.66992 12.5654 6.66992 13.67C6.66992 14.7746 7.56535 15.67 8.66992 15.67H8.68326C9.78782 15.67 10.6833 14.7746 10.6833 13.67C10.6833 12.5654 9.78782 11.67 8.68326 11.67H8.66992ZM14.0033 11.67C12.8987 11.67 12.0033 12.5654 12.0033 13.67C12.0033 14.7746 12.8987 15.67 14.0033 15.67H14.0166C15.1212 15.67 16.0166 14.7746 16.0166 13.67C16.0166 12.5654 15.1212 11.67 14.0166 11.67H14.0033ZM19.3366 11.67C18.232 11.67 17.3366 12.5654 17.3366 13.67C17.3366 14.7746 18.232 15.67 19.3366 15.67H19.3499C20.4545 15.67 21.3499 14.7746 21.3499 13.67C21.3499 12.5654 20.4545 11.67 19.3499 11.67H19.3366Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          ) : (
            <svg className="h-7 w-7" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.66667 13.6667H8.68H8.66667ZM14 13.6667H14.0133H14ZM19.3333 13.6667H19.3467H19.3333ZM26 13.6667C26 19.5573 20.6267 24.3333 14 24.3333C12.0382 24.34 10.0997 23.9077 8.32667 23.068L2 24.3333L3.86 19.3733C2.68267 17.7227 2 15.7653 2 13.6667C2 7.776 7.37333 3 14 3C20.6267 3 26 7.776 26 13.6667Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )
        }
      </ElementWithTooltip>
      <ElementWithTooltip
        tooltipPosition="top"
        tooltip="Share"
        onClick={props.onShare}
        className="text-white sm:text-black cursor-pointer hover:text-primary  transition-all transform duration-300
         p-3 sm:p-4 rounded-full hover:bg-gray-100"
      >
        <svg className="h-7 w-7" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.62986 16.0653C9.86551 15.594 9.9985 15.062 9.9985 14.4997C9.9985 13.9374 9.86551 13.4055 9.62986 12.9342V16.0653ZM9.62986 16.0653C9.2772 16.7704 8.69682 17.3357 7.98274 17.6698C7.26866 18.0038 6.46271 18.087 5.69546 17.9058C4.92821 17.7247 4.2446 17.2897 3.75539 16.6715C3.26617 16.0534 3 15.2881 3 14.4997C3 13.7114 3.26617 12.9461 3.75539 12.3279C4.2446 11.7097 4.92821 11.2748 5.69546 11.0936C6.46271 10.9125 7.26866 10.9956 7.98274 11.3297C8.69682 11.6637 9.2772 12.2291 9.62986 12.9342V16.0653ZM9.62986 16.0653L17.3667 19.9337L9.62986 16.0653ZM9.62986 12.9342L17.3667 9.06576L9.62986 12.9342ZM17.3667 9.06576C17.5723 9.47687 17.8568 9.84346 18.2041 10.1446C18.5514 10.4457 18.9546 10.6755 19.3906 10.8208C19.8267 10.9662 20.2871 11.0242 20.7456 10.9916C21.2041 10.9589 21.6517 10.8363 22.0628 10.6307C22.4739 10.4251 22.8405 10.1406 23.1416 9.79333C23.4428 9.44606 23.6726 9.04288 23.8179 8.6068C23.9632 8.17073 24.0212 7.7103 23.9886 7.25181C23.956 6.79331 23.8334 6.34574 23.6278 5.93463C23.2126 5.10435 22.4845 4.47303 21.6038 4.17954C20.7232 3.88605 19.7619 3.95443 18.9317 4.36964C18.1014 4.78486 17.4701 5.51289 17.1766 6.39358C16.8831 7.27427 16.9515 8.23548 17.3667 9.06576V9.06576ZM17.3667 19.9337C16.9515 20.7642 16.8832 21.7255 17.1768 22.6063C17.4704 23.4871 18.1018 24.2152 18.9323 24.6304C19.7627 25.0456 20.724 25.114 21.6048 24.8204C22.4856 24.5268 23.2137 23.8953 23.629 23.0649C24.0442 22.2344 24.1125 21.2731 23.8189 20.3923C23.5253 19.5115 22.8938 18.7834 22.0634 18.3682C21.6522 18.1626 21.2045 18.04 20.746 18.0074C20.2874 17.9748 19.8269 18.0328 19.3908 18.1782C18.51 18.4718 17.7819 19.1033 17.3667 19.9337V19.9337Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </ElementWithTooltip>
    </div>
  );
};

export default ActionBar;
