import React, {useEffect} from 'react';
import TripViewer from "./pages/TripViewer/TripViewer";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import TripMediaUploader from "./pages/TripEditor/TripMediaUploader";
import FullScreenMediaViewer from "./components/FullScreenMediaViewer";
import TripDiscovery from "./pages/TripDiscovery/TripDiscovery";
import TripMap from "./pages/TripMap/TripMap";
import Test from "./Test";
import LoginPage from "./pages/Registration/LoginPage";
import SignUpPage from "./pages/Registration/SignUpPage";
import PrivateRoute from "./components/PrivateRoute";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import UserTrips from "./pages/UserTrips/UserTrips";
import Settings from "./pages/Settings/Settings";
import useStore from "./stores/store";
import EmailVerificationPage from "./pages/Registration/EmailVerificationPage";
import {Toaster} from "react-hot-toast";
import Popover from "./components/Popover";
import {
  createTripPath,
  emailVerificationPath, homePath,
  loginPath,
  settingsPath,
  signupPath, tripDiscoveryPath,
  tripViewerPath, userTripsPath
} from "./helpers/constants";
import {AnimateSharedLayout} from "framer-motion";

function App() {
  const [actualFirebaseUser] = useAuthState(auth);
  const [FBUser, setFBUser] = useStore(state => [state.FBUser, state.setFBUser]);

  useEffect(() => {
    if (!FBUser && actualFirebaseUser) {
      console.log("updating user", actualFirebaseUser?.uid)
      setFBUser(actualFirebaseUser);
    }
  }, [FBUser, setFBUser, actualFirebaseUser])

  return (
    <Router>
      <AnimateSharedLayout>
        <div className="fixed inset-0 overflow-y-hidden overscroll-none w-screen h-[100uvh] sm:h-screen overscroll-none">
          <Toaster position="top-center"/>
          <Popover/>
          <FullScreenMediaViewer/>

          <Switch>
            {/*Registration*/}
            <Route path={signupPath}>
              <SignUpPage/>
            </Route>
            <Route path={loginPath}>
              <LoginPage/>
            </Route>
            <Route path={emailVerificationPath}>
              <EmailVerificationPage/>
            </Route>

            {/*App routes*/}
            <PrivateRoute path={createTripPath}>
              <TripMediaUploader />
            </PrivateRoute>
            <PrivateRoute path={settingsPath}>
              <Settings />
            </PrivateRoute>

            {/*Routes that have a background map*/}
            <Route path={[tripDiscoveryPath, tripViewerPath, userTripsPath]}>
              <Switch>
                <PrivateRoute path={tripViewerPath}>
                  <TripViewer/>
                </PrivateRoute>
                <PrivateRoute path={userTripsPath}>
                  <UserTrips/>
                </PrivateRoute>
                <PrivateRoute path={tripDiscoveryPath}>
                  <TripDiscovery/>
                </PrivateRoute>
              </Switch>
              <div className={`absolute inset-0 ml-auto h-[90vh] sm:h-screen w-full relative overflow-y-hidden overscroll-none`}>
                <div className="fixed top-0 left-0 h-80 w-80 bg-gradient-to-br z-10 from-[#00000055] via-transparent to-transparent pointer-events-none"/>
                <TripMap/>
              </div>
            </Route>
          </Switch>
        </div>
      </AnimateSharedLayout>
    </Router>
  );
}

export default App;
