import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useDropzone} from "react-dropzone";
import {storage} from "../firebase";
import useFileStore from "../stores/fileStore";
import {generateUuid} from "../utils";
import heic2any from "heic2any";
import {findEXIFinHEIC, findEXIFinJPEG} from "../exif";
import {coordinatesToDegrees} from "../mapBoxUtils";
import Button from "./Button";
import {DateTime} from "luxon";
//@ts-ignore
import ImageBlobReduce from 'image-blob-reduce';


// Rewrite the size calculation function of ImageBlobReduce so that it will scale the minimum side to the requested
// maximum size. This is done in order to deal with panorama images, such that the small side won't get really really
// small and loose it's quality entirely
ImageBlobReduce.prototype._calculate_size = function (env: any) {
  //
  // Note, if your need not "symmetric" resize logic, you MUST check
  // `env.orientation` (set by plugins) and swap width/height appropriately.
  //
  let scale_factor;
  if (env.opts.maxSmallSide) {
    scale_factor = env.opts.maxSmallSide / Math.min(env.image.width, env.image.height);
  } else {
    scale_factor = env.opts.max / Math.max(env.image.width, env.image.height);
  }

  if (scale_factor > 1) scale_factor = 1;

  env.transform_width = Math.max(Math.round(env.image.width * scale_factor), 1);
  env.transform_height = Math.max(Math.round(env.image.height * scale_factor), 1);

  // Info for user plugins, to check if scaling applied
  env.scale_factor = scale_factor;

  return Promise.resolve(env);
};

interface OwnProps {
  storageRootPath: string
  className?: string
  forceUploadButton?: boolean
  expand?: boolean
  backgroundClickable?: boolean
  onUpload?: (uniqueId: any) => void
  allowMultiple?: boolean
  renameFile?: string
}

type Props = OwnProps;

const FileUploader: FunctionComponent<Props> = (props) => {
  const {
    uploadedFiles: files,
    setUploadedFiles: setFiles,
    setFileProperties,
    uploadProgress: progress,
    setUploadProgress: setProgress,
    removeFile,
    releaseFileObjectURL
  } = useFileStore()
  const [imageBlobReduce,] = useState(new ImageBlobReduce());
  const [numConcurrentUploads, setNumConcurrentUploads] = useState(0);
  const [filesQueue, setFilesQueue] = useState<any>({});

  const upload = (file: any, progressOffset?: number, progressRescaler?: number, fileNameSuffix?: string,
                  filePropsPrefix?: string, onUpload?: () => void) => {
    return new Promise((resolve, reject) => {
      const rescaler = progressRescaler ?? 1;
      const offset = progressOffset ?? 0;
      const extension = "jpg"//file.name.split(".").pop().toLowerCase()
      const storedFileName = (props.renameFile ?? file.uniqueId) + `${fileNameSuffix ?? ""}.` + extension
      const path = props.storageRootPath + "/" + storedFileName;
      const uploadTask = storage.ref(path).put(file);
      uploadTask.on('state_changed', (snapshot) => {
        progress[file.uniqueId] = offset + 0.9 * rescaler * snapshot.bytesTransferred / snapshot.totalBytes;
        setProgress({...progress});
      }, (e) => {
        // error(e)
      }, () => {
        // load(path);
        uploadTask.snapshot.ref.getDownloadURL().then(url => {
          progress[file.uniqueId] = offset + rescaler;
          setProgress({...progress});
          releaseFileObjectURL(file.uniqueId);
          setFileProperties(file.uniqueId, {
            [filePropsPrefix ? `${filePropsPrefix}Url` : "url"]: url,
            [filePropsPrefix ? `${filePropsPrefix}StoredFileName` : "storedFileName"]: storedFileName,
            [filePropsPrefix ? `${filePropsPrefix}StoragePath` : "storagePath"]: path,
            [filePropsPrefix ? `${filePropsPrefix}Size` : "size"]: file.size,
          })
          if (!filePropsPrefix) {
            setFileProperties(file.uniqueId, {
              preview: undefined
            })
          }
          onUpload && onUpload();
          resolve(true);
        });
      });
    })
  }

  const convertHEIC = async (file: any) => {
    const convertedFile = await heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.94,
    }) as any;
    convertedFile.name = file.name.split(".")[0] + ".jpg"
    convertedFile.lastModifiedDate = file.lastModifiedDate
    convertedFile.uniqueId = file.uniqueId;
    return convertedFile;
  }

  const resizeImage = async (file: any, newUniqueId?: string, maxSide?: number, maxSmallSide?: number) => {
    return new Promise((resolve, reject) => {
      try {
        imageBlobReduce
          .toBlob(file, {
            max: maxSide ?? 600,
            maxSmallSide: maxSmallSide,
            unsharpAmount: 80,
            unsharpRadius: 0.6,
            unsharpThreshold: 2
          } as any)
          .then((blob: Blob) => {
            let newFile = new File([blob as BlobPart], file.name)
            newFile = Object.assign(newFile, {
              preview: undefined,
              progress: 0,
              uniqueId: newUniqueId ?? file.uniqueId
            })
            resolve(newFile);
          })
          .catch((error: any) => {
            console.log(error)
            reject(file)
          });
      } catch {
        reject(file)
      }
    })
  }

  // get a preview of the file
  const setPreview = async (file: any) => {
    const preview = URL.createObjectURL(file);
    setFileProperties(file.uniqueId, {preview: preview})
  }

  const setExif = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      let exif: any;
      if (!/heic/i.test(file.type)) {
        exif = findEXIFinJPEG(reader.result)
      } else {
        exif = findEXIFinHEIC(reader.result)
      }
      if (exif) {
        // console.log("exif", JSON.stringify(exif, null, 2))
        setFileProperties(file.uniqueId, {exif: exif})
        if (exif["GPSLatitude"] && exif["GPSLongitude"]) {
          const lat = coordinatesToDegrees(exif["GPSLatitude"], exif["GPSLatitudeRef"])
          const lng = coordinatesToDegrees(exif["GPSLongitude"], exif["GPSLongitudeRef"])
          setFileProperties(file.uniqueId, {location: [lng, lat]})
        }
        if (exif["DateTimeOriginal"] || exif["DateTime"]) {
          // the creation date has no timezone. we will later attach a timezone according to the GPS location in the backend.
          let parsedCreationDate = DateTime.fromFormat(
            exif["DateTimeOriginal"] ?? exif["DateTime"],
            "yyyy:MM:dd HH:mm:ss",
            {zone: "GMT"}
          )
          // console.log(parsedCreationDate)
          setFileProperties(file.uniqueId, {creationDate: parsedCreationDate.toJSDate()})
        } else {
          setFileProperties(file.uniqueId, {creationDate: file.lastModifiedDate})
        }
      }
    };
    reader.readAsArrayBuffer(file);
  }

  const convertAndUploadFile = async (file: any) => {
    setExif(file)
    if (/heic/i.test(file.type)) {
      file = await convertHEIC(file);
      setFileProperties(
        file.uniqueId,
        {
          name: file.name,
          size: file.size,
          type: file.type
        })
    }
    try {
      const thumbnailFile = await resizeImage(file, undefined, 400)
      file = await resizeImage(file, undefined, undefined, 1080)
      setPreview(thumbnailFile)
      await upload(thumbnailFile, 0, 0.3, "-thumbnail", "thumbnail")
      await upload(file, 0.3, 0.7)
      props.onUpload && props.onUpload(file.uniqueId)
    } catch {
      removeFile(file.uniqueId)
    }
  }


  useEffect(() => {
    console.log(numConcurrentUploads, Object.keys(filesQueue).length)
    const hwThreads = isNaN(window.navigator.hardwareConcurrency) ? 4 : window.navigator.hardwareConcurrency;
    const maxThreads = Math.min(4, hwThreads)
    if (numConcurrentUploads < maxThreads && Object.keys(filesQueue).length > 0) {
      let file: any = Object.values(filesQueue).find((file: any) => !file.isUploading)
      setFilesQueue((filesQueue: any) => {
        delete filesQueue[file.name]
        return filesQueue;
      })
      setNumConcurrentUploads((numConcurrentUploads) => numConcurrentUploads + 1)
      if (file) {
        file.isUploading = true;
        console.log(`uploading ${file.name}`)
        convertAndUploadFile(file).then(() => {
          setNumConcurrentUploads((numConcurrentUploads) => numConcurrentUploads - 1)
          console.log(`finished ${file.name}`)
        })
      } else {
        console.log("no more files")
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesQueue, numConcurrentUploads])

  const onDrop = useCallback(async (acceptedFiles: Array<any>) => {
    const convertAndUploadFiles = async (files: Array<any>) => {
      setFilesQueue({...filesQueue, ...Object.fromEntries(files.map(file => [file.name, file]))})
    }

    let newFiles;
    if (props.allowMultiple) {
      newFiles = {...files};
    } else {
      newFiles = {}
    }
    for (const i in acceptedFiles) {
      const file = acceptedFiles[i]
      const uniqueId = generateUuid();
      newFiles[uniqueId] = Object.assign(file, {
        preview: undefined,
        progress: 0,
        uniqueId: uniqueId
      })
    }
    setFiles(newFiles);
    await convertAndUploadFiles(acceptedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, progress, props, removeFile, setFileProperties, setFiles, setProgress])

  const {getRootProps, getInputProps} = useDropzone({onDrop,  multiple: !!props.allowMultiple})


  return (
    <div className={`flex flex-col items-stretch justify-center ${props.expand && "w-full h-full"} 
        cursor-pointer overflow-none ${props.className} ${!props.backgroundClickable && "pointer-events-none"}`} >
      <input {...getInputProps()} />
      {
        (files.length === 0 || props.forceUploadButton) && (
          <div {...getRootProps()}
               className={`w-full h-full flex flex-col items-center justify-center overflow-none
                        ${!props.backgroundClickable && "pointer-events-none"}`}
          >
            {
              props.children ? props.children : (
                <div className="overflow-none backdrop-filter backdrop-blur-sm bg-opacity-75 bg-white p-10
                    flex flex-col rounded-lg justify-center items-center cursor-pointer text-center space-y-4 w-80
                    pointer-events-auto"
                >
                  <p className="font-sans text-lg">Drag your trip photos here</p>
                  <div>or</div>
                  <Button outlined>
                    Choose photos to upload
                  </Button>
                </div>
              )
            }
          </div>
        )
      }

    </div>
  )
};

export default FileUploader;
