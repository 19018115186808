import React, { FunctionComponent } from 'react';

interface OwnProps {
  label: string
  onClick?: () => void
  removable?: boolean
}

type Props = OwnProps;

const LabelTag: FunctionComponent<Props> = (props) => {
  const emojis: {[key: string]: string} = {
    "theme parks": "🎡", //"🎢",
    "restaurants": "🍝",
    "bridges": "🌉",
    "water parks": "💦",
    "statues": "🗽",
    "palaces": "🏰",
    "castles": "🏰",
    "city": "🏙️",
    "city alley": "🏙️",
    "animals": "🦊",
    "trains": "🚂",
    "buildings": "🏢",
    "fountains": "⛲",
    "sea": "🌊",
    "beaches": "🏖️",
    "sunsets": "🌅",
    "volcanos": "🌋",
    "camping": "🏕️",
    "boats": "🚤",
    "parties": "🥳",
    "concerts": "🎸",
    "mountains": "⛰️",
    "lookouts": "🏞️",
    "villages": "🏘️",
    "markets": "🛍️",
    "forest": "🌲",
    "swimming pool": "🏊‍",
    "ruins": "🗿",
    "towers": "🗼",
    "museums": "🖼️",
    "city squares": "💂",
    "parks": "🌳",
    "monuments": "🏛️",
    "biking": "🚲",
    "skyscrapers": "🏙️",
    "shops": "🛍️",
    "skylines": "🌇",
    "waterfalls": "💦",
    "lakes": "🦢",
    "snow": "❄️",
    "cliffs": "🪨",
    "caves": "🦇",
    "roads": "🛣️",
    "trams": "🚋",
    "cable cars": "🚡",
    "ships": "🛳️",
    "sport games": "⚽",
    "flowers": "🌺",
    "cathedrals": "⛪",
    "churches": "⛪",
    "canals": "🚣‍",
    "graffiti": "🎨",
    "picnic": "🧺",
    "fortresses": "🏯",
    "festivals": "🎭",
    "pyramids": "🛕",
    "farms": "🐑",
    "islands": "🏝️",
    "reefs": "🐠",
    "promenades": "🚶‍",
    "golf": "⛳",
    "sky": "⛅",
    "factories": "🏭"
  }
  return (
    <div
      onClick={props.onClick}
      className={`px-3 py-1 whitespace-nowrap m-1 rounded-full text-sm justify-between group
                        flex items-center space-x-2 bg-blue-50 select-none ${props.onClick && "cursor-pointer"}`}
    >
      <div>
        {props.label}
      </div>
      <div className={`${props.removable && "h-4 w-5"} ${emojis[props.label] ? "block" : "hidden"} flex items-center`}>
        <div className={`block ${props.removable && "group-hover:hidden"}`}>
          {emojis[props.label]}
        </div>
        {
          props.removable && (
            <svg
              className="h-4 w-5 hidden group-hover:block"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          )
        }
      </div>
    </div>
  );
};

export default LabelTag;
