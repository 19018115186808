import React, {FunctionComponent} from 'react';
import ShinyWrapper from "./ShinyWrapper";

interface OwnProps {
  type?: "purple-fill" | "primary-fill" | "purple-outline" | "primary-outline"
  onClick?: () => void
  center?: boolean
  className?: string
  animateOnceInEveryMilliseconds?: number
  shine?: boolean

}

type Props = OwnProps;

const CTAButton: FunctionComponent<Props> = (props) => {
  const {type = "purple-fill", onClick, center = false, className} = props;
  const classes: {[key: string]: string} = {
    "primary-fill": "bg-gradient-to-r from-primary-light to-primary-dark text-offWhite shadow-md hover:shadow-lg py-2",
    "purple-fill": "bg-gradient-to-r from-purple-light to-purple-dark text-offWhite shadow-md hover:shadow-lg py-2",
    "purple-outline": "bg-offWhite text-purple-dark border-2 border-purple shadow py-1.5 hover:shadow-md hover:bg-gradient-to-r hover:from-purple-light hover:to-purple-dark hover:text-offWhite",
    "primary-outline": "bg-offWhite text-primary border-2 border-primary shadow py-1.5 hover:shadow-md hover:bg-primary hover:text-offWhite",
  }

  const button = (
    <button
      type="button"
      className={`${classes[type]} px-6 z-[1] relative transition-all duration-300 w-full md:w-auto select-none
              rounded-tl-md rounded-br-2xl rounded-bl-2xl rounded-tr-2xl ${className} text-sm`}
      onClick={onClick}
    >
      {props.children}
    </button>
  )
  if (props.shine) {
    return (
      <ShinyWrapper center={center} animateOnceInEveryMilliseconds={props.animateOnceInEveryMilliseconds}>
        {button}
      </ShinyWrapper>
    );
  } else {
    return button;
  }
};

export default CTAButton;
