import create from "zustand";
import tripSlice, {tripSliceInterface} from "./tripSlice";
import useUserStore, {userSliceInterface} from "./userSlice";

export type StoreInterface = tripSliceInterface & userSliceInterface

const useStore = create<StoreInterface>((set, get) => ({
  ...useUserStore(set, get),
  ...tripSlice(set, get)
}));

export default useStore;