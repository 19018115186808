import React, {FunctionComponent, useState} from 'react';
import {useAuthState} from "react-firebase-hooks/auth";
import {useHistory} from "react-router-dom";
import {auth} from "../../firebase";
import NavigationBar from "../../components/NavigationBar";
import Heading from "../../components/Heading";
import StyledHeading from "../../components/StyledHeading";
import mailVerification from "./mail-verification.json";
import Lottie from "lottie-react";
import {toast} from "react-hot-toast";
import {homePath, loginPath} from "../../helpers/constants";
import CTAButton from "../../components/CTAButton";
import useStore from "../../stores/store";

interface OwnProps {}

type Props = OwnProps;

const EmailVerificationPage: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const [user] = useAuthState(auth);
  const [
    resetUser,
    resetTrips
  ] = useStore(state => [
    state.resetUser,
    state.resetTrips
  ])

  const logout = () => {
    auth.signOut().then(() => {
      history.push(loginPath)
      // we need to wait a bit for the auth service to update the user to undefined
      setTimeout(() => {
        resetUser()
        resetTrips()
      }, 1000)
    });
  }

  const resendVerification = () => {
    if (user) {
      toast.promise(
        user.sendEmailVerification(),
        {
          loading: 'Just a sec...',
          success: <b>Email sent successfully</b>,
          error: <b>We had an error</b>,
        }
      );
    }
  }

  if (user?.emailVerified) {
    history.push(homePath);
  }


  return (
    <div className="flex flex-col items-center w-screen bg-offWhite text-gray">
      <NavigationBar>
        <CTAButton type="purple-outline" onClick={logout}>Log out</CTAButton>
      </NavigationBar>
      <div className="flex flex-col space-y-8 h-screen max-w-screen-lg w-full items-center justify-center px-4">
        <Lottie animationData={mailVerification} loop/>
        <Heading className="text-center max-w-[80vw]">
          One small step before we continue
        </Heading>
        <div className="text-lg text-center flex flex-col space-y-2">
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 space-x-2 items-center">
            <div>
              We've sent you an email to
            </div>
            <StyledHeading>{user?.email}</StyledHeading>
          </div>
          <div>
            Check it out and click the verify button.
          </div>
        </div>
        <div className="bg-white px-6 py-2 rounded-full">
          Didn't receive anything?
          <span
            className="underline cursor-pointer ml-1 hover:text-primary-dark transition-all"
            onClick={resendVerification}
          >
            click to resend
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationPage;
