import { useState, useEffect } from "react";
import {useWindowSize} from "react-use";


function useIsMobile(): boolean {
  const {width} = useWindowSize();
  const [isMobile, setIsMobile] = useState<boolean>(width < 768);
  useEffect(() => {
    setIsMobile(width < 768)
  }, [width]);
  return isMobile;
}

export default useIsMobile