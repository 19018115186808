import React, {FunctionComponent, useEffect} from 'react';
import {db} from "../../firebase";
import Trip, {TripCreationStage} from "../../models/Trip";
import Spinner from "../../components/Spinner";
import {
  Coordinates,
  Directions,
  extractPlaceContext,
  formatPlaceName,
  getDirections,
  getPlaceName,
  travelTime
} from "../../mapBoxUtils";
import {arrayToMap, datesAreOnSameDay, getTimeDifference} from "../../utils";
import MediaFile from "../../models/MediaFile";
import Waypoint from "../../models/Waypoint";
import {center, points} from '@turf/turf';
import firebase from "firebase/app";
import Transportation, {TransportationType} from "../../models/Transportation";
import sphereKnn from "sphere-knn";
import airports from "../../data/airports-mini.min.json";
import Airport from "../../models/Airport";
import DurationStats from "../../models/DurationStats";
import TransportationStats from "../../models/TransportationStats";
import useStore from "../../stores/store";


interface OwnProps {
  onGenerationFinished: () => void
}

type Props = OwnProps;

const TripItineraryGenerator: FunctionComponent<Props> = (props) => {

  const [uid, dbPath, tripId] = useStore(state => [state.uid, state.dbPath, state.currentEditedTripId])
  const airportsKNN = sphereKnn(airports.map((a) => ({...a, location: [a.lat, a.lon]})))


  const getNearestAirports = (coordinates: Coordinates) : Array<Airport> => {
    const results = airportsKNN(coordinates[1], coordinates[0], 5, 150*1000) // max distance is in meters
    results.sort((a1: Airport, a2: Airport) => a2.directs - a1.directs)
    return results
  }

  const generateWaypoints = async (batch: firebase.firestore.WriteBatch, allMedia: Array<MediaFile>) => {
    // walk over all the media in order of creation date and generate waypoints.
    // if the current and previous media are on different days or it is
    // more than 10 minutes walking distance, create a waypoint.
    const waypoints = [];
    let tripDay = 0;
    let currentWaypointMedia: Array<MediaFile> = [];
    let mediaIndex: number;
    for (mediaIndex = 0; mediaIndex < allMedia.length; mediaIndex++) {
      const media = allMedia[mediaIndex];
      let generateWaypoint = false;
      let increaseTripDay = false;
      if (media.creationDate && media.location) {
        const currentMediaDate = media.creationDate.toDate();
        const prevMediaDate = mediaIndex > 0 && allMedia[mediaIndex - 1].creationDate ? allMedia[mediaIndex - 1].creationDate.toDate() : currentMediaDate

        // console.log(waypoints.length, currentMediaDate, prevMediaDate, datesAreOnSameDay(currentMediaDate, prevMediaDate, 3))
        if (currentWaypointMedia.length === 0) {
          currentWaypointMedia.push(media)
        } else if (datesAreOnSameDay(currentMediaDate, prevMediaDate, 3)) {
          // if the media is within a 15 min walking distance from the previous media, then they are part of
          // the same waypoint
          const maxTime = 10; // minutes
          const walkTime = travelTime(
            media.location,
            currentWaypointMedia[currentWaypointMedia.length - 1].location,
            5, // km/h
          )
          if (walkTime > maxTime) {
            // generate a waypoint for all the previous media
            generateWaypoint = true;
          } else {
            currentWaypointMedia.push(media)
          }
        } else {
          generateWaypoint = true;
          increaseTripDay = true;
        }
      } else if (media.creationDate) {
        // if the location is not available, attach the media to the current waypoint
        // currentWaypointMedia.push(media)
        // TODO: check if the date is the same, if not, how to infer waypoint?
        // TODO: mark the media as estimated
      }

      // debugger;
      // get the center of all the waypoint media, get its name and generate a waypoint doc in firestore
      const mediaLocations = currentWaypointMedia.map(m => m.location).filter(l => l);
      if (mediaLocations.length > 0 && (generateWaypoint || mediaIndex === allMedia.length - 1)) {
        // get the waypoint center
        // console.log(currentWaypointMedia.map(m => m.location).filter(l => l))
        const features = points(currentWaypointMedia.map(m => m.location).filter(l => l));
        const waypointCenter = center(features).geometry.coordinates
        const waypointRef = db.collection(`${dbPath}/trips/${tripId}/waypoints`).doc();

        // get the waypoint place name
        // console.log(waypointCenter)
        const place = await getPlaceName(waypointCenter)
        let waypointTitle = formatPlaceName(place);
        const country = extractPlaceContext(place, "country")
        const region = extractPlaceContext(place, "region")

        const waypoint = {
          id: waypointRef.id,
          coordinates: waypointCenter,
          startDate: currentWaypointMedia[0].creationDate,
          endDate: currentWaypointMedia[currentWaypointMedia.length - 1].creationDate,
          title: waypointTitle,
          content: "",
          media: currentWaypointMedia.map((media: MediaFile) => media.uniqueId),
          rating: "must",
          tripDay: tripDay,
          country: country,
          region: region
        } as Waypoint

        waypoints.push(waypoint)

        // creat a waypoint doc
        batch.set(waypointRef, waypoint)

        // reset the media for the next waypoint and set the loop to go over this media again
        currentWaypointMedia = [];
        if (generateWaypoint) {
          mediaIndex--;
        }
      }
      if (increaseTripDay) {
        tripDay++;
      }
    }
    return {
      waypoints,
      numDays: tripDay + 1,
    }
  }

  const generateMediaDocs = (batch: firebase.firestore.WriteBatch, allMedia: Array<MediaFile>) => {
    const mediaRef = db.doc(`${dbPath}/trips/${tripId}/media/overview`);
    const urls: {[key: string]: string} = {};
    const thumbnailUrls: {[key: string]: string} = {};
    let mediaIndex: number;
    for (mediaIndex = 0; mediaIndex < allMedia.length; mediaIndex++) {
      const media = allMedia[mediaIndex];
      const mediaId = media.storedFileName.split(".")[0]
      urls[mediaId] = media.url;
      thumbnailUrls[mediaId] = media.thumbnailUrl;
    }
    batch.set(
      mediaRef,
      {
        urls: urls,
        thumbnailUrls: thumbnailUrls
      },
      {merge: true}
    )
  }

  const generateTransportation = (batch: firebase.firestore.WriteBatch, waypoints: Array<Waypoint>, directions: Directions) : Array<Transportation> => {
    if (!directions.durations) return [];

    const transportationItems: Array<Transportation> = [];
    for (let waypointIndex = 1; waypointIndex < waypoints.length; waypointIndex++) {
      let transportationType: TransportationType = "car"
      let normalizedDuration = directions.durations[waypointIndex-1]
      let airports: Array<Airport> = [];

      const startWaypoint = waypoints[waypointIndex-1]
      const endWaypoint = waypoints[waypointIndex]
      const startDate = startWaypoint.endDate.toDate();
      const endDate = endWaypoint.startDate.toDate()

      const walkTime = travelTime(startWaypoint.coordinates, endWaypoint.coordinates, 5);
      const flightTime = travelTime(startWaypoint.coordinates, endWaypoint.coordinates, 600);
      if (walkTime < 40) {
        transportationType = "walk"
        normalizedDuration = walkTime;
      } else {
        // if the actual route duration is shorter than the predicted route duration in more than 2 hours, then it's probably a flight.
        // if the predicted route duration is more than 12 hours, it's also probably a flight
        const routeActualDuration = getTimeDifference(startDate, endDate);
        const isLongRoute = directions.durations[waypointIndex-1] > 8*60
        const isActualRouteFasterThanPredicted = routeActualDuration < directions.durations[waypointIndex-1] - 120
        if (flightTime > 30 && (isLongRoute || isActualRouteFasterThanPredicted)) {
          console.log(startWaypoint.country, endWaypoint.country)
          const startAirport = getNearestAirports(startWaypoint.coordinates)
            .find(a => a.country.toLowerCase() === startWaypoint.country?.toLowerCase())
          const endAirport = getNearestAirports(endWaypoint.coordinates)
            .find(a => a.country.toLowerCase() === endWaypoint.country?.toLowerCase())
          if (startAirport && endAirport) {
            airports = [startAirport, endAirport]
            transportationType = "flight";
            normalizedDuration = flightTime;
          }
        }
      }

      // create doc in firestore
      const transportationRef = db.collection(`${dbPath}/trips/${tripId}/transportation`).doc();

      const transportation = {
        id: transportationRef.id,
        type: transportationType,
        duration: normalizedDuration,
        airports,
        startDate,
        endDate
      } as Transportation

      transportationItems.push(transportation)
      batch.set(transportationRef, transportation)
    }

    return transportationItems
  }

  const getFilteredTransportationDurations = (transportationItems: Array<Transportation>, transportationType: TransportationType) => {
    return transportationItems
      .filter(t => t.type === transportationType)
      .map(t => t.duration)
  }

  const sumTripTime = (transportationDurations: Array<number>) => {
    return transportationDurations.length > 0 ? transportationDurations.reduce((t, s) => t + s) : 0
  }

  const maxTripTime = (transportationDurations: Array<number>) => {
    return transportationDurations.length > 0 ? Math.max(...transportationDurations) : 0
  }

  const minTripTime = (transportationDurations: Array<number>) => {
    return transportationDurations.length > 0 ? Math.min(...transportationDurations) : 0
  }

  const calculateTripTimeStats = (transportationItems: Array<Transportation>, numDays: number) : TransportationStats => {
    let result: any = {}
    const types: Array<TransportationType> = ["walk", "car", "flight"]
    for (const type of types) {
      const filteredTransportationDurations = getFilteredTransportationDurations(transportationItems, type);
      const totalTime = sumTripTime(filteredTransportationDurations)
      result[type] = {
        total: totalTime,
        average: filteredTransportationDurations.length > 0 ? totalTime / filteredTransportationDurations.length : 0,
        averagePerDay: numDays > 0 ? totalTime / numDays : 0,
        max: maxTripTime(filteredTransportationDurations),
        min: minTripTime(filteredTransportationDurations),
        count: filteredTransportationDurations.length
      } as DurationStats
    }
    return result;
  }

  const generateTrip = (batch: firebase.firestore.WriteBatch, waypoints: Array<Waypoint>, directions: Directions,
                        numDays: number, transportationStats: TransportationStats) => {
    const trip: Trip = {
      stage: TripCreationStage.ITINERARY,
      numDays,
      authorId: uid,
      highlightedMedia: waypoints.map(w => w.media[0]),
      route: arrayToMap(directions.route),
      routeWaypoints: arrayToMap(directions.waypoints),
      distances: directions.distances,
      durations: directions.durations,
      startDate: waypoints[0].startDate,
      endDate: waypoints[waypoints.length-1]?.endDate,
      transportationStats
    } as Trip

    const tripRef = db.doc(`${dbPath}/trips/${tripId}`)
    batch.update(tripRef, trip)

    return trip
  }

  useEffect(() => {
    db.collection(`${dbPath}/trips/${tripId}/media`)
      .orderBy("creationDate", "asc")
      .get()
      .then(async (snapshot) => {

        const allMedia = snapshot.docs.map(doc => doc.data() as MediaFile);
        // debugger;
        let batch = db.batch();

        generateMediaDocs(batch, allMedia)

        const {waypoints, numDays} = await generateWaypoints(batch, allMedia);

        // fetch the route from the mapbox api and store it in firestore
        const directions = await getDirections(waypoints);

        const transportation = generateTransportation(batch, waypoints, directions);

        const transportationStats = calculateTripTimeStats(transportation, numDays)

        generateTrip(batch, waypoints, directions, numDays, transportationStats)

        batch.commit().then(() => {
          setTimeout(props.onGenerationFinished, 5000)
        });

        // add to server queue
        await db.collection("trips_queue").doc().set({tripId, uid})
      })
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={`absolute inset-0 flex flex-col items-center justify-center 
                      overflow-hidden p-10 z-30`}
    >
      <div className="rounded-lg flex flex-col items-center justify-center relative overflow-hidden
                    p-10 backdrop-filter backdrop-blur-sm bg-opacity-100 bg-white space-y-4">
        <div className="text-lg">
          Generating your trip
        </div>
        <Spinner className="text-primary"/>
      </div>
    </div>
  );
};

export default TripItineraryGenerator;
