import React, {FunctionComponent, useEffect, useRef} from 'react';
import InputErrorPopover from "./InputErrorPopover";

interface OwnProps {
  type: "password" | "email" | "text" | "number",
  placeholder?: string,
  value: string | number,
  onChange: (event: any) => void,
  onKeyDown?: (event: React.KeyboardEvent) => void,
  required?: boolean,
  pattern?: string,
  autoFocus?: boolean,
  disabled?: boolean
  label?: string,
  hint?: string,
  maxLength?: number
  icon?: any
  expandWidth?: boolean
  min?: number
  max?: number
  className?: string
  center?: boolean
  enterKeyHint?: "send" | "search" | "enter" | "done" | "go" | "next" | "previous"
  inputMode?: "email" | "text" | "search" | "none" | "tel" | "url" | "numeric" | "decimal"
  ref?: any
  labelClassName?: string
  error?: string
  onResetError?: () => void
}

type Props = OwnProps;

const Input: FunctionComponent<Props> = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {expandWidth, label, hint, icon, center, labelClassName, onResetError, ...inputProps} = props;

  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef && props.error) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100)
    }
  }, [inputRef, props.error])

  return (
    <div className={`flex flex-col space-y-2 ${expandWidth && "flex-1"}`}>
      {
        label && (
          <div className={`${labelClassName}`}>
            {label}
          </div>
        )
      }
      <div className="relative text-gray-500 focus-within:text-purple-500">
        {
          icon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none ">
              {icon}
            </div>
          )
        }
        <input
          {...inputProps}
          onBlur={props.onResetError}
          ref={ref ?? inputRef}
          className={`py-2 px-4 bg-white placeholder-gray-400 text-gray-900 rounded shadow px-3 py-2
          border-solid border outline-none rounded focus:border-primary appearance-none w-full block 
          ${icon && !center && "pl-12"} focus:outline-none ${props.className}`}
        />
        {
          props.error && (
            <InputErrorPopover>
              {props.error}
            </InputErrorPopover>
          )
        }
      </div>
      {
        hint && (
          <div className="text-xs text-center text-gray-700">
            {hint}
          </div>
        )
      }
    </div>
  );
});

export default Input;
