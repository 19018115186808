import axios from "axios";
import Waypoint from "./models/Waypoint";
import {distance, point, Units} from "@turf/turf";

const accessToken = "pk.eyJ1IjoiaXRhaWNhc3BpIiwiYSI6ImNrcnJtNm9lMzVtOW4yd3A4MnlmNHRsbnEifQ.bJ7CXiiLtDAgx48xxWgO5w";

const get = (url: string) => {
  return axios.get(url, {responseType: "json"});
}

export type Coordinates = [number, number]

export type Directions = {
  route: Array<Array<Coordinates>>,
  waypoints: Array<Array<Coordinates>>,
  distances: Array<number>, // in km
  durations: Array<number> // in minutes
}

export const getDirections = async (waypoints: Array<any>) : Promise<Directions> => {
  let route: Array<any> = []
  let routeWaypoints: Array<any> = []
  let distances: Array<any> = []
  let durations: Array<any> = []
  // debugger;
  let batchSize = 25;
  for (let i = 0; i < waypoints.length; i += (batchSize-1)) {
    let e = waypoints.slice(i, Math.min(i+batchSize, waypoints.length))
      .map((waypoint: Waypoint) => waypoint.coordinates.join(","))

    if (e.length <= 1) break;

    const coordinatesString = e.join(";")

    // console.log(e)
    // https://www.mapbox.com/api-documentation/#directions
    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinatesString}?geometries=geojson&steps=true&&access_token=${accessToken}`;
    const response = await get(url);
    if (response.data.routes.length === 0) {
      // no routes found. but we now need to break the batch.
      console.log("WARNING: no route found")
      if (batchSize !== 2) {
        batchSize = 2;
        i -= 1;
      }
      continue;
    }

    route = route.concat(response.data.routes[0].geometry.coordinates)
    routeWaypoints = routeWaypoints.concat(response.data.waypoints.map((w: any) => w.location).slice(i > 0 ? 1 : 0))
    distances = distances.concat(response.data.routes[0].legs.map((l: any) => l.distance / 1000))  // to km
    durations = durations.concat(response.data.routes[0].legs.map((l: any) => l.duration / 60))  // to minutes
  }

  return {
    route: route,
    waypoints: routeWaypoints,
    distances: distances, // in km
    durations: durations // in minutes
  };
}

export const getLocation = async (place: string) => {
  // https://docs.mapbox.com/api/search/geocoding/
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${place}.json?access_token=${accessToken}`;
  const response = await get(url);
  return response.data.features;
}

export const getPlaceName = async (location: Array<number>) => {
  // https://docs.mapbox.com/api/search/geocoding/
  var url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${location[0]},${location[1]}.json?types=poi,place&access_token=` + accessToken;
  const response = await get(url);
  return response.data.features;
}

export const formatPlaceName = (place: any) => {
  let formattedName = "";
  if (place.length > 0) {
    if (place[0].place_type[0] === "poi") {
      formattedName = place[0].text;
      if (place[0].context.findIndex((c: any) => c.id.includes("place")) !== -1) {
        formattedName += ", " + place[0].context.find((c: any) => c.id.includes("place")).text
      } else if (place[0].context.findIndex((c: any) => c.id.includes("region")) !== -1) {
        formattedName += ", " + place[0].context.find((c: any) => c.id.includes("region")).text
      }
    } else if (place[0].place_type[0] === "place") {
      formattedName = place[0].text;
    }
  }
  return formattedName;
}

export const extractPlaceContext = (place: any, context: "country" | "region" | "district" | "place") => {
  if (place.length > 0) {
    return place[0].context.find((c: any) => c.id.includes(context))?.text ?? ""
  } else {
    return ""
  }
}

export const coordinatesToDegrees = (coords: Array<number>, direction: "N" | "W" | "S" | "E" | undefined) => {
  const sign = ["N", "E"].includes(direction ?? "") ? 1 : -1
  return sign * (coords[0] + coords[1]/60 + coords[2]/3600)
}

// gets the time in minutes to travel between two points in the given speed based on aerial distance
// from, and to are geographical coordinates, travelSpeed is the speed in km/h
export const travelTime = (from: Array<number>, to: Array<number>, travelSpeed: number) => {
  const fromPoint = point(from);
  const toPoint = point(to);
  const options = {units: 'kilometers' as Units};
  const distanceBetweenPoints = distance(fromPoint, toPoint, options);
  return 60 * distanceBetweenPoints / travelSpeed; // in minutes
}