import React, { FunctionComponent } from 'react';
import Heading from "./Heading";

interface OwnProps {
  addBorder?: boolean
}

type Props = OwnProps;

const StyledHeading: FunctionComponent<Props> = (props) => {
  return (
    <div className="relative ml-1 mr-auto">
      <div className={`absolute inset-0 ${props.addBorder && "border-l-4"} border-primary-dark z-0 px-4 py-2 bg-gradient-to-br
              from-primary to-primary-dark skew-x-6 transform rounded`}/>
      <Heading priority={3} className="text-shadow text-white z-10 relative py-1 px-4 font-bold">
        {props.children}
      </Heading>
    </div>
  );
};

export default StyledHeading;
