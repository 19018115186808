import React, {FunctionComponent} from 'react';
import SmallTripCard from "./SmallTripCard";
import Trip from "../../models/Trip";
import HorizontalScroller from "../../components/HorizontalScroller";
import YearMarker from "./YearMarker";

interface OwnProps {
  userTrips?: Array<Trip>
}

type Props = OwnProps;

const TripCardsSlider: FunctionComponent<Props> = (props) => {
  const {userTrips} = props;
  return (
    <HorizontalScroller className="py-2 scrollbar-thin-2 scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-500 scrollbar-track-transparent">
      <div className="flex float-left pr-4">
        {
          userTrips?.map((trip, index) => {
            const previousTripYear: number = userTrips[index - 1]?.startDate.toDate().getFullYear();
            const currentTripYear: number = trip.startDate.toDate().getFullYear();
            const showYear = previousTripYear !== currentTripYear;
            return (
              <div key={trip.id} className="my-5 relative flex items-center">
                <YearMarker>
                  {showYear ? currentTripYear : null}
                </YearMarker>
                <div
                  className="transform hover:-translate-y-2 transition-all duration-300 ease-in-out"
                >
                  <SmallTripCard
                    coverImage={trip.coverImage}
                    trip={trip}
                  />
                </div>
              </div>
            )
          })
        }
      </div>
    </HorizontalScroller>
  );
};

export default TripCardsSlider;
