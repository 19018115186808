import {motion, MotionStyle} from 'framer-motion';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useIntersection} from "react-use";

interface OwnProps {
  src: string
  alt: string
  className: string
  style: MotionStyle | undefined
  onClick: (e: React.MouseEvent) => void
  root?: any
  layoutId?: string
}

type Props = OwnProps;

const LazyImage: FunctionComponent<Props> = (props) => {
  const [shouldLoad, setShouldLoad] = useState(false)
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: props.root ?? null,
    rootMargin: '700px',
    threshold: 0.01
  });

  useEffect(() => {
    // console.log(intersection)
    if (intersection && intersection.isIntersecting && !shouldLoad) {
      setShouldLoad(true)
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersection])

  if (!shouldLoad) {
    return <div ref={intersectionRef}/>
  } else {
    return (
// eslint-disable-next-line
      <motion.img {...props}/>
    );
  }
};

export default LazyImage;
