import React, {FunctionComponent} from 'react';
import FaceCluster from "../../models/FaceCluster";
import Avatar from "../../components/Avatar";
import {capitalize} from "../../utils";
import useStore from "../../stores/store";
import {useMediaViewer} from "../../components/FullScreenMediaViewer";

interface OwnProps {
  faces: Array<FaceCluster>
}

type Props = OwnProps;

const FacesBar: FunctionComponent<Props> = (props) => {
  const setMediaAndShow = useMediaViewer(store => store.setMediaAndShow);
  const [uid, trip] = useStore(state => [state.uid, state.trip])
  const faceCategories: {[category: string]: Array<FaceCluster>} = {
    toddlers: props.faces.filter(f => f.age < 2),
    children: props.faces.filter(f => 2 <= f.age && f.age < 12),
    youth: props.faces.filter(f => 12 <= f.age && f.age < 18),
    adults: props.faces.filter(f => 18 <= f.age && f.age < 65),
    elders: props.faces.filter(f => 65 <= f.age)
  }
  const ages: {[category: string]: string} = {
    toddlers: "under 2",
    children: "2-12",
    youth: "12-18",
    adults: "18-65",
    elders: "over 65"
  }
  return (
    <div className="flex p-4 justify-start items-center bg-gray-50 rounded-lg">
      {
        Object.keys(faceCategories).map((category: string) => {
          if (faceCategories[category].length === 0) {
            return null;
          } else {
            return (
              <div key={category} className="flex flex-col space-y-2">
                <div>
                  <div className="flex items-center space-x-2">
                    <div className="">
                      {faceCategories[category].length} {capitalize(category)}
                    </div>
                    <div className="text-sm text-gray-400">
                      {ages[category]}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row flex-wrap">
                  {
                    faceCategories[category].map(face => (
                      <Avatar
                        key={face.url}
                        className="my-1 -mx-1"
                        size="md"
                        src={face.url}
                        anonymize={trip?.authorId !== uid && trip?.anonymize}
                        anonymizationType="beam"
                        onClick={() => setMediaAndShow(
                          face.media.map(m => trip?.mediaOverview?.urls[m] ?? ""),
                          0
                        )}
                      />
                    ))
                  }
                </div>
              </div>
            )
          }
        })
      }

    </div>
  );
};

export default FacesBar;
