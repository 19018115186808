import React, {FunctionComponent, useState} from 'react';
import TripCard from "./TripCard";
import TripHighlightedFeatures from "./TripHighlightedFeatures";
import ActionBar from "./ActionBar";
import TripComments from "./TripComments";
import SharePopover from "./SharePopover";
import {SwipeEventData} from "react-swipeable";
import useIsMobile from "../../hooks/useIsMobile";
import useStore from "../../stores/store";
import {usePopover} from "../../components/Popover";

interface OwnProps {
  isVisible: boolean
  onSwiped: (eventDate: SwipeEventData) => void
}

type Props = OwnProps;

const TripPost: FunctionComponent<Props> = (props) => {
  const isMobile = useIsMobile()
  const [
    trip,
    highlightedMedia,
    setHighlightedMedia,
    selectedFeature,
    setSelectedFeature,
    fullScreenSlideShow,
    setFullScreenSlideShow,
    isTripCommentsVisible
  ] = useStore(state => [
    state.trip,
    state.highlightedMedia,
    state.setHighlightedMedia,
    state.selectedFeature,
    state.setSelectedFeature,
    state.fullScreenSlideShow,
    state.setFullScreenSlideShow,
    state.isTripCommentsVisible
  ])
  const [showPopover] = usePopover(popover => [popover.showPopover])
  const handleFeatureSelection = (feature?: string, mediaIds?: Array<string>) => {
    setSelectedFeature(feature)
    setHighlightedMedia(mediaIds)
    if (isMobile) {
      setFullScreenSlideShow(true)
    }
  }

  const labelsOverview = trip?.mediaOverview?.labels;

  return (
    <div className={`${props.isVisible ? "opacity-100" : "opacity-0"} transition-all duration-500 
        w-full h-full relative pointer-events-none`}>
      <div className={`h-[90vh] sm:h-[100vh] w-full flex items-center justify-center pointer-events-none`}>
        <div className="p-10 w-40 pointer-events-none hidden sm:block">
          {/*<ActionBar/>*/}
        </div>
        <div className="h-[100%] sm:h-[90vh] w-[100%] sm:w-[70%] flex sm:space-x-4 relative pointer-events-auto">
          <TripCard
            highlightedMedia={highlightedMedia}
            trip={trip}
            onSwiped={props.onSwiped}
          />
          <div className={`transition-all duration-300 ease-in-out absolute inset-0 bg-white p-4 sm:p-0 
                  sm:bg-transparent sm:relative z-50 sm:z-40
                  ${(!labelsOverview || fullScreenSlideShow) && !isTripCommentsVisible ? "w-0 opacity-0 pointer-events-none"
                    : "sm:w-[70%] opacity-100 pointer-events-auto"}`}>
            <TripHighlightedFeatures
              onSelect={handleFeatureSelection}
              selectedFeature={selectedFeature}
              onHide={() => setFullScreenSlideShow(!fullScreenSlideShow)}
              className={`transition-all duration-300 ease-in-out ${fullScreenSlideShow ? "opacity-0" : ""}`}
            />
            <TripComments/>
          </div>
        </div>
        <div className="absolute right-0 top-14 sm:right-auto sm:top-auto sm:relative sm:p-10 pointer-events-auto z-10">
          <ActionBar
            tripId={trip?.id}
            onShare={() => showPopover(<SharePopover/>)}
          />
        </div>
      </div>
    </div>

  );
};

export default TripPost;
