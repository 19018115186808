import React, {FunctionComponent, useState} from 'react';
import SignUpForm from "./SignUpForm";
import NavigationBar from "../../components/NavigationBar";
import {useHistory} from "react-router-dom";
import ProfilePhotoSelectionForm from "./ProfilePhotoSelectionForm";
import Carousel from "../../components/Carousel";
import {emailVerificationPath, loginPath} from "../../helpers/constants";
import CTAButton from "../../components/CTAButton";

interface OwnProps {}

type Props = OwnProps;

const SignUpPage: FunctionComponent<Props> = (props) => {
  const history = useHistory()
  const [step, setStep] = useState<"signup" | "photo">("signup");

  return (
    <div className="flex flex-col items-center justify-center w-screen py-24 h-screen overflow-y-auto">
      <NavigationBar>
        <CTAButton
          type="purple-outline"
          onClick={() => history.push(loginPath)}
        >Log in</CTAButton>
      </NavigationBar>
      <div className="max-w-screen-lg flex flex-col items-center justify-center w-full space-y-10 text-center pt-12">
        <Carousel
          type="slide-left"
          duration={1}
          reverseExit
          visibleKey={step}
          slides={{
            "signup": <SignUpForm onSignup={() => setStep("photo")}/>,
            "photo": <ProfilePhotoSelectionForm onSubmit={() => history.push(emailVerificationPath)}/>
          }}
          className="flex flex-col space-y-10"
        />
      </div>
    </div>
  );
};

export default SignUpPage;
