import React, {FunctionComponent, useEffect, useState} from 'react';
import {default as BoringAvatar} from "boring-avatars";

interface OwnProps {
  userId?: string
  src?: string
  anonymize?: boolean
  anonymizationType?: "marble" | "beam"
  size: "sm" | "md" | "lg"
  className?: string
  onHover?: (e: React.MouseEvent, isHovered: boolean) => void
  onClick?: (e: React.MouseEvent) => void
}

type Props = OwnProps;

const Avatar: FunctionComponent<Props> = (props) => {
  const fallbackPath = undefined

  const [avatarPath, setAvatarPath] = useState<undefined | string>(fallbackPath);

  useEffect(() => {
    if (props.userId) {
      setAvatarPath(`https://firebasestorage.googleapis.com/v0/b/tripsik-1.appspot.com/o/users%2F${props.userId}-thumbnail.jpg?alt=media`)
    } else if (props.src) {
      setAvatarPath(props.src)
    }
  }, [props.userId, props.src])

  let sizeClasses = "";
  if (props.size === "sm") {
    sizeClasses = "h-8 w-8"
  } else if (props.size === "md") {
    sizeClasses = "h-10 w-10 sm:h-14 sm:w-14"
  } else if (props.size === "lg") {
    sizeClasses = "h-16 w-16 sm:h-24 sm:w-24"
  }

  const handleImageError = () => {
    if (avatarPath !== fallbackPath) {
      setAvatarPath(fallbackPath)
    }
  }

  if (!avatarPath) {
    return <div className={`rounded-full object-cover object-center ${sizeClasses} hover:shadow-md select-none
                  cursor-pointer transition-all transform duration-200 bg-gray-200 ${props.className}`}/>
  }

  return (
    <div className={`rounded-full ${sizeClasses}
                  ${props.onClick && "hover:shadow-lg cursor-pointer"}
                   select-none shadow-md border border-white
                   transition-all transform duration-200 ${props.className}`}>
      {
        !props.anonymize ? (
          <img
            onClick={props.onClick}
            onMouseEnter={(e) => props.onHover &&props.onHover(e, true)}
            onMouseOut={(e) => props.onHover &&props.onHover(e, false)}
            src={avatarPath}
            alt="avatar"
            className={`rounded-full object-cover object-center w-full h-full`}
            onError={handleImageError}
          />
        ) : (
          <BoringAvatar
            size={"w-full h-full"}
            name={avatarPath}
            variant={props.anonymizationType}
            colors={["#A3A948", "#EDB92E", "#F85931", "#CE1836", "#009989"]}
          />
        )
      }
    </div>

  );
};

export default Avatar;
