import React, { FunctionComponent } from 'react';
import Popover from "../../components/Popover";
import TextEditor from "../../components/TextEditor";
import useStore from "../../stores/store";

interface OwnProps {}

type Props = OwnProps;

const ContentEditorPopover: FunctionComponent<Props> = (props) => {
  const [
    trip,
    setShowTextEditorForWaypoint,
    setCurrentTripWaypointContent,
    showTextEditorForWaypoint
  ] = useStore(state => [
    state.trip,
    state.setShowTextEditorForWaypoint,
    state.setCurrentTripWaypointContent,
    state.showTextEditorForWaypoint
  ])

  return (
    <TextEditor
      initialContent={trip?.waypoints && trip?.waypoints[showTextEditorForWaypoint]?.content}
      onSubmit={(content) => {
        setCurrentTripWaypointContent(showTextEditorForWaypoint, content)
        setShowTextEditorForWaypoint(-1)
      }}
    />
  );
};

export default ContentEditorPopover;
