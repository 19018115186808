import React, { FunctionComponent } from 'react';
import Avatar from "../Avatar";
import Spinner from "../Spinner";

interface OwnProps {
  uid: string
}

type Props = OwnProps;

const CommentSpinner: FunctionComponent<Props> = (props) => {
  return (
    <div className="flex flex-row items-start space-x-2">
      <Avatar userId={props.uid} size="sm"/>
      <div
        className="px-4 py-2 bg-gray-100 rounded-xl relative"
      >
        <div className="absolute top-0 -left-2 w-6 overflow-hidden inline-block">
          <div className="h-6 w-6 bg-gray-100 -rotate-45 transform origin-top-left rounded-lg"/>
        </div>
        <Spinner className="text-primary my-[0.1rem]"/>
      </div>
    </div>
  );
};

export default CommentSpinner;
