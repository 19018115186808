import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import ContentEditable, {ContentEditableEvent} from "react-contenteditable";
import OverflowList from "./OverflowList";
import LabelTag from "../pages/TripViewer/LabelTag";
import Button from "./Button";

interface OwnProps {
  initialContent?: string
  onSubmit: (content: string) => void
}

type Props = OwnProps;

const TextEditor: FunctionComponent<Props> = (props) => {
  const contentRef = useRef<any>(undefined);
  const [content, setContent] = useState(props.initialContent ?? "");
  const [tagsCompletion, setTagsCompletion] = useState(false);
  const [contentAvailable, setContentAvailable] = useState((props.initialContent ?? "") !== "");

  const handleContentChange = (e: ContentEditableEvent) => {
    const newContent = e.target.value;
    const lastChar = (e.nativeEvent as any).data;
    const inputType = (e.nativeEvent as any).inputType;
    const oldHashCount = content.split("#").length
    const newHashCount = newContent.split("#").length
    if (!tagsCompletion && lastChar === "#") {
      setTagsCompletion(true)
    } else if (tagsCompletion && ((inputType === "insertText" && lastChar === " ")
      || (inputType === "insertText" && lastChar === null)
      || (inputType === "deleteContentBackward" && oldHashCount !== newHashCount))) {
      setTagsCompletion(false)
    }
    setContentAvailable(newContent !== "" && newContent !== "<br>")
    setContent(newContent)
  }

  const handleKeyUp = (e: React.KeyboardEvent) => {
    // console.log(e)
  }

  useEffect(() => {
    contentRef.current?.focus()
  }, [])

  const handleSubmit = () => {
    props.onSubmit(content)
  }

  return (
    <div className="relative font-light">
      {
        !contentAvailable && (
          <div className="absolute left-0 top-0 text-gray-400 -z-10 select-none p-10">
            Type your description here.
          </div>
        )
      }
      <ContentEditable
        innerRef={contentRef}
        className={`relative left-0 top-0 right-0 outline-none z-[70] p-10 ${!contentAvailable && "pb-6"}`}
        html={content}
        onChange={handleContentChange}
        onKeyUp={handleKeyUp}
      />
      {
        tagsCompletion && (
          <OverflowList
            className="mt-4 ml-8"
            items={["animals", "city", "sea"]}
            itemRenderer={(item, index) => (
              <LabelTag
                key={index}
                label={item}
                onClick={() => {}}
              />
            )}
            overflowRenderer={(items) => (
              <div>+ {items.length} more</div>
            )}
          />
        )
      }
      <div className="flex justify-end">
        <Button
          onClick={handleSubmit}
          disabled={content === ""}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default TextEditor;
