import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import Transition from "./Transition";
import Spinner from "./Spinner";
import {motion} from "framer-motion";
import create, {State} from "zustand";
import AnimatedLazyImage from "./AnimatedLazyImage";

interface OwnProps {
}

type Props = OwnProps;


interface useMediaViewerInterface extends State {
  media: Array<string>
  setMedia: (media: Array<string>) => void
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void,
  currentMediaIndex: number
  setCurrentMediaIndex: (currentMediaIndex: number) => void
  setMediaAndShow: (media: Array<string>, currentMediaIndex: number) => void
}


export const useMediaViewer = create<useMediaViewerInterface>(set => ({
  media: [],
  setMedia: (media: Array<string>) => set({media}),
  isVisible: false,
  setIsVisible: (isVisible: boolean) => set({isVisible}),
  currentMediaIndex: 0,
  setCurrentMediaIndex: (currentMediaIndex: number) => set({currentMediaIndex}),
  setMediaAndShow: (media: Array<string>, currentMediaIndex: number) => {
    set({
      media: media,
      currentMediaIndex: currentMediaIndex,
      isVisible: true
    })
  }
}))


const FullScreenMediaViewer: FunctionComponent<Props> = (props) => {
  const mediaViewerStore = useMediaViewer()
  const viewerRef = useRef<any>(null);

  useEffect(() => {
    if (viewerRef !== null) {
      viewerRef.current?.focus();
    }
  }, [mediaViewerStore.isVisible])

  const nextMedia = (e?: React.MouseEvent) => {
    e && e.stopPropagation();
    mediaViewerStore.setCurrentMediaIndex(Math.min(mediaViewerStore.media.length - 1, mediaViewerStore.currentMediaIndex + 1))
  }
  const prevMedia = (e?: React.MouseEvent) => {
    e && e.stopPropagation();
    mediaViewerStore.setCurrentMediaIndex(Math.max(0, mediaViewerStore.currentMediaIndex - 1))
  }
  const handleNavigateMedia = (e: React.KeyboardEvent) => {
    if (e.code === 'ArrowLeft' && mediaViewerStore.currentMediaIndex > 0) {
      prevMedia()
    } else if (e.code === 'ArrowRight' && mediaViewerStore.currentMediaIndex < mediaViewerStore.media.length - 1) {
      nextMedia()
    } else if (e.code === 'Escape') {
      mediaViewerStore.setIsVisible(false)
    }
  }
  const handleHide = () => {
    mediaViewerStore.setIsVisible(false)
  }
  return (
    <Transition
      uniqueKey="imageviewer"
      className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-75 cursor-pointer backdrop-filter backdrop-blur"
      isVisible={mediaViewerStore.isVisible}
    >
      <div
        className="z-30 w-full sm:w-[90%] h-[90%] flex items-center justify-center outline-none  cursor-pointer"
        onClick={handleHide}
        onKeyDown={handleNavigateMedia}
        ref={viewerRef}
        tabIndex={0}
      >
        <div
          id="bottom-gradient-background"
          className="block sm:hidden absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-b from-transparent to-black z-20"
        />
        {
          mediaViewerStore.currentMediaIndex > 0 && (
            <svg xmlns="http://www.w3.org/2000/svg"
                 onClick={prevMedia}
                 className="absolute h-24 w-24 sm:h-32 sm:w-32 my-auto left-10 sm:top-0 bottom-0 text-white cursor-pointer hover:text-gray-400 z-50 p-6"
                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          )
        }
        {
          mediaViewerStore.currentMediaIndex < mediaViewerStore.media.length - 1 && mediaViewerStore.media.length > 1 && (
            <svg xmlns="http://www.w3.org/2000/svg"
                 className="absolute h-24 w-24 sm:h-32 sm:w-32 my-auto right-10 sm:top-0 bottom-0 text-white cursor-pointer hover:text-gray-400 z-50 p-6"
                 onClick={nextMedia}
                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          )
        }
        <div className="absolute inset-0 z-30 w-full h-full flex flex-col items-center justify-center">
          <Spinner className="text-white"/>
        </div>
        <AnimatedLazyImage
          key={mediaViewerStore.currentMediaIndex}
          src={mediaViewerStore.media[mediaViewerStore.currentMediaIndex]}
          // alt=""
          className={`h-full object-scale-down rounded-lg overflow-hidden select-none z-40`}
          // onLoad={() => setLoading(false)}
          maxSide="90vh"
          fit="height"
        />
        <div className="absolute top-3 left-1/2 -translate-x-1/2 text-white z-30 text-sm">
          Click anywhere to close
        </div>
      </div>
    </Transition>
  );
};

export default FullScreenMediaViewer;
