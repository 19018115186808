import React, {createRef, FunctionComponent, useEffect, useState} from 'react';
import {useScroll} from "react-use";

interface OwnProps {
  label?: string
  days: Array<number>
  selectedDayIndex: number
  setSelectedDayIndex: (day: number) => void
}

type Props = OwnProps;

const TripDaySelector: FunctionComponent<Props> = (props) => {
  const scrollRef = React.useRef<any>(null);
  const [dayRefs, setDayRefs] = useState<any>([]);
  const {x} = useScroll(scrollRef);
  const maxScroll = scrollRef.current?.scrollWidth - scrollRef.current?.clientWidth;
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'ArrowLeft' && props.selectedDayIndex > 0) {
      props.setSelectedDayIndex(props.selectedDayIndex - 1)
    } else if (e.code === 'ArrowRight' && props.selectedDayIndex < props.days.length - 1) {
      props.setSelectedDayIndex(props.selectedDayIndex + 1)
    }
  }

  // create refs for each day tab
  useEffect(() => {
    if (dayRefs.length === 0) {
      const newDayRefs = Array.from(Array(props.days.length).keys()).map((_) => createRef());
      setDayRefs(newDayRefs)
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // scroll to reveal the current day
  useEffect(() => {
    if (dayRefs[props.selectedDayIndex]) {
      scrollRef?.current?.scrollTo({
        left: dayRefs[props.selectedDayIndex]?.current?.offsetLeft - 150,
        behavior: "smooth",
      })
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedDayIndex])

  const handleScroll = (offset: number) => {
    scrollRef?.current?.scrollTo({
      left: scrollRef?.current?.scrollLeft + offset,
      behavior: "smooth",
    })
  }

  return (
    <div
      tabIndex={0}
      onKeyDown={handleKeyDown}
      className="flex flex-row rounded-full h-10 p-2 bg-white items-center justify-center
                  text-sm shadow-lg space-x-2 mx-2 z-20 relative group"
    >
      <div
        onClick={() => handleScroll(-50)}
        className={`opacity-0 pointer-events-none ${x > 0 && "group-hover:opacity-100 pointer-events-auto cursor-pointer"} z-10
                    transition-all transform duration-300 flex items-center justify-start rounded-full
                  bg-gradient-to-l from-[#ffffffff] to-white absolute left-0 top-0 bottom-0 w-16 `}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </div>
      {
        props.days.length > 6 && (
          <div
            onClick={() => handleScroll(50)}
            className={`opacity-0 pointer-events-none ${x !== maxScroll && "group-hover:opacity-100 pointer-events-auto cursor-pointer"} 
                      transition-all transform 
                      duration-300 flex items-center justify-end bg-opacity-75 z-10 rounded-full 
                      bg-gradient-to-r from-[#ffffffff] to-white absolute right-0 top-0 bottom-0 w-16`}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        )
      }
      <div className="px-2 font-semibold select-none">
        {props.label}
      </div>
      <div className="flex flex-row flex-nowrap space-x-1 overflow-x-scroll no-scrollbar max-w-[300px]" ref={scrollRef}>
        {
          props.days.map((day, i) => (
            <button
              key={i}
              ref={dayRefs[i]}
              className={
                `${i === props.selectedDayIndex ? "bg-gradient-to-br from-purple-light/80 to-purple-extra-dark text-white shadow-md" : "bg-white text-black hover:bg-purple/10" }
                      cursor-pointer px-4 py-1 rounded-full outline-none h-8 whitespace-nowrap select-none`
              }
              onClick={() => props.setSelectedDayIndex(i)}
            >
              {day}
            </button>
          ))
        }
      </div>

    </div>
  );
};

export default TripDaySelector;
