import React, {FunctionComponent} from 'react';
import {sortByFrequency} from "../../utils";
import HighlightedFeatureCard from "./HighlightedFeatureCard";
import StyledHeading from "../../components/StyledHeading";
import useStore from "../../stores/store";

interface OwnProps {
  onSelect: (feature?: string, mediaIds?: Array<string>) => void
  selectedFeature?: string
  onHide?: () => void
  className?: string
}

type Props = OwnProps;

const TripHighlightedFeatures: FunctionComponent<Props> = (props) => {
  const trip = useStore(state => state.trip)
  const labelsOverview = trip?.mediaOverview?.labels ?? {};
  const features = sortByFrequency(Object.values(labelsOverview).map(m => m.features).flat().filter(k => k)).slice(0, 8)
  const featuresMedia: {[feature: string]: Array<string>} = {}
  features.forEach(feature => {
    featuresMedia[feature] = Object.keys(labelsOverview)
      .filter(key => labelsOverview[key].features?.includes(feature))
  })

  const urls = trip?.mediaOverview?.thumbnailUrls ?? {}

  const handleFeatureSelection = (feature: string) => {
    if (props.selectedFeature === feature) {
      // unselect
      props.onSelect(undefined, undefined)
    } else {
      props.onSelect(feature, featuresMedia[feature])
    }
  }
  return (
    <div className={`h-full sm:h-[90vh] flex flex-col space-y-4 ${props.className}`}>
      <div className="flex justify-between items-center">
        <StyledHeading addBorder>Highlights</StyledHeading>
        <div onClick={props.onHide} className="text-gray-500 sm:text-white cursor-pointer hover:text-gray-200">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2 h-full grid-rows-4">
        {
          features.map(feature => {
            const isSelected = props.selectedFeature === feature
            return (
              <HighlightedFeatureCard
                key={feature}
                imageUrl={urls[featuresMedia[feature][0]]}
                onClick={() => handleFeatureSelection(feature)}
                isSelected={isSelected}
                feature={feature}
              />
            )
          })
        }

      </div>
    </div>
  );
};

export default TripHighlightedFeatures;
