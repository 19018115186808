import {DateTime, DurationObjectUnits} from "luxon";

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
export const unique = (array: Array<any>, byValue?: (key: any) => any, maxLength?: number) => {
  const map: any = {}
  for (const i in array) {
    const v = array[i]
    const key = byValue ? byValue(v) : v
    if (!(key in map)) {
      map[key] = v;
    }
    if (maxLength && Object.keys(map).length >= maxLength) {
      return Object.keys(map)
    }
  }
  return Object.keys(map);
}

export const sortByFrequency = (array: Array<any>) => {
  const frequency: any = {}
  array.forEach(v => frequency[v] = 0)
  array.forEach(v => frequency[v]++)
  function compareFrequency(a: any, b: any) {
    return frequency[b] - frequency[a];
  }
  return Object.keys(frequency).sort(compareFrequency);
}

export const range = (start: number, end: number, step?: number, offset?: number) => {
  const len = Math.round((Math.abs(end - start) + ((offset || 0) * 2)) / (step || 1) + 1);
  const direction = start < end ? 1 : -1;
  const startingPoint = start - (direction * (offset || 0));
  const stepSize = direction * (step || 1);

  return Array(len).fill(0).map(function(_, index) {
    return startingPoint + (stepSize * index);
  });
}

export const randomChoice = (array: Array<any>) => {
  return array[Math.floor(Math.random() * array.length)]
}

export const subsample = (array: Array<any>, step: number) => {
  console.log(array.length)
  return range(0, array.length, step).map(i => array[i]);
}

let lut: any = []; for (var i=0; i<256; i++) { lut[i] = (i<16?'0':'')+(i).toString(16); }
export function generateUuid()
{
  let d0 = Math.random()*0xffffffff|0;
  let d1 = Math.random()*0xffffffff|0;
  let d2 = Math.random()*0xffffffff|0;
  let d3 = Math.random()*0xffffffff|0;
  // eslint-disable-next-line no-mixed-operators
  return lut[d0&0xff]+lut[d0>>8&0xff]+lut[d0>>16&0xff]+lut[d0>>24&0xff]+'-'+
    // eslint-disable-next-line no-mixed-operators
    lut[d1&0xff]+lut[d1>>8&0xff]+'-'+lut[d1>>16&0x0f|0x40]+lut[d1>>24&0xff]+'-'+
    // eslint-disable-next-line no-mixed-operators
    lut[d2&0x3f|0x80]+lut[d2>>8&0xff]+'-'+lut[d2>>16&0xff]+lut[d2>>24&0xff]+
    // eslint-disable-next-line no-mixed-operators
    lut[d3&0xff]+lut[d3>>8&0xff]+lut[d3>>16&0xff]+lut[d3>>24&0xff];
}

export const datesAreOnSameDay = (first: Date, second: Date, allowedHoursAfterMidnight?: number) => {
  if (allowedHoursAfterMidnight) {
    first = new Date(first)
    first.setHours(first.getHours() - allowedHoursAfterMidnight)
    second = new Date(second)
    second.setHours(second.getHours() - allowedHoursAfterMidnight)
  }
  return first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();
}

export const arrayEquals = function (array1: Array<any>, array2: Array<any>) {
  // if the other array is a falsy value, return
  if (!array2)
    return false;

  // compare lengths - can save a lot of time
  if (array1.length !== array2.length)
    return false;

  for (let i = 0, l=array1.length; i < l; i++) {
    // Check if we have nested arrays
    if (array1[i] instanceof Array && array2[i] instanceof Array) {
      // recurse into the nested arrays
      if (!array1[i].equals(array2[i]))
        return false;
    }
    else if (array1[i] !== array2[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
}

export const nestedArrayIndexOf = function (array: Array<any>, searchValue: Array<any>)
{
  // if the other array is a falsy value, return -1
  if (!array)
    return -1;

  //start by assuming the array doesn't contain the thing
  let result = -1;
  for (let i = 0, l=array.length; i < l; i++)
  {
    //if anything in the array is the thing then change our mind from before
    if (array[i] instanceof Array)
      if (arrayEquals(array[i], searchValue))
        result = i;
      else
      if (array[i]===searchValue)
        result = i;


  }
  //return the decision we left in the variable, result
  return result;
}


export const arrayToMap = (array: Array<any>) => {
  return Object.fromEntries(array.map((v: number, i: number) => [i, v]));
}

export const getFormattedDateRange = (startDate: Date, endDate: Date) => {
  let tripDates = ""
  if (startDate.getFullYear() === endDate.getFullYear()) {
    if (startDate.getMonth() === endDate.getMonth()) {
      tripDates = `${startDate.toLocaleString('default', { month: 'short' })} ${startDate.getFullYear()}`
    } else {
      tripDates = `${startDate.toLocaleString('default', { month: 'short' })}`
      tripDates += `-${endDate.toLocaleString('default', { month: 'short' })} ${endDate.getFullYear()}`
    }
  } else {
    tripDates = `${startDate.toLocaleString('default', { month: 'short' })} ${startDate.getFullYear()}`
    tripDates += `-${endDate.toLocaleString('default', { month: 'short' })} ${endDate.getFullYear()}`
  }
  return tripDates;
}

export const getFormattedDate = (date: Date) => {
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT)
}

export const getFormattedTime = (date: Date) => {
  return DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_SIMPLE)
}

export const getTimeDifference = (startDate: Date, endDate: Date) => {
 return (endDate.getTime() - startDate.getTime()) / (1000 * 60)
}

// duration in minutes to hours or days
export const getFormattedDuration = (duration: number) => {
  if (duration < 60) {
    return `${Math.round(duration)} minutes`
  } else if (duration < 24 * 60) {
    const hours = Math.round(duration / 60);
    if (hours === 1) {
      return `1 hour`
    } else {
      return `${hours} hours`
    }
  } else {
    const days = Math.round(duration / (60 * 24));
    if (days === 1) {
      return `1 day`
    } else {
      return `${days} days`
    }
  }
}

const units: Array<keyof DurationObjectUnits> = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

export const timeAgo = (date: Date) => {
  let dateTime = DateTime.fromJSDate(date)
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = (units.find((unit) => diff.get(unit) !== 0) || 'second') as Intl.RelativeTimeFormatUnit;

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

export const removeFromArray = (array: Array<any>, itemToRemove: any) => {
  return array.filter(item => item !== itemToRemove)
}