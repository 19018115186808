import React, {FunctionComponent, useEffect} from 'react';
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase";
import {Route, useHistory} from "react-router-dom";
import Spinner from './Spinner';
import UserMenu from "../pages/UserMenu/UserMenu";
import {emailVerificationPath, loginPath} from "../helpers/constants";

interface OwnProps {
  path: string
  exact?: boolean
}

type Props = OwnProps;

const PrivateRoute: FunctionComponent<Props> = (props) => {
  const [user, isLoadingUser,] = useAuthState(auth);

  const history = useHistory();

  useEffect(() => {
    if (!isLoadingUser && !user) {
      history.push(loginPath)
    } else if (!isLoadingUser && user && !user.emailVerified) {
      history.push(emailVerificationPath)
    }
  }, [user, isLoadingUser, history])

  if (isLoadingUser || !user) {
    return (
      <div className="absolute inset-0 flex items-center justify-center">
        <Spinner className="text-primary"/>
      </div>
    )
  } else {
    return (
      <Route path={props.path} exact={props.exact}>
        <UserMenu className="fixed top-4 right-0 sm:right-6 z-50"/>
        {props.children}
      </Route>
    );
  }
};

export default PrivateRoute;
