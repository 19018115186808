import React, {FunctionComponent, useEffect} from 'react';
import FileUploader from "../../components/FileUploader";
import Heading from "../../components/Heading";
import Card from "../../components/Card";
import useFileStore from "../../stores/fileStore";
import Button from "../../components/Button";
import {db} from "../../firebase";
import Transition from "../../components/Transition";
import useStore from "../../stores/store";

interface OwnProps {
  onSubmit: () => void
}

type Props = OwnProps;

const ProfilePhotoSelectionForm: FunctionComponent<Props> = (props) => {
  const [uid, dbPath] = useStore(state => [state.uid, state.dbPath])
  const [getFile, files, setFiles] = useFileStore(store => [store.getFile, store.uploadedFiles, store.setUploadedFiles])
  const file = Object.values(files)[0]

  useEffect(() => {
    setFiles({})
  }, [setFiles])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    props.onSubmit();
  }

  const handleUploadFinished = (uniqueId: string) => {
    const file = getFile(uniqueId)
    db.doc(dbPath)
      .update({
        avatarUrl: file.url ?? null,
        avatarStoragePath: file.storagePath ?? null,
        avatarThumbnailUrl: file.thumbnailUrl ?? null,
        avatarThumbnailStoragePath: file.thumbnailStoragePath ?? null,
      })
  }

  return (
    <>
      <Transition uniqueKey="title" type="slide-up" isVisible delay={0.5}>
        <Heading>
          Pick your profile photo
        </Heading>
      </Transition>
      <Card>
        <form onSubmit={handleSubmit} className="flex flex-col space-y-6">
          <FileUploader
            storageRootPath={"/users"}
            renameFile={uid}
            forceUploadButton
            expand
            backgroundClickable
            onUpload={handleUploadFinished}
          >
            <div className="rounded-full h-60 w-60 overflow-hidden relative group">
              {
                file && (
                  <img
                    src={file.url ?? file.preview}
                    alt="avatar"
                    className={`bg-gray-100 ${!file.url && "animate-pulse"} rounded-full object-cover object-center 
                              hover:shadow-md select-none w-full h-full`}
                  />
                )
              }
              <div className={`absolute inset-0 z-10 flex flex-col space-y-2 items-center justify-center 
                          transition-all transform duration-300
                        ${file ? "opacity-0 group-hover:opacity-100 bg-black bg-opacity-25 text-white" 
                          : "bg-gray-100 text-gray-400 hover:bg-gray-200 hover:text-gray-600"}`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
                <div>
                  {
                    file ? "change photo" : "select photo"
                  }
                </div>
              </div>
            </div>
          </FileUploader>
          <Button
            type="submit"
            disabled={file && !file.url}
            className={file && !file.url ? "animate-pulse" : ""}
          >
            {
              file ? "Continue" : "Skip"
            }
          </Button>
        </form>

      </Card>
    </>
  );
};

export default ProfilePhotoSelectionForm;
