import React, { FunctionComponent } from 'react';
import Waypoint from "../../models/Waypoint";

interface OwnProps {
  waypoint: Waypoint
  isAuthor?: boolean
  onEdit?: () => void
}

type Props = OwnProps;

const WaypointContent: FunctionComponent<Props> = (props) => {
  if (props.isAuthor && (!props.waypoint.content || props.waypoint.content === "")) {
    return (
      <div className="flex pb-6">
        <div
          className="flex space-x-2 text-gray-500 hover:text-gray-600 items-center text-sm select-none cursor-pointer"
          onClick={() => props.onEdit && props.onEdit()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
          <div>
            Add description
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="group relative"
      >
        <div className={`absolute left-0 -top-0 px-4 py-1 -mt-6 bg-primary rounded-t-md text-white text-xs
                              opacity-0 ${props.isAuthor && "group-hover:opacity-90"} pointer-events-none`}>
          click to edit description
        </div>
        <p
          onClick={() => props.isAuthor && props.onEdit && props.onEdit()}
          className={`text-base font-light py-3 px-1 border border-transparent 
                          ${props.isAuthor && "hover:border-primary rounded-tr-sm rounded-b-sm cursor-text"}`}
          dangerouslySetInnerHTML={{__html: props.waypoint.content}}
        />
      </div>
    )
  }
};

export default WaypointContent;
