import React, { FunctionComponent } from 'react';

interface OwnProps {
}

type Props = OwnProps;

const YearMarker: FunctionComponent<Props> = (props) => {
  if (props.children == null) {
    return <div className="w-5"/>
  }
  return (
    <div className="text-shadow text-sm bg-black bg-opacity-10 text-white rotate-90 transform px-2 z-30 rounded-sm select-none">
      {props.children}
    </div>
  );
};

export default YearMarker;
