import React, {FunctionComponent, useEffect, useState} from 'react';
import Heading from "../../components/Heading";
import Input from "../../components/Input";
import usePasswordValidation from "../../hooks/usePasswordValidation";
import useStore from "../../stores/store";

interface OwnProps {}

type Props = OwnProps;

const ProfileSettings: FunctionComponent<Props> = (props) => {
  const [loading,] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState("");
  const [passwordError] = usePasswordValidation(password);
  const user = useStore(state => state.user)

  useEffect(() => {
    if (user) {
      setName(user.name)
    }
  }, [user])

  return (
    <div className="flex flex-col space-y-4">
      <Heading>
        Profile
      </Heading>
      <div className="flex flex-col items-stretch space-y-1">
        <Input
          label="Full Name"
          type="text"
          placeholder="Your full name"
          value={name}
          onChange={e => setName(e.target.value)}
          required
          autoFocus
          disabled={loading}
          maxLength={40}
        />
      </div>
      <div className="flex flex-col items-stretch space-y-2">
        <Input
          label="Set Your Password"
          type="password"
          placeholder="Set a password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          required
          disabled={loading}
          pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
          error={passwordError ?? undefined}
        />
        <Input
          type="password"
          placeholder="Repeat your password"
          value={passwordValidation}
          onChange={e => setPasswordValidation(e.target.value)}
          required
          disabled={loading}
          pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
          error={passwordError ?? undefined}
        />
      </div>
    </div>
  );
};

export default ProfileSettings;
