import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyClaQKpQMctktDy4oGMQUqKI50s76poS6A",
  authDomain: "tripsik-1.firebaseapp.com",
  databaseURL: "https://tripsik-1-default-rtdb.firebaseio.com",
  projectId: "tripsik-1",
  storageBucket: "tripsik-1.appspot.com",
  messagingSenderId: "188465154968",
  appId: "1:188465154968:web:442ec09aa0a63c6696b9ed"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();

export default firebase;