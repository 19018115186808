import React, {FunctionComponent, useEffect} from 'react';
import ItinerarySideBar from "./ItinerarySideBar";
import {useLocation} from "react-use";
import CommentsDrawer from "./CommentsDrawer";
import {useHistory} from "react-router-dom";
import Logo from "../../components/Logo";
import TripActionsBar from "./TripActionsBar";
import useStore from "../../stores/store";
import {Helmet} from "react-helmet";
import ContentEditorPopover from "./ContentEditorPopover";
import {usePopover} from "../../components/Popover";
import {homePath, tripViewerPath} from "../../helpers/constants";
import {allowPullToRefresh, preventPullToRefresh} from "../../helpers/utils";

interface OwnProps {}

type Props = OwnProps;

const TripViewer: FunctionComponent<Props> = (props) => {
  const location = useLocation()
  const history = useHistory()
  const [
    uid,
    currentTrip,
    loadTripFromDB,
    showCommentsForWaypoint,
    setSelectedDay,
    setScrollingToDay,
    setSelectedWaypoint,
    setScrollingToWaypoint,
    setShowCommentsForWaypoint,
    showTextEditorForWaypoint,
    setShowTextEditorForWaypoint
  ] = useStore(state => [
    state.uid,
    state.trip,
    state.loadTripFromDB,
    state.showCommentsForWaypoint,
    state.setSelectedDay,
    state.setScrollingToDay,
    state.setSelectedWaypoint,
    state.setScrollingToWaypoint,
    state.setShowCommentsForWaypoint,
    state.showTextEditorForWaypoint,
    state.setShowTextEditorForWaypoint
  ])
  const params = new URLSearchParams(location.search)
  const tripId = params.get("id")
  const waypointId = params.get("w")
  // const showComments = params.get("c")

  // prevent pull to refresh
  useEffect(preventPullToRefresh, [])

  useEffect(() => {
    if (uid && tripId && (!currentTrip || currentTrip?.id !== tripId || currentTrip.waypoints?.length === 0)) {
      loadTripFromDB(tripId)
    }
  }, [uid, tripId, currentTrip, loadTripFromDB])

  useEffect(() => {
    if (currentTrip && currentTrip.waypoints && currentTrip.waypoints.length > 0 && waypointId) {
      const waypointIndex = currentTrip.waypoints?.findIndex(w => w.id === waypointId) ?? 0
      const waypoint = currentTrip.waypoints[waypointIndex];
      setSelectedDay(waypoint.tripDay)
      setScrollingToDay(waypoint.tripDay)
      setSelectedWaypoint(waypointIndex)
      setScrollingToWaypoint(waypointIndex)
      setShowCommentsForWaypoint(waypointIndex)
      setTimeout(() => {
        history.replace(`${tripViewerPath}?id=${tripId}`)
      }, 2000)
    }
  }, [history, tripId, setSelectedWaypoint, setSelectedDay, setScrollingToDay, setShowCommentsForWaypoint,
    setScrollingToWaypoint, currentTrip, waypointId])

  if (!currentTrip) {
    return <div/>
  }

  return (
    <div className="overflow-y-hidden overscroll-none">
      <Helmet>
        <title>{`tripsik | ${currentTrip.title} Trip`}</title>
      </Helmet>

      <Logo
        height={30}
        className="text-white fixed left-4 sm:left-10 top-8 sm:top-10 z-20 sm:hidden"
        hasShadow
        onClick={() => history.push(homePath)}
      />
      <ItinerarySideBar/>
      <CommentsDrawer isVisible={showCommentsForWaypoint === -1}/>
      <TripActionsBar/>
    </div>
  );
};

export default TripViewer;
