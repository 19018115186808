import { motion } from 'framer-motion';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {randomChoice, range} from "../utils";
import ProgressBar from "./ProgressBar";
import {SwipeEventData, useSwipeable} from "react-swipeable";

interface OwnProps {
  media: Array<string>
  onSwiped?: (eventDate: SwipeEventData) => void
}

type Props = OwnProps;

const StoryImageCarousel: FunctionComponent<Props> = (props) => {
  const swipeHandlers = useSwipeable({
    onSwiped: props.onSwiped ? props.onSwiped : () => {},
    // delta: 10,                            // min distance(px) before a swipe starts. *See Notes*
    // preventDefaultTouchmoveEvent: false,  // call e.preventDefault *See Details*
    // trackTouch: true,                     // track touch input
    // trackMouse: false,                    // track mouse input
    // rotationAngle: 0,                     // set a rotation angle
  });
  const [currentMediaIndex, setCurrentMediaIndex] = useState(-1);
  const [progress, setProgress] = useState(0)
  const [intervalId, setIntervalId] = useState<any>(undefined);
  const [currentMedia, setCurrentMedia] = useState<Array<string>>([]);
  const [maxLoadedMediaIndex, setMaxLoadedMediaIndex] = useState(1)
  const [transitions, setTransitions] = useState<Array<string>>([]);
  const [isPaused, setIsPaused] = useState(false);
  // const [fitTypes, setFitTypes] = useState<Array<string>>([])

  const updateInterval = 200; //ms
  const timePerMedia = 4000; //ms
  const showNextMedia = () => {
    const nextMediaIndex = (currentMediaIndex + 1) % props.media.length;
    setMaxLoadedMediaIndex(Math.max(nextMediaIndex + 1, maxLoadedMediaIndex))
    setCurrentMediaIndex(nextMediaIndex)
    setProgress(nextMediaIndex /  props.media.length + 1e-16)
  }
  const showPrevMedia = () => {
    const nextMediaIndex = ((currentMediaIndex - 1) + props.media.length) % props.media.length;
    setMaxLoadedMediaIndex(Math.max(nextMediaIndex + 1, maxLoadedMediaIndex))
    setCurrentMediaIndex(nextMediaIndex)
    setProgress(nextMediaIndex /  props.media.length + 1e-16)
  }

  // console.log(currentMediaIndex, progress, maxLoadedMediaIndex)
  const currentProgressForIndex = (index: number) => {
    return Math.min(1, Math.max(0, (progress - index / props.media.length) * props.media.length))
  }

  const updateProgress = () => {
    setProgress((progress) => {
      const newProgress = progress + updateInterval / (timePerMedia * props.media.length)
      return newProgress > 1 ? 0 : newProgress
    })
  }

  useEffect(() => {
    if (progress >= (currentMediaIndex + 1)/props.media.length || progress === 0) {
      showNextMedia()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  useEffect(() => {
    // when rerendering the parent component, the media urls are recreated and therefore react thinks that
    // the media list was changed when it wasn't. So before we actually reset the media playback, we verify that
    // it has really changed
    if (currentMedia.length !== props.media.length || props.media.some(m => currentMedia.indexOf(m) === -1)) {
      setCurrentMedia(props.media)
      setProgress(0)
      setCurrentMediaIndex(-1)
      setMaxLoadedMediaIndex(1)
      setTransitions(
        props.media.map(m => randomChoice(["zoomIn", "slideDown", "slideUp", "slideLeft", "slideRight"]))
      )
      // setFitTypes(
      //   props.media.map(m => randomChoice(["object-contain", "object-cover"]))
      // )
      // resetting the interval id will rerun the useEffect hook below and create a new interval
      setIntervalId(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.media])

  useEffect(() => {
    // reset the update progress interval. this is implemented in a different hook so that we can return a
    // clearInterval function that will clean the interval once the component is offloaded
    if (!intervalId && !isPaused) {
      let newIntervalId = setInterval(updateProgress, updateInterval);
      setIntervalId(newIntervalId)
    } else {
      return () => {
        clearInterval(intervalId);
        setIntervalId(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalId])

  const pause = () => {
    setIsPaused(true)
    clearInterval(intervalId)
    setIntervalId(undefined)
  }

  const play = () => {
    setIsPaused(false)
    let newIntervalId = setInterval(updateProgress, updateInterval);
    setIntervalId(newIntervalId)
  }

  const handleNavigateMedia = (e: React.KeyboardEvent) => {
    if (e.code === 'ArrowLeft') {
      showPrevMedia()
    } else if (e.code === 'ArrowRight') {
      showNextMedia()
    }
  }

  const variants = {
    zoomInInitial: {scale: 1, transition: {ease: "easeOut", duration: 4}},
    zoomIn: {scale: 1.05, transition: {ease: "easeOut", duration: 4}},
    zoomOutInitial: {scale: 1.05, transition: {ease: "easeOut", duration: 4}},
    zoomOut: {scale: 1, transition: {ease: "easeOut", duration: 4}},
    slideDownInitial: {scale: 1.2, transition: {ease: "easeOut", duration: 4}},
    slideDown: {scale: 1.15, translateY: 15, transition: {ease: "easeOut", duration: 4}},
    slideUpInitial: {scale: 1.2, transition: {ease: "easeOut", duration: 4}},
    slideUp: {scale: 1.15, translateY: -15, transition: {ease: "easeOut", duration: 4}},
    slideLeftInitial: {scale: 1.15, transition: {ease: "easeOut", duration: 4}},
    slideLeft: {scale: 1.2, translateX: -30, transition: {ease: "easeOut", duration: 4}},
    slideRightInitial: {scale: 1.15, transition: {ease: "easeOut", duration: 4}},
    slideRight: {scale: 1.2, translateX: 30, transition: {ease: "easeOut", duration: 4}},
  }

  return (
    <motion.div
      className="w-full h-full relative"
      tabIndex={-1}
      onKeyDown={handleNavigateMedia}
      {...swipeHandlers}
    >
      <div
        onClick={() => intervalId ? pause() : play()}
        className="absolute left-2 top-6 text-white z-30 cursor-pointer pointer-events-auto hover:text-primary hidden sm:block"
      >
        {
          intervalId ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
            </svg>
          )
        }
      </div>
      <div
        className="absolute flex flex-row space-x-2 flex-nowrap z-20 h-4 top-0 right-0 left-0 p-2
                  bg-gradient-to-b from-[#00000066] to-transparent pb-10 pointer-events-none">
        {
          props.media.length > 1 && range(0, props.media.length - 1).map(index => {
            return (
              <ProgressBar
                key={index}
                progress={currentProgressForIndex(index)}
                colorClass="bg-gray-200"
                backgroundColorClass="bg-gray-400"
                heightClass="h-[0.1rem]"
                showWhenEmpty
                smooth
              />
            )
          })
        }
      </div>
      {/*{*/}
      {/*  props.media.slice(0, maxLoadedMediaIndex+1).map((media, index) => {*/}
      {/*    return (*/}
      {/*      <img*/}
      {/*        key={media}*/}
      {/*        src={props.media[currentMediaIndex]} alt=""*/}
      {/*        onClick={showNextMedia}*/}
      {/*        className={`bg-gray-200 z-0 h-full w-full object-cover object-center overflow-hidden */}
      {/*                transition-all transform duration-300 absolute inset-0 cursor-pointer*/}
      {/*                filter blur-md opacity-100`}*/}
      {/*      />*/}
      {/*    )*/}
      {/*  })*/}
      {/*}*/}
      <div
        id="show-next"
        className="absolute right-0 top-0 bottom-0 w-[70%] z-20 cursor-pointer"
        onClick={showNextMedia}
      />
      <div
        id="show-prev"
        className="absolute left-0 top-0 bottom-0 w-[30%] z-20 cursor-pointer"
        onClick={showPrevMedia}
      />

      {
        props.media.slice(0, maxLoadedMediaIndex+1).map((media, index) => {
          const transition = index === currentMediaIndex ? transitions[currentMediaIndex] : `${transitions[index]}Initial`;
          return (
            <motion.img
              key={media}
              variants={variants}
              animate={transition}
              src={media} alt=""
              onClick={showNextMedia}
              className={`z-0 h-full w-full object-cover
                      object-center overflow-hidden 
                      transition-all transform duration-300 absolute inset-0 cursor-pointer
                        ${index === currentMediaIndex ? "opacity-100" : "opacity-0 select-none pointer-events-none"}`}
            />
          )
        })
      }

    </motion.div>
  );
};

export default StoryImageCarousel;
