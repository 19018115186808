import React, { FunctionComponent } from 'react';

interface OwnProps {
  isSelected?: boolean
  onClick?: () => void
}

type Props = OwnProps;

const MenuItem: FunctionComponent<Props> = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={`rounded-lg px-4 py-2 select-none cursor-pointer transition-all duration-300 
                  ${props.isSelected ? "text-primary bg-gray-50 font-semibold" : "text-gray-500 hover:text-black"}`}>
      {props.children}
    </div>
  );
};

export default MenuItem;
