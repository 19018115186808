import React, { FunctionComponent } from 'react';

interface OwnProps {
  className?: string
  height?: number
  onClick?: () => void
  hasShadow?: boolean
}

type Props = OwnProps;

const Logo: FunctionComponent<Props> = (props) => {
  return (
    <div className={`${props.className} ${props.onClick && "cursor-pointer"} text-primary`} onClick={props.onClick}>
      <svg height={props.height ?? 70} viewBox="0 0 236 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter={props.hasShadow ? "url(#filter0_d)" : ""}>
          <path fillRule="evenodd" clipRule="evenodd" d="M23.0474 27.5916L23.44 28.2722L23.8268 27.6017C24.0221 27.523 24.1963 27.3805 24.3153 27.1743L27.9736 20.8332C28.0189 20.7547 28.0521 20.674 28.0744 20.5926C28.7806 19.6377 29.1982 18.4564 29.1982 17.1775C29.1982 17.1168 29.1972 17.0562 29.1953 16.9959C29.1984 16.9177 29.2 16.8391 29.2 16.76C29.2 13.5788 26.6212 11 23.44 11C20.2588 11 17.68 13.5788 17.68 16.76C17.68 16.9266 17.6869 17.0911 17.7005 17.2535C17.7167 18.5032 18.1316 19.6567 18.8239 20.5927C18.8461 20.6741 18.8794 20.7548 18.9246 20.8332L22.5829 27.1743C22.697 27.372 22.8618 27.5111 23.0474 27.5916ZM32.8068 26.0442C32.9464 25.8023 33.0719 25.5506 33.1975 25.2988C33.3977 24.8976 33.598 24.496 33.8554 24.1332C35.332 22.0511 36.2 19.5069 36.2 16.76C36.2 9.71285 30.4871 4 23.44 4C16.3928 4 10.68 9.71285 10.68 16.76C10.68 19.5069 11.548 22.0511 13.0246 24.1332C13.282 24.4961 13.4823 24.8976 13.6824 25.2988C13.8081 25.5506 13.9336 25.8023 14.0732 26.0442L20.8414 37.7758C21.9959 39.7769 24.8841 39.7769 26.0386 37.7758L32.8068 26.0442Z" fill="currentColor"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M45 45.0492C45 45.0273 44.9981 45.0054 44.9944 44.9838C44.9583 44.775 44.7596 44.6363 44.5495 44.6636C43.8118 44.7598 43.4181 44.707 43.1885 44.6501C42.9075 44.5804 42.6177 44.4457 42.007 44.0515C41.8675 43.9614 41.7212 43.8646 41.5664 43.7621C40.2465 42.8884 38.313 41.6087 34.8 40.698C30.8158 39.665 27.3986 41.2778 25.0424 43.2436C24.6256 43.5914 24.2296 43.9594 23.8557 44.3404C23.4182 44.7862 22.6839 44.7761 22.2593 44.318C21.8968 43.927 21.5112 43.5472 21.1027 43.1866C18.7746 41.1307 15.3721 39.4429 11.2724 40.4679C7.67352 41.3676 5.67756 42.6278 4.29988 43.4976C4.13411 43.6023 3.97729 43.7013 3.82771 43.7933C3.18609 44.1882 2.8557 44.3399 2.52112 44.4193C2.41755 44.4439 2.29111 44.4665 2.13257 44.48C1.53235 44.5313 1 44.9692 1 45.5716V49.5321C1 50.057 1.40621 50.4986 1.93106 50.4924C2.61921 50.4843 3.27246 50.4077 3.90638 50.2572C5.1943 49.9516 6.18391 49.3884 6.97229 48.9033C7.14653 48.796 7.31814 48.688 7.49031 48.5796C8.76794 47.7754 10.0767 46.9514 12.7276 46.2887C14.2599 45.9057 15.6974 46.4179 17.1313 47.684C18.6022 48.983 19.7121 50.8022 20.2426 52.0401C20.7181 53.1496 21.8124 53.8661 23.0195 53.8582C24.2267 53.8504 25.3115 53.1198 25.7726 52.0041C26.2411 50.8704 27.3696 49.1161 28.8862 47.8507C30.3777 46.6063 31.8476 46.1309 33.2943 46.5059C35.8235 47.1617 37.053 47.973 38.2687 48.7752L38.269 48.7754C38.4296 48.8814 38.5899 48.9872 38.753 49.0924C39.5055 49.5782 40.4729 50.1585 41.7446 50.4738C42.4967 50.6603 43.2746 50.7359 44.1023 50.7127C44.6137 50.6984 45 50.2634 45 49.7518V45.0492Z" fill="currentColor"/>
          <path d="M111.059 14.5521C112.164 14.5521 113.059 15.4475 113.059 16.5521V48.2641C113.059 49.3686 112.164 50.2641 111.059 50.2641H104.115C103.011 50.2641 102.115 49.3686 102.115 48.2641V16.552C102.115 15.4475 103.011 14.5521 104.115 14.5521H111.059Z" fill="currentColor"/>
          <path d="M126.414 15.6978C126.414 16.842 128.141 17.4425 129.045 16.7404C129.592 16.3147 130.188 15.9266 130.83 15.576C132.708 14.5521 134.905 14.0401 137.422 14.0401C140.366 14.0401 143.033 14.7867 145.422 16.2801C147.812 17.7734 149.689 19.9067 151.054 22.6801C152.462 25.4534 153.166 28.6747 153.166 32.3441C153.166 36.0134 152.462 39.2561 151.054 42.0721C149.689 44.8454 147.812 47.0001 145.422 48.5361C143.033 50.0294 140.366 50.7761 137.422 50.7761C134.948 50.7761 132.75 50.2641 130.83 49.2401C129.265 48.3864 126.414 49.4871 126.414 51.2698V65.2881C126.414 66.3926 125.519 67.2881 124.414 67.2881H117.47C116.366 67.2881 115.47 66.3926 115.47 65.2881V16.5521C115.47 15.4475 116.366 14.5521 117.47 14.5521H125.269C125.902 14.5521 126.414 15.065 126.414 15.6978ZM142.03 32.3441C142.03 29.6134 141.262 27.4801 139.726 25.9441C138.233 24.3654 136.377 23.5761 134.158 23.5761C131.982 23.5761 130.126 24.3654 128.59 25.9441C127.097 27.5227 126.35 29.6774 126.35 32.4081C126.35 35.1387 127.097 37.2934 128.59 38.8721C130.126 40.4507 131.982 41.2401 134.158 41.2401C136.334 41.2401 138.19 40.4507 139.726 38.8721C141.262 37.2507 142.03 35.0747 142.03 32.3441Z" fill="currentColor"/>
          <path d="M169.916 50.7761C166.801 50.7761 164.028 50.2427 161.596 49.1761C159.164 48.1094 157.244 46.6587 155.836 44.8241C154.873 43.5406 154.2 42.1574 153.816 40.6745C153.524 39.5489 154.459 38.5521 155.622 38.5521H162.48C163.505 38.5521 164.31 39.3729 164.827 40.2577C165.114 40.7485 165.509 41.1826 166.012 41.5601C167.036 42.3281 168.295 42.7121 169.788 42.7121C171.153 42.7121 172.199 42.4561 172.924 41.9441C173.692 41.3894 174.076 40.6854 174.076 39.8321C174.076 38.8081 173.543 38.0614 172.476 37.5921C171.409 37.0801 169.681 36.5254 167.292 35.9281C164.732 35.3307 162.599 34.7121 160.892 34.0721C159.185 33.3894 157.713 32.3441 156.476 30.9361C155.239 29.4854 154.62 27.5441 154.62 25.1121C154.62 23.0641 155.175 21.2081 156.284 19.5441C157.436 17.8374 159.1 16.4934 161.276 15.5121C163.495 14.5307 166.119 14.0401 169.148 14.0401C173.628 14.0401 177.148 15.1494 179.708 17.3681C181.772 19.1274 183.138 21.3428 183.807 24.0143C184.095 25.1657 183.155 26.2001 181.968 26.2001H175.819C174.82 26.2001 174.029 25.4236 173.541 24.5518C173.263 24.0545 172.886 23.6226 172.412 23.2561C171.516 22.5307 170.321 22.1681 168.828 22.1681C167.548 22.1681 166.567 22.4241 165.884 22.9361C165.201 23.4054 164.86 24.0667 164.86 24.9201C164.86 25.9441 165.393 26.7121 166.46 27.2241C167.569 27.7361 169.276 28.2481 171.58 28.7601C174.225 29.4427 176.38 30.1254 178.044 30.8081C179.708 31.4481 181.159 32.5147 182.396 34.0081C183.676 35.4587 184.337 37.4214 184.38 39.8961C184.38 41.9867 183.783 43.8641 182.588 45.5281C181.436 47.1494 179.751 48.4294 177.532 49.3681C175.356 50.3067 172.817 50.7761 169.916 50.7761Z" fill="currentColor"/>
          <path d="M195.5 14.5521C196.604 14.5521 197.5 15.4475 197.5 16.5521V48.2641C197.5 49.3686 196.604 50.2641 195.5 50.2641H188.556C187.451 50.2641 186.556 49.3686 186.556 48.2641V16.552C186.556 15.4475 187.451 14.5521 188.556 14.5521H195.5Z" fill="currentColor"/>
          <path d="M222.754 50.2641C222.113 50.2641 221.512 49.9575 221.136 49.4396L214.473 40.2682C213.334 38.7007 210.855 39.5063 210.855 41.4437V48.2641C210.855 49.3686 209.959 50.2641 208.855 50.2641H201.911C200.806 50.2641 199.911 49.3686 199.911 48.2641V2.90405C199.911 1.79948 200.806 0.904053 201.911 0.904053H208.855C209.959 0.904053 210.855 1.79948 210.855 2.90405V23.0445C210.855 24.9701 213.309 25.7834 214.459 24.2389L221.071 15.3577C221.448 14.8508 222.043 14.5521 222.675 14.5521H230.92C232.613 14.5521 233.54 16.5244 232.46 17.8281L221.392 31.1859C220.775 31.9306 220.779 33.01 221.402 33.7499L232.535 46.9761C233.63 48.2769 232.705 50.2641 231.005 50.2641H222.754Z" fill="currentColor"/>
          <path d="M88.944 15.916C88.944 17.2726 90.9351 17.951 91.9907 17.0989C92.542 16.6538 93.1265 16.2529 93.744 15.8961C95.1057 15.0791 96.5748 14.5518 98.1511 14.3142C99.2433 14.1496 100.144 15.0635 100.144 16.1681V23.7521C100.144 24.8567 99.2486 25.7521 98.144 25.7521H97.136C94.4053 25.7521 92.3573 26.3494 90.992 27.5441C89.6267 28.6961 88.944 30.7441 88.944 33.6881V48.2641C88.944 49.3687 88.0486 50.2641 86.944 50.2641H80C78.8954 50.2641 78 49.3687 78 48.2641V16.5521C78 15.4475 78.8954 14.5521 80 14.5521H87.5801C88.3334 14.5521 88.944 15.1627 88.944 15.916Z" fill="currentColor"/>
          <path d="M73.728 40.984C74.8326 40.984 75.728 41.8795 75.728 42.984V48.264C75.728 49.3686 74.8326 50.264 73.728 50.264H70.16C66.192 50.264 63.0987 49.304 60.88 47.384C58.6613 45.4214 57.552 42.2427 57.552 37.848V25.64C57.552 24.5355 56.6566 23.64 55.552 23.64H55.2C54.0954 23.64 53.2 22.7446 53.2 21.64V16.552C53.2 15.4475 54.0954 14.552 55.2 14.552H55.552C56.6566 14.552 57.552 13.6566 57.552 12.552V2.84802C57.552 1.74345 58.4474 0.848022 59.552 0.848022H66.496C67.6006 0.848022 68.496 1.74345 68.496 2.84802V12.552C68.496 13.6566 69.3914 14.552 70.496 14.552H73.664C74.7686 14.552 75.664 15.4475 75.664 16.552V21.64C75.664 22.7446 74.7686 23.64 73.664 23.64H70.496C69.3914 23.64 68.496 24.5355 68.496 25.64V37.976C68.496 39.0427 68.752 39.8107 69.264 40.28C69.776 40.7494 70.6293 40.984 71.824 40.984H73.728Z" fill="currentColor"/>
          <circle cx="107.7" cy="7.09998" r="6" fill="currentColor"/>
          <circle cx="191.8" cy="7.09998" r="6" fill="currentColor"/>
        </g>
        <defs>
          <filter id="filter0_d" x="0" y="0.848022" width="234.009" height="68.44" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
        </defs>
      </svg>

    </div>
  );
};

export default Logo;
