import React, {FunctionComponent} from 'react';
import {useHistory} from "react-router-dom";
import Transition from "../../components/Transition";
import Trip from "../../models/Trip";
import {getFormattedDateRange} from "../../utils";
import {tripDiscoveryPath} from "../../helpers/constants";
import useStore from "../../stores/store";

interface OwnProps {
  coverImage?: string
  trip?: Trip
}

type Props = OwnProps;

const SmallTripCard: FunctionComponent<Props> = (props) => {
  const [setHoveredTripId] = useStore(store => [store.setHoveredTripId])
  const history = useHistory()

  const handleViewTrip = () => {
    setHoveredTripId(null);
    history.push(`${tripDiscoveryPath}?id=${props.trip?.id}`)
  }

  return (
    <Transition
      uniqueKey="trip-overview"
      type="slide-up"
      isVisible={true}
      // layoutId={props.trip?.id}
      className={`rounded-lg shadow-sm relative h-full w-full w-[300px] h-[170px] group bg-white
              cursor-pointer hover:shadow-lg transition-all transform duration-300 relative m-1`}
      onClick={handleViewTrip}
      onMouseEnter={() => setHoveredTripId(props.trip?.id ?? null)}
      onMouseLeave={() => setHoveredTripId(null)}
    >
      <div
        className={`absolute bottom-0 left-0 right-0 h-[30%] bg-gradient-to-b from-transparent rounded-lg
                  z-30 flex flex-col justify-end space-y-4 items-stretch p-4 to-[#000000aa]`}
      >
        <div className="flex flex-row justify-between text-white items-center">
          <div className="flex flex-col">
            <h2 className={`text-xl font-extrabold select-none text-shadow`}>
              {props.trip?.title}
            </h2>
            <h3 className={`text-md select-none text-shadow`}>
              {
                getFormattedDateRange(props.trip?.startDate?.toDate(), props.trip?.endDate?.toDate())
              }
            </h3>
          </div>
        </div>
      </div>
      <img
        className="absolute inset-0 object-cover object-center w-[100%] h-[100%] z-20 rounded-lg "
        src={props.coverImage ?? ""} alt="main"
      />
      <img
        className="absolute inset-0 w-[100%] h-[100%] object-cover object-center w-full h-full transition-all duration-300
        transform filter blur-md opacity-50 group-hover:translate-y-1 group-hover:opacity-100 bg-transparent"
        src={props.coverImage ?? ""} alt="shadow"
      />
    </Transition>
  );
};

export default SmallTripCard;
