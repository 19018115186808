import React, { FunctionComponent } from 'react';

interface OwnProps {
  className?: string;
  children: any;
  outlined?: boolean;
  onClick?: (e: React.MouseEvent) => void
  onMouseEnter?: (e: React.MouseEvent) => void
  onMouseLeave?: (e: React.MouseEvent) => void
  type?: "button" | "submit" | "reset"
  color?: string
  disabled?: boolean
  size?: "lg" | "sm"
}

type Props = OwnProps;

const Button: FunctionComponent<Props> = (props) => {
  let colorClasses = ""
  if (props.outlined) {
    colorClasses = "border-primary border text-primary bg-white hover:border-primary-dark " +
                    "hover:bg-primary-dark hover:text-white"
  } else {
    if (props.disabled) {
      colorClasses = "bg-gray-400 text-white border border-transparent"
    } else {
      colorClasses = "bg-primary text-white hover:bg-primary-dark hover:text-white border border-transparent"
    }
  }
  let sizeClasses = ""
  if (props.size === "sm") {
    sizeClasses = "px-4 py-1 text-sm"
  } else {
    sizeClasses = "px-6 py-2"
  }
  return (
    <button
      type={props.type ?? "button"}
      className={`${colorClasses} 
                tracking-normal rounded-full ${sizeClasses} cursor-pointer outline-none
                transition-all tranform shadow ${props.className} flex flex-row items-center justify-center space-x-2`}
      onClick={props.disabled ? () => {} : props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </button>
  );
};

export default Button;
