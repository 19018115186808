import React, { FunctionComponent } from 'react';

interface OwnProps {
  onSelect: () => void
  isActive: boolean
}

type Props = OwnProps;

const WaypointIndex: FunctionComponent<Props> = (props) => {
  return (
    <div
      onClick={props.onSelect}
      className={`absolute sm:relative rounded-full h-4 w-4 flex items-center justify-center z-20
                    text-sm ml-2 mt-2 sm:m-0 cursor-pointer select-none
                      ${props.isActive ? "bg-gradient-to-br from-purple-light/80 to-purple-extra-dark text-white p-[0.88rem]"
        : "p-3 border-2 border-purple-dark bg-white text-purple-dark"}`}
    >
      {props.children}
    </div>
  );
};

export default WaypointIndex;
