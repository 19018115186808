import React, {FunctionComponent} from 'react';
import LoginForm from "./LoginForm";
import NavigationBar from "../../components/NavigationBar";
import CTAButton from "../../components/CTAButton";
import {useHistory} from "react-router-dom";
import {signupPath} from "../../helpers/constants";

interface OwnProps {}

type Props = OwnProps;

const LoginPage: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  return (
    <div className="flex flex-col items-center justify-center h-screen w-screen overflow-y-scroll py-24">
      <NavigationBar>
        <CTAButton
          onClick={() => history.push(signupPath)}
          type="purple-outline"
        >Sign up</CTAButton>
      </NavigationBar>
      <LoginForm/>
    </div>
  );
};

export default LoginPage;
