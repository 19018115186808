import React, { FunctionComponent } from 'react';
import {AnimatePresence, motion} from "framer-motion";

interface OwnProps {
  visibleKey: string
  slides: { [key: string]: any }
  className?: string
  type?: "zoom-in" | "zoom-out" | "fade" | "slide-right" | "slide-left" | "slide-up" | "slide-down"
  duration?: number // in seconds
  onMouseEnter?: (e: React.MouseEvent) => void
  onMouseLeave?: (e: React.MouseEvent) => void
  reverseExit?: boolean
}

type Props = OwnProps;

const Carousel: FunctionComponent<Props> = (props) => {
  let scaleStart = 1;
  if (props.type === "zoom-in") {
    scaleStart = 0.9;
  } else if (props.type === "zoom-out") {
    scaleStart = 1.2;
  }

  let translateXStart = 0;
  if (props.type === "slide-right") {
    translateXStart = -100;
  } else if (props.type === "slide-left") {
    translateXStart = 100;
  }

  let translateYStart = 0;
  if (props.type === "slide-up") {
    translateYStart = 20;
  } else if (props.type === "slide-down") {
    translateYStart = -20;
  }

  const dir = (props.reverseExit ? -1 : 1);
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {
        Object.keys(props.slides).map((key) => {
          if (props.visibleKey === key) {
            return (
              <motion.div
                key={key}
                initial={{ opacity: 0, scale: scaleStart, translateX: translateXStart, translateY: translateYStart }}
                animate={{ opacity: 1, scale: 1, translateX: 0, translateY: 0 }}
                exit={{ opacity: 0, scale: scaleStart, translateX: dir*translateXStart, translateY: dir*translateYStart }}
                transition={{ ease: "easeInOut", duration: props.duration ?? 0.3 }}
                className={props.className}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                {props.slides[key]}
              </motion.div>
            )
          } else {
            return null;
          }
        })
      }
    </AnimatePresence>
  );
};

export default Carousel;
