import React, {FunctionComponent, useState} from 'react';
import Transition from "../../components/Transition";
import Avatar from "../../components/Avatar";
import UserMenuItem from "./UserMenuItem";
import {auth} from "../../firebase";
import {useHistory} from "react-router-dom";
import useStore from "../../stores/store";
import {
  bookmarksPath, createTripPath,
  Phase,
  settingsPath,
  tripDiscoveryPath,
  tripsikPhase,
  userTripsPath
} from "../../helpers/constants";
import {useLocation} from "react-use";
import useIsMobile from "../../hooks/useIsMobile";

interface OwnProps {
  className?: string
}

type Props = OwnProps;

const UserMenu: FunctionComponent<Props> = (props) => {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const [
    uid,
    user,
    resetUser,
    setCurrentEditedTripId,
    resetTrips
  ] = useStore(state => [
    state.uid,
    state.user,
    state.resetUser,
    state.setCurrentEditedTripId,
    state.resetTrips
  ])

  const isMobile = useIsMobile()
  const history = useHistory();
  const logout = () => {
    auth.signOut().then(() => {
      history.push("./")
      // we need to wait a bit for the auth service to update the user to undefined
      setTimeout(() => {
        resetUser()
        resetTrips()
      }, 1000)
    });
  }

  const handleCreateNewTrip = () => {
    setCurrentEditedTripId();
    history.push(createTripPath)
    setIsVisible(false)
  }

  const handleShowFeed = () => {
    history.push(tripDiscoveryPath)
    setIsVisible(false)
  }

  const handleShowMyTrips = () => {
    history.push(`${userTripsPath}?id=${uid}`)
    setIsVisible(false)
  }

  const handleShowBookmarks = () => {
    history.push(bookmarksPath)
    setIsVisible(false)
  }

  const handleShowSettings = () => {
    history.push(settingsPath)
    setIsVisible(false)
  }

  return (
    <div className={`relative ${props.className}`}>
      <div className="absolute right-4 top-4 z-50">
        <Avatar
          size="md"
          userId={uid}
          className="shadow-md hover:border-white border border-transparent hover:scale-105 cursor-pointer"
          onHover={(e, isHovered) => setIsVisible(isHovered)}
          onClick={() => setIsVisible(isMobile ? !isVisible : true)}
        />
      </div>
      <Transition
        uniqueKey="user-menu"
        className="absolute right-1 sm:right-0 left-1 sm:left-auto top-0 bg-white shadow-xl rounded-lg sm:w-80"
        type="zoom-in"
        isVisible={isVisible}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        <div className="h-20 pl-4 pt-4 flex flex-col">
          <div className="text-lg">
            {user?.name}
          </div>
          <div className="text-sm">
            {user?.email}
          </div>
        </div>
        <div
          className="w-full h-full p-4 grid grid-cols-3 gap-1"
        >
          {
            tripsikPhase >= Phase.DISCOVERY && (
              <UserMenuItem
                label="discover"
                icon={(
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                )}
                onClick={handleShowFeed}
              />
            )
          }
          <UserMenuItem
            label="my trips"
            isActive={location.pathname?.includes(userTripsPath)}
            icon={(
              <svg className="h-6 w-6" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.844 18H4.5C3.121 18 2 16.879 2 15.5C2 14.121 3.121 13 4.5 13H11.5C13.43 13 15 11.43 15 9.5C15 7.57 13.43 6 11.5 6H6.639C6.27175 6.71988 5.81697 7.39164 5.285 8H11.5C12.327 8 13 8.673 13 9.5C13 10.327 12.327 11 11.5 11H4.5C2.019 11 0 13.019 0 15.5C0 17.981 2.019 20 4.5 20H14.093C13.6023 19.3828 13.1833 18.7118 12.844 18ZM3 0C1.346 0 0 1.346 0 3C0 6.188 3 8 3 8C3 8 6 6.187 6 3C6 1.346 4.654 0 3 0ZM3 4.5C2.80295 4.49993 2.60785 4.46106 2.42582 4.38559C2.2438 4.31012 2.07842 4.19954 1.93913 4.06016C1.79984 3.92078 1.68937 3.75533 1.61403 3.57325C1.53868 3.39118 1.49993 3.19605 1.5 2.999C1.50007 2.80195 1.53894 2.60685 1.61441 2.42482C1.68988 2.2428 1.80046 2.07742 1.93984 1.93813C2.07922 1.79884 2.24467 1.68837 2.42675 1.61303C2.60882 1.53768 2.80395 1.49893 3.001 1.499C3.39896 1.49913 3.78056 1.65735 4.06187 1.93884C4.34317 2.22033 4.50113 2.60204 4.501 3C4.50087 3.39796 4.34265 3.77956 4.06116 4.06087C3.77967 4.34217 3.39796 4.50013 3 4.5Z" fill="currentColor"/>
                <path d="M17 12C15.346 12 14 13.346 14 15C14 18.188 17 20 17 20C17 20 20 18.187 20 15C20 13.346 18.654 12 17 12ZM17 16.5C16.803 16.4999 16.6078 16.4611 16.4258 16.3856C16.2438 16.3101 16.0784 16.1995 15.9391 16.0602C15.7998 15.9208 15.6894 15.7553 15.614 15.5733C15.5387 15.3912 15.4999 15.196 15.5 14.999C15.5001 14.802 15.5389 14.6068 15.6144 14.4248C15.6899 14.2428 15.8005 14.0774 15.9398 13.9381C16.0792 13.7988 16.2447 13.6884 16.4267 13.613C16.6088 13.5377 16.804 13.4989 17.001 13.499C17.399 13.4991 17.7806 13.6573 18.0619 13.9388C18.3432 14.2203 18.5011 14.602 18.501 15C18.5009 15.398 18.3427 15.7796 18.0612 16.0609C17.7797 16.3422 17.398 16.5001 17 16.5Z" fill="currentColor"/>
              </svg>
            )}
            onClick={handleShowMyTrips}
          />
          <UserMenuItem
            label="new trip"
            isActive={location.pathname?.includes(createTripPath)}
            icon={(
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} d="M12 4v16m8-8H4" />
              </svg>
            )}
            onClick={handleCreateNewTrip}
          />
          {
            tripsikPhase >= Phase.DISCOVERY && (
              <UserMenuItem
                label="bookmarks"
                icon={(
                  <svg className="h-6 w-6" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.4447 21.6364L21.5544 22.189C22.2192 22.5201 23.0002 22.0366 23.0002 21.2939V2.54545C23.0002 1.14545 21.8502 0 20.4447 0H7.65411C6.24855 0 5.11133 1.14545 5.11133 2.54545H17.8891C19.2947 2.54545 20.4447 3.69091 20.4447 5.09091V21.6364Z"
                      fill="currentColor"/>
                    <path
                      d="M9.53335 22.8022L8.94444 22.5508L8.35554 22.8022L1.5 25.7286V7.63627C1.5 7.0703 1.97282 6.59082 2.55556 6.59082H15.3333C15.9161 6.59082 16.3889 7.0703 16.3889 7.63627V25.7286L9.53335 22.8022Z"
                      stroke="currentColor" strokeWidth={3}/>
                  </svg>
                )}
                onClick={handleShowBookmarks}
              />
            )
          }
          <UserMenuItem
            label="settings"
            isActive={location.pathname?.includes(settingsPath)}
            icon={(
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            )}
            onClick={handleShowSettings}
          />
          <UserMenuItem
            label="log out"
            icon={(
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
            )}
            onClick={logout}
          />
        </div>
      </Transition>
    </div>
  );
};

export default UserMenu;
