import React, {FunctionComponent, useEffect, useState} from 'react';
import Button from "../../components/Button";
import useStore from "../../stores/store";

interface OwnProps {
}

type Props = OwnProps;

const SharePopover: FunctionComponent<Props> = (props) => {
  const [isCopied, setIsCopied] = useState(false);
  const trip = useStore(state => state.trip)

  const link = `https://tripsik.com/?id=${trip?.id}`

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(link)
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }
  return (
    <>
      <div>
        Share with anyone
      </div>
      <div className="bg-gray-100 border border-gray-200 rounded-md p-3 relative">
        {link}
        <div className="absolute right-1 top-2 bottom-2 flex items-center justify-center">
          <Button
            className="mt-0 text-sm"
            onClick={handleCopyLink}
          >
            {isCopied ? (
              <div className="flex space-x-1 items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                <div>
                  Copied
                </div>
              </div>
            ): "Copy"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SharePopover;
