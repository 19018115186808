import React, { FunctionComponent } from 'react';
import Heading from "../../components/Heading";
import Button from "../../components/Button";
import {useHistory} from "react-router-dom";
import {createTripPath} from "../../helpers/constants";

interface OwnProps {}

type Props = OwnProps;

const FirstTripCTA: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  return (
    <div className=" flex items-center justify-center h-full w-full bg-black/20">
      {/*CTA Card*/}
      <div className="bg-white rounded-lg p-10 shadow-xl flex flex-col items-center gap-4">
        <Heading priority={2}>
          Create your first trip
        </Heading>
        <Button onClick={() => history.push(createTripPath)}>
          Create trip
        </Button>
      </div>
    </div>
  );
};

export default FirstTripCTA;
