import React, {FunctionComponent, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import firebase from "firebase/app";
import SignInWithGoogleButton from "./SignInWithGoogleButton";
import {auth, db} from "../../firebase";
import Heading from "../../components/Heading";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import Card from "../../components/Card";
import Transition from "../../components/Transition";
import useStore from "../../stores/store";
import {homePath, emailVerificationPath, Phase, tripsikPhase, signupPath} from "../../helpers/constants";
import {useAuthState} from "react-firebase-hooks/auth";

interface OwnProps {}

type Props = OwnProps;

const LoginForm: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const setFBUser = useStore(state => state.setFBUser);
  const [user, isLoadingUser,] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<firebase.FirebaseError>();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Deal with a connected user that navigated to the login page
  useEffect(() => {
    if (user) {
      history.push(homePath);
    }
  }, [user]);

  if (isLoadingUser) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <Spinner className="text-primary"/>
      </div>
    )
  }

  const login = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    auth.signInWithEmailAndPassword(email, password).then(async (user) => {
      if (user.user) {
        try {
          setFBUser(user.user);
          if (!user.user?.emailVerified) {
            history.push(emailVerificationPath);
          } else {
            await db.doc(`/users/${user.user.uid}`).update({
              lastLoginDate: firebase.firestore.FieldValue.serverTimestamp(),
            });
            history.push(homePath);
          }
        } catch (error) {
          setLoading(false);
          setError(error);
        }
      }
    }).catch((error) => {
      setLoading(false);
      setError(error);
    });
  }

  const loginWithGoogle = () => {
    let provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider).then(async (result) => {
      try {
        const user = result.user;

        if (user) {
          const userPath = `/users/${user.uid}`;
          const snapshot = await db.doc(userPath).get();
          if (!snapshot.exists) {
            await db.doc(userPath).set({
              signUpDate: firebase.firestore.FieldValue.serverTimestamp(),
              planType: "free",
              email: user.email
            });
            setFBUser(user);
          } else {
            await db.doc(userPath).update({
              lastLoginDate: firebase.firestore.FieldValue.serverTimestamp(),
            });
          }
          history.push(homePath);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }).catch((error) => {
      setError(error);
      setLoading(false);
    });
  }

  return (
    <div className="max-w-screen-lg flex flex-col items-center justify-center w-full space-y-8 my-24">
      <Transition uniqueKey="title" type="slide-up" isVisible>
        <Heading>
          Let's log you in
        </Heading>
      </Transition>
      <Card>
        <form onSubmit={login} className="flex flex-col space-y-4 items-stretch">
          <div className="flex flex-col items-stretch space-y-1">
            <Input
              type="email"
              placeholder="Your email address"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
              autoFocus
              disabled={loading}
              error={error?.message}
              onResetError={() => setError(undefined)}
            />
          </div>
          <div className="flex flex-col items-stretch space-y-1">
            <Input
              type="password"
              placeholder="Your password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
              disabled={loading}
            />
          </div>
          <Button
            type="submit"
          >
            {
              loading ? (
                <>
                  <Spinner/>
                  <div>
                    loading
                  </div>
                </>
              ) : "Log in"
            }
          </Button>
          <div className="flex flex-row justify-center items-center">
            <div className="border-solid border-t border-gray-300 flex-1"/>
            <div className="flex flex-col items-center justify-center px-4 text-gray-500">
              or log in with
            </div>
            <div className="border-solid border-t border-gray-300 flex-1"/>
          </div>
          <div className="flex flex-row justify-center items-center">
            <SignInWithGoogleButton onClick={loginWithGoogle}/>
          </div>
        </form>
        <div className="text-sm text-center">
          <div>
            Don't have an account?
          </div>
          <div
            onClick={() => history.push(signupPath)}
            className="text-purple-dark cursor-pointer hover:text-purple"
          >
            Join now
          </div>
        </div>
      </Card>
    </div>
  );
};

export default LoginForm;
