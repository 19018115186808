import React, {FunctionComponent} from 'react';
import Logo from "./Logo";
import {useWindowScroll} from "react-use";
import {landingPagePath} from "../helpers/constants";

interface OwnProps {
  logoSize?: number
  onClickLogo?: () => void
}

type Props = OwnProps;

const NavigationBar: FunctionComponent<Props> = (props) => {
  const { y } = useWindowScroll();
  return (
    <div className={`absolute top-0 sm:top-4 left-0 right-0 w-screen h-24 transition-all duration-500 ${y > 10 && "shadow-weak"} 
    z-40 flex justify-between items-center px-4 mx-auto max-w-screen-lg bg-offWhite`}>
      <Logo height={props.logoSize ?? 40} onClick={props.onClickLogo ?? (() => window.location.assign(landingPagePath))}/>
      <div className="flex">
        {props.children}
      </div>
    </div>

  );
};

export default NavigationBar;
