import React, { FunctionComponent } from 'react';
import OverflowList from "../../components/OverflowList";
import ElementWithTooltip from "../../components/ElementWithTooltip";
import LabelTag from "./LabelTag";
import {removeFromArray} from "../../utils";

interface OwnProps {
  tags: Array<string>
  removable?: boolean
  onUpdateTags?: (newTags: Array<string>) => void
}

type Props = OwnProps;

const TagBar: FunctionComponent<Props> = (props) => {

  const handleTagRemove = (label: string) => {
    props.onUpdateTags && props.onUpdateTags(removeFromArray(props.tags, label))
  }

  return (
    <OverflowList
      itemRenderer={(label, index) => (
        <LabelTag
          key={index}
          label={label}
          removable={props.removable}
          onClick={props.removable ? () => handleTagRemove(label) : undefined}
        />
      )}
      items={props.tags}
      overflowRenderer={(items) => (
        <ElementWithTooltip
          className="px-3 py-1 whitespace-nowrap m-1 rounded-full text-sm cursor-pointer
                        flex items-center space-x-2 bg-gray-50 select-none"
          tooltipClassName="!text-black !bg-white border border-gray-100 flex flex-col items-start"
          allowHoverTooltip
          offsetY="1rem"
          tooltipAlign="right"
          tooltip={(
            items.map((label, index) => (
              <LabelTag
                key={index}
                label={label}
                removable={props.removable}
                onClick={props.removable ? () => handleTagRemove(label) : undefined}
              />
            ))
          )}
        >
          + {items.length} more
        </ElementWithTooltip>
      )}
    />
  );
};

export default TagBar;
