import React, {FunctionComponent, useEffect, useState} from 'react';
import FileUploader from "../../components/FileUploader";
import useFileStore from "../../stores/fileStore";
import {db} from "../../firebase";
import MediaFile from "../../models/MediaFile";
import {generateUuid} from "../../utils";
import {TripCreationStage} from "../../models/Trip";
import MediaUploaderCallToAction from "./MediaUploaderCallToAction";
import Transition from "../../components/Transition";
import TripNameForm from "./TripNameForm";
import {useHistory} from "react-router-dom"
import TripItineraryGenerator from "./TripItineraryGenerator";
import MediaGrid from "./MediaGrid";
import {useLocation} from "react-use";
import Spinner from "../../components/Spinner";
import Logo from "../../components/Logo";
import useStore from "../../stores/store";
import {createTripPath, homePath, tripViewerPath} from "../../helpers/constants";
import {preventPullToRefresh} from "../../helpers/utils";

interface OwnProps {}

type Props = OwnProps;

const TripMediaUploader: FunctionComponent<Props> = (props) => {
  const location = useLocation()
  const [
    uploadedFiles,
    setUploadedFiles,
    uploadProgress,
    setUploadProgress,
    removeFile,
    getFile
  ] = useFileStore(store => [
    store.uploadedFiles, store.setUploadedFiles, store.uploadProgress, store.setUploadProgress, store.removeFile, store.getFile
  ])
  const [
    uid,
    dbPath,
    storagePath,
    tripId,
    setTripId,
    currentTrip,
    loadTripFromDB
  ] = useStore(state => [
    state.uid,
    state.dbPath,
    state.storagePath,
    state.currentEditedTripId,
    state.setCurrentEditedTripId,
    state.trip,
    state.loadTripFromDB
  ])
  const [setFiles] = useFileStore(store => [store.setUploadedFiles])
  const [loading, setLoading] = useState(true);

  const history = useHistory()
  const [tripCreationStage, setTripCreationStage] = useState(TripCreationStage.INIT)

  // prevent pull to refresh
  useEffect(preventPullToRefresh, [])
  
  useEffect(() => {
    if (currentTrip && currentTrip.media) {
      setUploadedFiles(Object.fromEntries(currentTrip.media.map(m => [m.uniqueId, m])))
      setUploadProgress(Object.fromEntries(currentTrip.media.map(m => [m.uniqueId, 1])))
      setTripCreationStage(TripCreationStage.PHOTOS)
    }
  }, [currentTrip, setUploadProgress, setUploadedFiles])
  // console.log(uploadedFiles, uploadProgress)
  // initialize tripId if it is not already set
  useEffect(() => {
    if (uid) {
      const requestedTripId = new URLSearchParams(location.search).get("id")
      if (requestedTripId) {
        // a trip was requested. should be some trip the user has already started creating
        if (tripId !== requestedTripId) {
          setFiles({})
          setTripId(requestedTripId)

          console.log(uid, requestedTripId)
          loadTripFromDB(requestedTripId, true, uid).then(() => {
            setLoading(false)
          })
        } else {
          setLoading(false);
        }
      } else {
        // the user did not request a specific trip id. so either we have a trip creation already in progress,
        // and then we will show it. or otherwise, we create a new trip id
        let newTripId;
        if (tripId) {
          newTripId = tripId;
        } else {
          newTripId = generateUuid()
          setTripId(newTripId)
        }
        history.replace(`${createTripPath}?id=${newTripId}`)
        setLoading(false)
      }
    }
  }, [setFiles, uid, tripId, setTripId, location.search, loadTripFromDB, history])

  const handleUploadFinished = (uniqueId: string) => {
    const file = getFile(uniqueId)
    db.collection(`${dbPath}/trips/${tripId}/media`)
      .doc(file.storedFileName.split(".")[0])
      .set({
        url: file.url ?? null,
        storedFileName: file.storedFileName ?? null,
        name: file.name ?? null,
        type: file.type ?? null,
        size: file.size ?? null,
        lastModifiedDate: file.lastModifiedDate ?? null,
        storagePath: file.storagePath ?? null,
        location: file.location ?? null,
        creationDate: file.creationDate ?? null,
        uniqueId: file.uniqueId,
        exif: file.exif ?? null,
        thumbnailUrl: file.thumbnailUrl ?? null,
        thumbnailStoredFileName: file.thumbnailStoredFileName ?? null,
        thumbnailStoragePath: file.thumbnailStoragePath ?? null,
        thumbnailName: file.thumbnailName ?? null,
        thumbnailSize: file.thumbnailSize ?? null,
      } as MediaFile, {merge: true})
    if (tripCreationStage === TripCreationStage.INIT) {
      db.collection(`${dbPath}/trips`)
        .doc(tripId)
        .set({
          id: tripId,
          authorId: uid,
          stage: TripCreationStage.PHOTOS
        })
      setTripCreationStage(TripCreationStage.PHOTOS)
    }
  }

  const handleRemoveFile = (e: React.MouseEvent, uniqueId: string) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(uniqueId)
    db.collection(`${dbPath}/trips/${tripId}/media`)
      .doc(uniqueId)
      .delete()
    removeFile(uniqueId)
  }

  const handleCreateTrip = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    db.collection(`${dbPath}/trips`)
      .doc(tripId)
      .update({
        id: tripId,
        authorId: uid,
        stage: TripCreationStage.NAME
      })
    setTripCreationStage(TripCreationStage.NAME)
  }

  const handleSetTripName = (tripName: string) => {
    db.collection(`${dbPath}/trips`)
      .doc(tripId)
      .update({
        title: tripName,
        stage: TripCreationStage.ITINERARY_GENERATION
      })
    setTripCreationStage(TripCreationStage.ITINERARY_GENERATION)
    // history.push("./view")
  }

  const handleReturnToMediaUpload = () => {
    setTripCreationStage(TripCreationStage.PHOTOS)
  }

  const numFiles = Object.values(uploadedFiles).length
  let numFinishedFiles = 0;
  Object.keys(uploadedFiles).forEach(fileId => {
    if (uploadedFiles[fileId].url) {
      numFinishedFiles++;
    }
  })
  // const progressSum = Object.values(uploadProgress).reduce((s, p) => (s as number) + (p as number), 0) as number
  // const averageProgress = numFiles === 0 ? 0 : progressSum / numFiles;
  // console.log(uploadedFiles)

  return (
    <div className="absolute inset-0 flex flex-row items-center justify-center bg-gray-100 overflow-hidden sm:p-10">
      <Logo
        onClick={() => history.push(homePath)}
        height={30}
        className="self-start pr-10 hidden sm:block"
      />
      <div className="w-full h-full sm:p-10">
        <div className="w-full h-full bg-white border-t-2 border-b-2 border-white rounded-lg flex items-center justify-center relative overflow-hidden">
          {
            loading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <Spinner className="text-primary"/>
              </div>
            )
          }
          <div className="absolute inset-0 z-20">
            <FileUploader
              storageRootPath={`${storagePath}/trips/${tripId}/media`}
              forceUploadButton
              expand
              allowMultiple
              backgroundClickable
              onUpload={handleUploadFinished}
            >
              <MediaGrid
                mediaFiles={uploadedFiles}
                uploadProgress={uploadProgress}
                onRemoveFile={handleRemoveFile}
              />
              <Transition
                uniqueKey="uploader"
                className="z-30"
                isVisible={!loading && (tripCreationStage === TripCreationStage.PHOTOS || tripCreationStage === TripCreationStage.INIT)}
              >
                <MediaUploaderCallToAction
                  onNext={numFinishedFiles > 0 && numFinishedFiles === numFiles ? handleCreateTrip : undefined}
                  progress={numFinishedFiles/numFiles}
                  totalFiles={numFiles}
                  totalFinishedFiles={numFinishedFiles}
                />
                {/*<audio src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3" loop autoPlay controls/>*/}
              </Transition>
            </FileUploader>
          </div>

          <Transition
            uniqueKey="tripname"
            className="bg-opacity-25 z-30 absolute inset-0 backdrop-filter backdrop-blur-sm"
            isVisible={tripCreationStage === TripCreationStage.NAME}
          >
            <TripNameForm
              onReturn={handleReturnToMediaUpload}
              onCreateTrip={(tripName) => handleSetTripName(tripName)}
            />
          </Transition>

          <Transition
            uniqueKey="tripgenerator"
            className="bg-opacity-25 z-30 absolute inset-0 backdrop-filter backdrop-blur-sm"
            isVisible={tripCreationStage === TripCreationStage.ITINERARY_GENERATION}
          >
            <TripItineraryGenerator
              onGenerationFinished={() => history.push(`${tripViewerPath}?id=${tripId}`)}
            />
          </Transition>


        </div>
      </div>
      <div className="w-[108px] ml-10 hidden sm:block"/>
    </div>
  );
};

export default TripMediaUploader;
