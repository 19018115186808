import React, {FunctionComponent} from 'react';
import Spinner from "../../components/Spinner";
import ElementWithTooltip from "../../components/ElementWithTooltip";
import {motion} from 'framer-motion';
import {useMediaViewer} from "../../components/FullScreenMediaViewer";

interface OwnProps {
  preview: any
  url: string
  thumbnailUrl: string
  isLoading?: boolean
  onRemove: (e: React.MouseEvent) => void
  isLocationAvailable: boolean
}

type Props = OwnProps;

const MediaCard: FunctionComponent<Props> = (props) => {
  const setMediaAndShow = useMediaViewer(store => store.setMediaAndShow);
  const preview = props.preview;
  const url = props.url;
  const thumbnailUrl = props.thumbnailUrl;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setMediaAndShow(
      [url ?? preview],
      0
    )
  }
  if (preview || thumbnailUrl) {
    return (
      <div className="relative h-44 w-full rounded-lg pointer-events-auto group select-none">
        <div className="absolute w-full h-full overflow-hidden">
          <motion.img
            // layoutId={url ?? preview}
            onClick={handleClick}
            src={thumbnailUrl ?? preview}
            className="rounded-lg w-full h-44 object-cover object-center mb-2 select-none"
            alt=""
          />
        </div>
        {
          props.isLoading && (
            <>
              <div className="absolute inset-0 z-10 opacity-20 bg-black rounded-lg"/>
              <div className="absolute inset-0 z-20 flex items-center justify-center text-white">
                <Spinner/>
              </div>
            </>
          )
        }
        <div
          className="absolute right-2 top-2 rounded-full p-1 bg-opacity-50 bg-white text-gray-700 opacity-0
                                group-hover:opacity-100 cursor-pointer transition-all transform z-30"
          onClick={props.onRemove}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        {
          !props.isLocationAvailable && (
            <div className="absolute left-2 bottom-2 text-white z-30">
              <ElementWithTooltip
                tooltip="No location available"
              >
                <svg className="w-6 h-6" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.29871 0.238708C1.23005 0.165021 1.14725 0.105919 1.05525 0.0649274C0.963247 0.0239354 0.863934 0.00189351 0.763231 0.000116722C0.662528 -0.00166006 0.562499 0.0168645 0.469111 0.0545855C0.375723 0.0923065 0.290889 0.148451 0.21967 0.21967C0.148451 0.290889 0.0923065 0.375723 0.0545855 0.469111C0.0168645 0.562499 -0.00166006 0.662528 0.000116722 0.763231C0.00189351 0.863934 0.0239354 0.963247 0.0649274 1.05525C0.105919 1.14725 0.165021 1.23005 0.238708 1.29871L3.76471 4.82571C2.78438 6.38013 2.26559 8.18097 2.26871 10.0187C2.26871 14.0467 5.03571 18.4277 10.4987 23.2067L10.8757 23.5347C11.193 23.8083 11.5979 23.9589 12.0168 23.9593C12.4358 23.9596 12.8409 23.8097 13.1587 23.5367C15.0127 21.9437 16.5707 20.3957 17.8287 18.8897L22.7387 23.7997C22.8802 23.9363 23.0697 24.0118 23.2664 24.01C23.463 24.0082 23.6511 23.9292 23.7901 23.7901C23.9291 23.651 24.0079 23.4628 24.0095 23.2662C24.0111 23.0695 23.9354 22.8801 23.7987 22.7387L1.29871 0.238708ZM12.6547 13.7147C12.0631 13.8166 11.4557 13.7749 10.8835 13.5933C10.3113 13.4116 9.79115 13.0952 9.36666 12.6708C8.94218 12.2463 8.6258 11.7261 8.44414 11.1539C8.26248 10.5817 8.22083 9.97432 8.32271 9.38271L12.6547 13.7147Z" fill="currentColor"/>
                  <path d="M5.74071 2.55871L10.0237 6.84271C10.743 6.39103 11.5943 6.19691 12.4383 6.29213C13.2823 6.38735 14.069 6.76626 14.6696 7.36684C15.2702 7.96742 15.6491 8.7541 15.7443 9.59809C15.8395 10.4421 15.6454 11.2934 15.1937 12.0127L19.6287 16.4477C21.0527 14.2087 21.7687 12.0677 21.7687 10.0187C21.7687 4.63371 17.4037 0.268708 12.0187 0.268708C9.72103 0.265296 7.49656 1.0767 5.74071 2.55871Z" fill="currentColor"/>
                </svg>
              </ElementWithTooltip>
            </div>
          )
        }
      </div>
    )
  } else {
    return (
      <div className="rounded-lg bg-gray-100 w-full h-44 flex items-center justify-center">
        <Spinner className="text-gray-400"/>
      </div>
    )
  }
};

export default MediaCard;
