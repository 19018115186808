import React, { FunctionComponent } from 'react';
import MediaCard from "./MediaCard";

interface OwnProps {
  mediaFiles: any
  uploadProgress: any
  onRemoveFile: (e: React.MouseEvent, key: string) => void
}

type Props = OwnProps;

const MediaGrid: FunctionComponent<Props> = (props) => {
  return (
    <div className="absolute left-0 right-0 top-0 max-h-full z-10 p-4 sm:p-10 grid grid-cols-1 sm:grid-cols-4 gap-2 overflow-y-auto">
      {
        Object.keys(props.mediaFiles).map((key: string) => {
          const file = props.mediaFiles[key]
          return (
            <MediaCard
              key={key}
              preview={file.preview}
              url={file.url}
              thumbnailUrl={file.thumbnailUrl}
              isLoading={!file.url}
              onRemove={(e) => props.onRemoveFile(e, key)}
              isLocationAvailable={!!file.location}
            />
          )
        })
      }
    </div>
  );
};

export default MediaGrid;
