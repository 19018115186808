import React, { FunctionComponent } from 'react';

interface OwnProps {
  progress: number
  colorClass?: string
  backgroundColorClass?: string
  heightClass?: string
  showWhenEmpty?: boolean
  smooth?: boolean
}

type Props = OwnProps;

const ProgressBar: FunctionComponent<Props> = (props) => {
  return (
    <div className={`relative ${props.backgroundColorClass ?? "bg-gray-300"} ${props.heightClass ?? "h-2"} w-[80%] rounded-full transition-all transform 
                    ${props.progress === 0 && !props.showWhenEmpty ? "opacity-0" : "opacity-100"}`}>
      <div
        className={`absolute left-0 top-0 bottom-0 rounded-full 
                ${props.colorClass ?? "bg-blue-500"} ${props.smooth ? "duration-300 transition-all transform" : "duration-0"}`}
        style={{width: `${props.progress * 100}%`}}
      />
    </div>
  );
};

export default ProgressBar;
