export enum Phase {
  PERSONAL = 1,
  DISCOVERY = 2,
  PLANNING = 3
}

export const tripsikPhase: Phase = Phase.PERSONAL;

export const pathsPrefix = "";
export const addPathPrefix = (path: string) => `${pathsPrefix}/${path}`;
export const tripDiscoveryPath = addPathPrefix("");
export const loginPath = addPathPrefix("login");
export const signupPath = addPathPrefix("signup");
export const emailVerificationPath = addPathPrefix("verify");
export const createTripPath = addPathPrefix("create");
export const userTripsPath = addPathPrefix("user");
export const settingsPath = addPathPrefix("settings");
export const tripViewerPath = addPathPrefix("trip");
export const bookmarksPath = addPathPrefix("bookmarks");
export const homePath = tripsikPhase >= Phase.DISCOVERY ? tripDiscoveryPath : userTripsPath
export const landingPagePath = "https://tripsik.com"

