import React, {FunctionComponent, useState} from 'react';
import Logo from "../../components/Logo";
import {useHistory} from "react-router-dom";
import MenuItem from "./MenuItem";
import ProfileSettings from "./ProfileSettings";
import PrivacySettings from "./PrivacySettings";
import {homePath} from "../../helpers/constants";

interface OwnProps {}

type Props = OwnProps;

const Settings: FunctionComponent<Props> = (props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("profile");
  const menuItems = [
    {id: "profile", label: "Profile", page: <ProfileSettings/>},
    {id: "privacy", label: "Privacy", page: <PrivacySettings/>},
  ]
  const history = useHistory();
  return (
    <div className="absolute inset-0 flex flex-row items-center justify-center bg-offWhite overflow-hidden p-10">
      <Logo
        onClick={() => history.push(homePath)}
        height={30}
        className="self-start pr-10"
      />
      <div className="w-full h-full p-20 flex space-x-10">
        <div className="flex flex-col space-y-2 w-60 items-stretch">
          {
            menuItems.map(item => (
              <MenuItem
                key={item.id}
                onClick={() => setSelectedMenuItem(item.id)}
                isSelected={item.id === selectedMenuItem}
              >
                {item.label}
              </MenuItem>
            ))
          }
        </div>
        <div className="flex-grow px-10">
          {
            menuItems.find(i => i.id === selectedMenuItem)?.page
          }
        </div>
      </div>
      <div className="w-[108px] ml-10"/>
    </div>
  );
};

export default Settings;
