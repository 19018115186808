import React, { FunctionComponent } from 'react';
import {capitalize} from "../../utils";

interface OwnProps {
  feature: string
  imageUrl: string
  onClick: () => void
  isSelected: boolean
}


type Props = OwnProps;

const HighlightedFeatureCard: FunctionComponent<Props> = (props) => {
  return (
    <div
      key={props.feature}
      onClick={props.onClick}
      className={`relative cursor-pointer border-2 rounded-xl overflow-hidden select-none
                          transition-all transform hover:scale-[101%]
                        ${props.isSelected ? "border-white shadow-lg" : "border-transparent"}`}
    >
      <div
        className={`z-0 absolute inset-0 bg-black rounded-lg`}/>
      <img
        className={`z-10 absolute inset-0 rounded-lg overflow-hidden object-center object-cover h-full w-full max-w-[25rem]`}
        src={props.imageUrl}
        alt={props.feature}
      />
      <div
        className={`z-20 absolute bottom-0 left-0 right-0 text-white bg-gradient-to-t from-[#000000aa]
                        to-transparent p-4 rounded-lg ${props.isSelected ? "font-semibold" : "font-normal"}`}>
        {capitalize(props.feature)}
      </div>
      {
        props.isSelected && (
          <div className="absolute z-30 top-0 right-0 p-2 text-white hover:text-gray-200">
            <svg xmlns="http://www.w3.org/2000/svg"
                 className="h-6 w-6"
                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        )
      }
    </div>
  );
};

export default HighlightedFeatureCard;
