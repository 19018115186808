import {useEffect, useState} from "react";

const usePasswordValidation = (password: string = "") => {

  const [validLength, setValidLength] = useState(false);
  const [hasLetters, setHasLetters] = useState(false);
  const [hasNumbers, setHasNumbers] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setValidLength(password.length >= 8);
    setHasNumbers(/\d/.test(password));
    setHasLetters(/.*[a-zA-Z]/.test(password))
  }, [password])

  useEffect(() => {
    if (password.length === 0) {
      setError(null);
    } else if (!validLength) {
      setError("Must have at least 8 characters");
    } else if (!hasNumbers) {
      setError("Must have at least 1 number");
    } else if (!hasLetters) {
      setError("Must have at least 1 letter")
    } else {
      setError(null);
    }
  }, [validLength, hasNumbers, hasLetters, password.length])

  return [error]
}

export default usePasswordValidation;