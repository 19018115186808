import React, {FunctionComponent} from 'react';
import StoryImageCarousel from "../../components/StoryImageCarousel";
import ViewTripButton from "./ViewTripButton";
import Avatar from "../../components/Avatar";
import {useHistory} from "react-router-dom";
import Transition from "../../components/Transition";
import Trip from "../../models/Trip";
import {SwipeEventData} from "react-swipeable";
import {tripViewerPath, userTripsPath} from "../../helpers/constants";

interface OwnProps {
  highlightedMedia?: Array<string>
  trip?: Trip
  onSwiped: (eventDate: SwipeEventData) => void
}

type Props = OwnProps;

const TripCard: FunctionComponent<Props> = (props) => {
  const history = useHistory()

  const handleViewTrip = () => {
    history.push(`${tripViewerPath}?id=${props.trip?.id}`)
  }

  const highlightedMedia = (props.highlightedMedia ?? props.trip?.highlightedMedia) ?? []

  return (
    <Transition
      uniqueKey="trip-overview"
      type="slide-up"
      isVisible={true}
      layoutId={props.trip?.id}
      className="z-10 sm:rounded-lg shadow-lg bg-white relative h-full w-full overflow-hidden"
    >
      <div
        className="absolute bottom-0 left-0 right-0 h-[30%] bg-gradient-to-b from-transparent
                  to-black z-30 py-6 px-4 sm:p-10 flex flex-col justify-end space-y-4 items-stretch"
      >
        <div className="flex flex-row justify-between text-white items-end">
          <div className="flex flex-col space-y-4">
            <h2 className="text-3xl font-bold select-none">
              {props.trip?.title}
            </h2>
            {
              props.trip?.description && (
                <p className="">
                  {props.trip?.description}
                </p>
              )
            }
            <ViewTripButton
              onClick={handleViewTrip}
            />
          </div>
          <Avatar
            size="lg"
            userId={props.trip?.authorId}
            className="hover:scale-105"
            onClick={() => history.push(`${userTripsPath}?id=${props.trip?.authorId}`)}
          />
        </div>

      </div>
      {
        highlightedMedia.length > 0 && props.trip?.mediaOverview && props.trip.mediaOverview.urls && (
          <StoryImageCarousel
            media={highlightedMedia.map(m => props.trip?.mediaOverview?.urls[m] ?? "") ?? []}
            onSwiped={props.onSwiped}
          />
        )
      }
    </Transition>
  );
};

export default TripCard;
