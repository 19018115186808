let prevent = false;
let startPosition = 0;

export const initializeTouch = (e: any) => {
  if (e.touches.length !== 1) { return; }

  let scrollY = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
  prevent = (scrollY === 0);
  startPosition = e.touches[0].clientY;
}

export const updateTouch = (e: any) => {
  if (prevent && Math.abs(startPosition - e.touches[0].clientY) > 5) {
    prevent = false;
    e.preventDefault();
  }
}

export const preventPullToRefresh = () => {
  document.querySelector("html")?.addEventListener('touchstart', initializeTouch, {passive:false});
  document.querySelector("html")?.addEventListener('touchmove', updateTouch, {passive:false});
  return allowPullToRefresh;
}

export const allowPullToRefresh = () => {
  document.querySelector("html")?.removeEventListener('touchstart', initializeTouch);
  document.querySelector("html")?.removeEventListener('touchmove', updateTouch);
}