import { motion } from 'framer-motion';
import React, {FunctionComponent, useState} from 'react';

interface OwnProps {
  src?: string
  className?: string
  onClick?: () => void
  fit?: "height" | "width"
  maxSide?: string
  cropToSize?: string
}

type Props = OwnProps;

const AnimatedLazyImage: FunctionComponent<Props> = (props) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [pulsing, setPulsing] = useState(true);

  const imageLoaded = () => {
    setImageLoading(false);
    setTimeout(() => setPulsing(false), 100);
  };

  const maxSide = props.maxSide ?? "16rem";
  const fit = props.fit ?? "width"
  return (
    <div
      className={`${pulsing ? "animate-pulse" : ""} ${imageLoading ? "bg-gray" : ""} flex justify-center`}
    >
      <motion.img
        initial={{ height: maxSide, opacity: 0 }}
        animate={{
          height: fit === "height" ? undefined : imageLoading ? maxSide : props.cropToSize ?? "auto",
          opacity: imageLoading ? 0 : 1
        }}
        transition={
          [
            { height: fit === "height" ? undefined : { delay: 0, duration: 0.4 } },
            { opacity: { delay: 0.5, duration: 0.4 } }
          ]
        }
        onLoad={imageLoaded}
        width={fit === "width" ? "100%" : undefined}
        className={props.className}
        src={props.src}
        onClick={props.onClick}
      />
    </div>
  );
};

export default AnimatedLazyImage;
