import React, { FunctionComponent } from 'react';

interface OwnProps {
  onClick: () => void
}

type Props = OwnProps;

const ViewTripButton: FunctionComponent<Props> = (props) => {
  return (
    <div
      onClick={props.onClick}
      className="rounded-full border-2 border-white border-solid flex px-6 py-2 justify-center space-x-2
              text-white hover:bg-white hover:text-black cursor-pointer"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
      </svg>
      <div className="select-none">
        view trip
      </div>
    </div>
  );
};

export default ViewTripButton;
