import React, { FunctionComponent } from 'react';

interface OwnProps {}

type Props = OwnProps;

const PrivacySettings: FunctionComponent<Props> = (props) => {
  return (
    <div>

    </div>
  );
};

export default PrivacySettings;
