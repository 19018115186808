import React, {FunctionComponent, useEffect, useState} from 'react';
import TripDaySelector from "./TripDaySelector";
import {getFormattedDateRange, range} from "../../utils";
import ZoomOutButton from "./ZoomOutButton";
import StoryImageCarousel from "../../components/StoryImageCarousel";
import {useIntersection, useScroll} from "react-use";
import Avatar from "../../components/Avatar";
import Transition from "../../components/Transition";
import FacesBar from "./FacesBar";
import {useHistory} from "react-router-dom";
import WaypointsList from "./WaypointsList";
import Spinner from "../../components/Spinner";
import useIsMobile from "../../hooks/useIsMobile";
import useStore from "../../stores/store";
import {userTripsPath} from "../../helpers/constants";

interface OwnProps {
}

type Props = OwnProps;

const ItinerarySideBar: FunctionComponent<Props> = (props) => {
  const isMobile = useIsMobile()
  const [
    uid,
    trip,
    selectedWaypoint,
    setSelectedWaypoint,
    selectedDay,
    setSelectedDay,
    setScrollingToDay,
    setScrollingToWaypoint,
    userDetails,
    loadUserDetails
  ] = useStore(state => [
    state.uid,
    state.trip,
    state.selectedWaypoint,
    state.setSelectedWaypoint,
    state.selectedDay,
    state.setSelectedDay,
    state.setScrollingToDay,
    state.setScrollingToWaypoint,
    state.userDetails,
    state.loadUserDetails
  ])
  const history = useHistory()
  const scrollRef = React.useRef<any>(null);
  const scroll = useScroll(scrollRef)
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.7
  });
  const [showDaySelector, setShowDaySelector] = useState(false);

  // load the user details after the trip is loaded
  useEffect(() => {
    if (trip && !(trip.authorId in userDetails)) {
      loadUserDetails(trip.authorId)
    }
  }, [userDetails, loadUserDetails, trip])

  // show the day selector when the user scrolls down, and hide it when the user scrolls back up
  useEffect(() => {
    if (intersection) {
      setShowDaySelector(!intersection.isIntersecting)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersection])

  // reset map zoom and selected days when the user scrolls to the top manually
  useEffect(() => {
    if (scroll.y === 0) {
      setSelectedDay(0)
      setSelectedWaypoint(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll])

  const resetSelectedDay = () => {
    setSelectedWaypoint(-1)
    setScrollingToDay(0)
    scrollRef?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  // when a day was selected by the user, scroll the waypoints to the first in the requested day
  const handleSelectDay = (selectedDay: number) => {
    const selectedWaypoint = trip?.waypoints?.findIndex(waypoint => waypoint.tripDay === selectedDay) ?? 0;
    setScrollingToDay(selectedDay)
    setScrollingToWaypoint(selectedWaypoint)
    setSelectedDay(selectedDay)
    setSelectedWaypoint(selectedWaypoint)
  }

  let tripDates = getFormattedDateRange(trip?.startDate?.toDate(), trip?.endDate?.toDate());

  const urls = trip?.mediaOverview?.urls ?? {}

  const isAuthor = uid === trip?.authorId;

  return (
    <Transition
      uniqueKey="trip-overview"
      type={isMobile ? "slide-up" : "slide-right"}
      isVisible={true}
      className="absolute left-0 top-[500px] sm:bottom-0 sm:top-0 z-20 sm:bg-white sm:shadow-xl
                  overscroll-none w-full sm:w-[40%] overflow-hidden pointer-events-auto"
      drag={isMobile ? "y" : undefined}
      dragConstraints={{bottom: 0}}
    >
      <div className="overflow-y-hidden sm:overflow-y-scroll sm:h-screen overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300
                scrollbar-thumb-rounded scrollbar-track-transparent w-full overscroll-none">
        <div
          className="w-full sm:h-full overflow-x-hidden rounded-t-xl sm:rounded-none bg-white pointer-events-auto
                    overscroll-none sm:border-none shadow-xl sm:shadow-none"
          ref={scrollRef}
        >
          {
            trip && (
              <>
                <div id="drag-bar" className="block sm:hidden w-32 bg-gray-300 rounded-full h-1 m-auto mt-2"/>
                <div className="w-full h-[60vh] overflow-hidden hidden sm:block" ref={intersectionRef}>
                  <StoryImageCarousel
                    media={trip.highlightedMedia?.map(m => urls[m] ?? "") ?? []}
                  />
                </div>
                <div
                  className="justify-between flex flex-row px-4 py-6 sm:p-8"
                >
                  <div className="flex flex-row items-center">
                    <Avatar
                      userId={trip.authorId}
                      size="md"
                      onClick={() => history.push(`${userTripsPath}?id=${trip.authorId}`)}
                    />
                    <div className="flex flex-col ml-4">
                      <h3 className="text-2xl">{trip?.title}</h3>
                      <div className="text-sm">
                        by {userDetails[trip.authorId]?.name}
                      </div>
                    </div>
                  </div>
                  <div className="text-right flex flex-col space-y-1 justify-center">
                    <div>{tripDates}</div>
                    <h4 className="text-sm">{trip?.numDays} days</h4>
                  </div>
                </div>

                <div className="w-full px-8 py-2">
                  <FacesBar faces={trip.mediaOverview?.faces ?? []}/>
                </div>

                <div className={`absolute h-30 sm:top-4 right-4 left-0 flex items-start 
                ${showDaySelector ? "opacity-0  sm:opacity-100" : "opacity-0"} transition-all transform z-20 duration-500`}>
                  <div className="flex flex-row justify-center w-full items-center">
                    <ZoomOutButton
                      isVisible={selectedWaypoint !== -1}
                      onClick={resetSelectedDay}
                    />
                    <TripDaySelector
                      label={"Day"}
                      days={range(1, trip.numDays ?? 1)}
                      selectedDayIndex={selectedDay}
                      setSelectedDayIndex={handleSelectDay}
                    />
                    <div className="h-6 w-6"/>
                  </div>
                </div>
                <div className={`absolute top-0 right-0 left-0 h-28 bg-gradient-to-b from-white via-[#ffffffcc] to-[#ffffff00] z-10 
              pointer-events-none ${showDaySelector ? "opacity-0 sm:opacity-100" : "opacity-0"} transition-all transform duration-500`}/>
                {
                  trip.waypoints && trip.waypoints.length > 0 ? (
                    <WaypointsList
                      trip={trip}
                      scrollRef={scrollRef}
                      isAuthor={isAuthor}
                    />
                  ) : (
                    <div className="flex w-full justify-center items-center space-x-2 p-4">
                      <Spinner className="text-primary"/>
                      <div>
                        Loading Itinerary
                      </div>
                    </div>
                  )
                }
              </>
            )
          }

        </div>
      </div>



    </Transition>
  );
};

export default ItinerarySideBar;
