import React, {FunctionComponent, ReactNode} from 'react';
import useIsMobile from "../hooks/useIsMobile";

interface OwnProps {
  tooltip: string | ReactNode
  className?: string
  tooltipClassName?: string
  tooltipPosition?: "top" | "bottom"
  offsetY?: string
  offsetX?: string
  onClick?: (e: React.MouseEvent) => void
  onMouseDown?: (e: React.MouseEvent) => void
  onMouseEnter?: (e: React.MouseEvent) => void
  onMouseLeave?: (e: React.MouseEvent) => void
  tooltipAlign?: "left" | "center" | "right"
  allowHoverTooltip?: boolean
}

type Props = OwnProps;

const ElementWithTooltip: FunctionComponent<Props> = (props) => {
  const isMobile = useIsMobile()
  const dir = props.tooltipPosition === "top" ? "-" : "";
  const offsetY = dir + (props.offsetY ?? "0rem");
  const offsetX = (props.offsetX ?? "0rem");

  let containerOffset = "left-[50%]"
  let tooltipOffset = "left-[-50%]"
  if (props.tooltipAlign === "left") {
    containerOffset = "left-0"
    tooltipOffset = "left-0"
  } else if (props.tooltipAlign === "right") {
    containerOffset = "right-0"
    tooltipOffset = "right-0"
  }
  return (
    <div
      className={`${!isMobile && "has-tooltip"} relative ${props.className}`}
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <div className={`tooltip ${props.allowHoverTooltip && "tooltip-hoverable"} ${containerOffset}`}
           style={{top: `${dir}50%`, marginTop: offsetY, marginLeft: offsetX}}>
        <div className={`relative ${tooltipOffset} bg-gray-600 rounded shadow-lg px-2 py-1 
                        text-xs text-white whitespace-nowrap cursor-auto ${props.tooltipClassName}`}>
          {props.tooltip}
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default ElementWithTooltip;
