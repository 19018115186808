import React, {FunctionComponent, useState} from 'react';
import Transition from "../../components/Transition";
import ElementWithTooltip from "../../components/ElementWithTooltip";
import ToggleButton from "../../components/ToggleButton";
import useStore from "../../stores/store";
import {useHistory} from "react-router-dom";
import SharePopover from "../TripDiscovery/SharePopover";
import {usePopover} from "../../components/Popover";
import {Phase, tripDiscoveryPath, tripsikPhase} from "../../helpers/constants";

interface OwnProps {}

type Props = OwnProps;

const TripActionsBar: FunctionComponent<Props> = (props) => {
  const history = useHistory()
  const [
    trip,
    toggleBookmark,
    setCurrentTripPublicState,
    user,
    uid
  ] = useStore(state => [
    state.trip,
    state.toggleBookmark,
    state.setCurrentTripPublicState,
    state.user,
    state.uid
  ])
  const [showPopover] = usePopover(popover => [popover.showPopover])
  const isBookmarked = user?.bookmarks?.includes(trip?.id ?? "")
  const [publishCelebration, setPublishCelebration] = useState(false);

  const isAuthor = trip?.authorId === uid;

  const handlePublish = () => {
    if (!trip?.isPublic) {
      setPublishCelebration(true)
    } else {
      setPublishCelebration(false)
    }
    setCurrentTripPublicState(!trip?.isPublic)
  }

  return (
    <div className="fixed right-32 top-7 z-10">
      <Transition
        uniqueKey="trip-actions-bar"
        isVisible={true}
        className={`justify-between bg-white shadow-lg rounded-lg flex-row p-4
                    hidden sm:flex cursor-pointer items-center space-x-6`}
      >
        <ElementWithTooltip
          tooltipPosition="top"
          tooltip="Trip Highlights"
          offsetY="1rem"
          onClick={() => history.push(`${tripDiscoveryPath}?id=${trip?.id}`)}
          className="text-white sm:text-black cursor-pointer hover:text-primary  transition-all transform duration-300 flex"
        >
          <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 3V7V3ZM3 5H7H3ZM6 17V21V17ZM4 19H8H4ZM13 3L15.286 9.857L21 12L15.286 14.143L13 21L10.714 14.143L5 12L10.714 9.857L13 3Z" stroke="currentColor" strokeWidth={1.7} strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </ElementWithTooltip>
        <ElementWithTooltip
          tooltipPosition="top"
          tooltip="Share"
          offsetY="1rem"
          onClick={() => showPopover(<SharePopover/>)}
          className="text-white sm:text-black cursor-pointer hover:text-primary  transition-all transform duration-300 flex"
        >
          <svg className="h-6 w-6" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.62986 16.0653C9.86551 15.594 9.9985 15.062 9.9985 14.4997C9.9985 13.9374 9.86551 13.4055 9.62986 12.9342V16.0653ZM9.62986 16.0653C9.2772 16.7704 8.69682 17.3357 7.98274 17.6698C7.26866 18.0038 6.46271 18.087 5.69546 17.9058C4.92821 17.7247 4.2446 17.2897 3.75539 16.6715C3.26617 16.0534 3 15.2881 3 14.4997C3 13.7114 3.26617 12.9461 3.75539 12.3279C4.2446 11.7097 4.92821 11.2748 5.69546 11.0936C6.46271 10.9125 7.26866 10.9956 7.98274 11.3297C8.69682 11.6637 9.2772 12.2291 9.62986 12.9342V16.0653ZM9.62986 16.0653L17.3667 19.9337L9.62986 16.0653ZM9.62986 12.9342L17.3667 9.06576L9.62986 12.9342ZM17.3667 9.06576C17.5723 9.47687 17.8568 9.84346 18.2041 10.1446C18.5514 10.4457 18.9546 10.6755 19.3906 10.8208C19.8267 10.9662 20.2871 11.0242 20.7456 10.9916C21.2041 10.9589 21.6517 10.8363 22.0628 10.6307C22.4739 10.4251 22.8405 10.1406 23.1416 9.79333C23.4428 9.44606 23.6726 9.04288 23.8179 8.6068C23.9632 8.17073 24.0212 7.7103 23.9886 7.25181C23.956 6.79331 23.8334 6.34574 23.6278 5.93463C23.2126 5.10435 22.4845 4.47303 21.6038 4.17954C20.7232 3.88605 19.7619 3.95443 18.9317 4.36964C18.1014 4.78486 17.4701 5.51289 17.1766 6.39358C16.8831 7.27427 16.9515 8.23548 17.3667 9.06576V9.06576ZM17.3667 19.9337C16.9515 20.7642 16.8832 21.7255 17.1768 22.6063C17.4704 23.4871 18.1018 24.2152 18.9323 24.6304C19.7627 25.0456 20.724 25.114 21.6048 24.8204C22.4856 24.5268 23.2137 23.8953 23.629 23.0649C24.0442 22.2344 24.1125 21.2731 23.8189 20.3923C23.5253 19.5115 22.8938 18.7834 22.0634 18.3682C21.6522 18.1626 21.2045 18.04 20.746 18.0074C20.2874 17.9748 19.8269 18.0328 19.3908 18.1782C18.51 18.4718 17.7819 19.1033 17.3667 19.9337V19.9337Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </ElementWithTooltip>
        {
          tripsikPhase >= Phase.DISCOVERY && (
            <ElementWithTooltip
              tooltipPosition="top"
              tooltip={isBookmarked ? "Remove Bookmark" : "Add Bookmark"}
              offsetY="1rem"
              className="text-white sm:text-black cursor-pointer hover:text-primary  transition-all transform duration-300 flex"
              onClick={() => trip?.id && toggleBookmark(trip.id)}
            >
              {
                isBookmarked ? (
                  <svg className="h-6 w-6 text-primary" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 5.55556C5 4.87778 5.26925 4.22776 5.7485 3.7485C6.22776 3.26925 6.87778 3 7.55556 3H20.3333C21.0111 3 21.6611 3.26925 22.1404 3.7485C22.6196 4.22776 22.8889 4.87778 22.8889 5.55556V26L13.9444 21.5278L5 26V5.55556Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                ) : (
                  <svg className="h-6 w-6" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 5.55556C5 4.87778 5.26925 4.22776 5.7485 3.7485C6.22776 3.26925 6.87778 3 7.55556 3H20.3333C21.0111 3 21.6611 3.26925 22.1404 3.7485C22.6196 4.22776 22.8889 4.87778 22.8889 5.55556V26L13.9444 21.5278L5 26V5.55556Z" stroke="currentColor" strokeWidth={2.2} strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                )
              }
            </ElementWithTooltip>
          )
        }
        {
          isAuthor && (
            <ToggleButton
              isChecked={!!trip?.isPublic}
              checkedLabel={"your trip is public"}
              uncheckedLabel={"make your trip public"}
              checkedHoverLabel={"unpublish your trip"}
              onClick={handlePublish}
              size="sm"
              className={`w-44 bubbles ${publishCelebration && "animate"} ${trip?.isPublic && "shadow-lg-primary"}`}
              hoverClassName={trip?.isPublic ? "shadow-lg-primary hover:shadow-lg-red" : undefined}
            />
          )
        }
      </Transition>
    </div>
  );
};

export default TripActionsBar;
