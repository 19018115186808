import Waypoint from "./Waypoint";
import MediaFile from "./MediaFile";
import Transportation from "./Transportation";
import MediaOverview from "./MediaOverview";
import Comment from "./Comment";

export enum TripCreationStage {
  INIT = "INIT",
  PHOTOS  = "PHOTOS",
  NAME = "NAME",
  ITINERARY_GENERATION = "ITINERARY-GENERATION",
  ITINERARY = "ITINERARY",
  COMPLETE = "COMPLETE"
}

type Trip = {
  id: string
  numDays: number
  waypoints?: Array<Waypoint>
  transportation?: Array<Transportation>
  transportationStats?: any
  media?: Array<MediaFile>
  mediaOverview?: MediaOverview
  highlightedMedia?: Array<string>
  coverImage?: string
  title: string
  description?: string
  authorId: string
  startDate?: any
  endDate?: any
  stage: TripCreationStage
  route?: any
  routeWaypoints?: any
  durations?: Array<number>
  distances?: Array<number>
  path?: string
  comments?: {[key: string]: Comment}
  anonymize?: boolean
  isPublic?: boolean
}

export default Trip;