import React, {createRef, FunctionComponent, useEffect, useState} from 'react';
import WaypointCard from "./WaypointCard";
import TransportationCard from "./TransportationCard";
import Trip from "../../models/Trip";
import useStore from "../../stores/store";

interface OwnProps {
  trip: Trip
  scrollRef: any
  isAuthor: boolean
}

type Props = OwnProps;

const WaypointsList: FunctionComponent<Props> = (props) => {
  const [
    selectedWaypoint,
    setSelectedWaypoint,
    setSelectedDay,
    scrollingToDay,
    setScrollingToDay,
    scrollingToWaypoint,
    setScrollingToWaypoint,
    setShowCommentsForWaypoint,
    showCommentsForWaypoint,
  ] = useStore(state => [
    state.selectedWaypoint,
    state.setSelectedWaypoint,
    state.setSelectedDay,
    state.scrollingToDay,
    state.setScrollingToDay,
    state.scrollingToWaypoint,
    state.setScrollingToWaypoint,
    state.setShowCommentsForWaypoint,
    state.showCommentsForWaypoint,
  ])

  const [dayRefs, setDayRefs] = useState<any>([]);
  const [waypointRefs, setWaypointRefs] = useState<any>([]);

  // create refs for the waypoints that mark the beginning of each day
  useEffect(() => {
    if (props.trip && props.trip?.waypoints && dayRefs.length === 0) {
      const newDayRefs = Array.from(Array(props.trip?.numDays).keys()).map((_) => createRef());
      setDayRefs(newDayRefs)
      const numWaypoints = props.trip?.waypoints?.length ?? 0;
      let dayRefIndex = 0;
      for (let waypointIndex = 0; waypointIndex < numWaypoints; waypointIndex++) {
        if (waypointIndex === 0 || props.trip?.waypoints[waypointIndex].tripDay !== props.trip?.waypoints[waypointIndex - 1].tripDay) {
          waypointRefs.push(newDayRefs[dayRefIndex])
          dayRefIndex++;
        } else {
          waypointRefs.push(createRef())
        }
      }
      setWaypointRefs(waypointRefs)
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.trip])

  useEffect(() => {
    if (scrollingToWaypoint !== null && waypointRefs[scrollingToWaypoint]) {
      props.scrollRef?.current?.scrollTo({
        top: waypointRefs[scrollingToWaypoint].current?.offsetTop - 100,
        behavior: "smooth",
      })
      // waypointRefs[scrollingToWaypoint].current.scrollIntoView({behavior: "smooth", block: "center"});
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollingToWaypoint])

  const shouldShowPlaceTitle = (waypointIndex: number) => {
    if (props.trip?.waypoints) {
      const nextWaypoint = props.trip.waypoints[waypointIndex]
      const prevWaypoint = props.trip.waypoints[waypointIndex-1]
      if (nextWaypoint && nextWaypoint?.region && nextWaypoint?.region !== "" &&
        (waypointIndex === 0 || (prevWaypoint && nextWaypoint.region !== prevWaypoint.region))) {
        return true;
      }
    }
    return false;
  }

  // resets the scrolling parameters for the given waypoint and day indices
  const resetScrolling = (waypointIndex: number, dayIndex: number) => {
    if (scrollingToDay === dayIndex) {
      // set timeout is required since otherwise it keeps scrolling to the next waypoint
      setTimeout(() => setScrollingToDay(null), 300)
    }
    if (scrollingToWaypoint === waypointIndex) {
      // set timeout is required since otherwise it keeps scrolling to the next waypoint
      setTimeout(() => setScrollingToWaypoint(null), 300)
    }
  }

  // change selected day when waypoints have been scrolled
  const handleWaypointEnterIntoView = (waypointIndex: number, scroll?: boolean) => {
    const waypoint = props.trip?.waypoints && props.trip?.waypoints[waypointIndex];
    if (waypoint) {
      // ignore enter to view events while scrolling due to day selection by user
      // when reaching the requested day, turn off the event ignore by setting the scroll day to null
      // we check that scrollTop !== 0 so that it won't be called when the page is first loaded
      if (scrollingToDay === null && scrollingToWaypoint === null && props.scrollRef?.current?.scrollTop !== 0) {
        setSelectedDay(waypoint.tripDay)
        setSelectedWaypoint(waypointIndex)
        //
      }
      resetScrolling(waypointIndex, waypoint.tripDay)
    }
  }

  const handleWaypointExitFromView = (waypointIndex: number) => {
    const waypoint = props.trip?.waypoints && props.trip?.waypoints[waypointIndex];
    if (waypoint) {
      if (showCommentsForWaypoint === waypointIndex) {
        setShowCommentsForWaypoint(-1)
      }
      resetScrolling(waypointIndex, waypoint.tripDay)
    }
  };

  return (
    <div
      className="flex flex-col px-4 sm:px-10 py-4 sm:py-10 space-y-10 "
    >
      {
        props.trip.waypoints?.map((waypoint, i) => {
          return (
            <div ref={waypointRefs[i]} key={i} className="flex flex-col space-y-4">
              {
                shouldShowPlaceTitle(i) && (
                  <h2 className="text-2xl mb-4">
                    {waypoint.region}, {waypoint.country}
                  </h2>
                )
              }
              <WaypointCard
                index={i}
                isActive={i === selectedWaypoint}
                waypoint={waypoint}
                onSelect={() => handleWaypointEnterIntoView(i, true)}
                onEnterView={() => handleWaypointEnterIntoView(i)}
                onExitView={() => handleWaypointExitFromView(i)}
                lazyLoadingRoot={props.scrollRef.current}
                isAuthor={props.isAuthor}
              />
              {
                props.trip.waypoints && i < props.trip.waypoints.length - 1 && (
                  <TransportationCard
                    transportation={props.trip?.transportation && props.trip?.transportation[i]}
                    previousWaypoint={props.trip.waypoints[i]}
                    nextWaypoint={props.trip.waypoints[i+1]}
                    showPlaceTitle={shouldShowPlaceTitle(i+1)}
                  />
                )
              }
            </div>

          )
        })
      }
    </div>
  );
};

export default WaypointsList;
