import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import Comment from "../../models/Comment";
import CommentCard from "./CommentCard";
import CommentsInput from "./CommentsInput";
import {db} from "../../firebase";
import {generateUuid} from "../../utils";
import CommentSpinner from "./CommentSpinner";
import useStore from "../../stores/store";

interface OwnProps {
  waypointId?: string
}

type Props = OwnProps;

const CommentsForm: FunctionComponent<Props> = (props) => {
  const inputRef = useRef<any>(null);
  const [newCommentValue, setNewCommentValue] = useState("");
  const [
    uid,
    user,
    trip,
    isTripCommentsVisible,
    setIsTripCommentsVisible,
    showCommentsForWaypoint,
    setShowCommentsForWaypoint,
    loadTripComments
  ] = useStore(store => [
    store.uid,
    store.user,
    store.trip,
    store.isTripCommentsVisible,
    store.setIsTripCommentsVisible,
    store.showCommentsForWaypoint,
    store.setShowCommentsForWaypoint,
    store.loadTripComments
  ])
  const [isSendingComment, setIsSendingComment] = useState(false);

  useEffect(() => {
    if (showCommentsForWaypoint !== -1) {
      inputRef.current?.focus();
    }
  }, [showCommentsForWaypoint])

  useEffect(() => {
    if ((isTripCommentsVisible || showCommentsForWaypoint !== -1) && !trip?.comments) {
      loadTripComments()
    }
    if (!isTripCommentsVisible || showCommentsForWaypoint === -1) {
      setNewCommentValue("")
    }
    setIsSendingComment(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip, isTripCommentsVisible])


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSendingComment(true)

    // submit comment
    const commentsRef = db.doc(
      `users/${trip?.authorId}/trips/${trip?.id}/metadata/comments`
    )
    const comment: Comment = {
      id: generateUuid(),
      authorId: uid ?? "",
      authorName: user?.name ?? "",
      text: newCommentValue,
      creationDate: new Date(),
      waypointId: props.waypointId ?? ""
    }
    db.runTransaction((t) => {
      return t.get(commentsRef).then((doc) => {
        const obj = doc.get("comments") ?? {};
        obj[comment.id] = comment;
        t.set(commentsRef, { comments: obj }, {merge: true});
      })
    });
    setNewCommentValue("")
  }

  const handleClose = () => {
    setIsTripCommentsVisible(false)
    setShowCommentsForWaypoint(-1)
    setTimeout(() => setNewCommentValue(""), 500)
  }

  const currentComments = Object.values(trip?.comments ?? {})
    .filter(comment => !props.waypointId || comment.waypointId === props.waypointId)
    .sort((a, b) => a.creationDate < b.creationDate ? 1 : -1);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'Escape') {
      handleClose()
    }
  }

  return (
    <form className="p-4 flex flex-col h-full" onSubmit={handleSubmit}>
      <CommentsInput
        newCommentValue={newCommentValue}
        setNewCommentValue={setNewCommentValue}
        onClose={handleClose}
        inputRef={inputRef}
        onKeyDown={handleKeyDown}
        disabled={isSendingComment}
      />
      {
        isSendingComment || (currentComments && Object.keys(currentComments).length > 0) ? (
          <div className="flex flex-col py-4 space-y-4 items-start mt-4 overflow-y-auto h-full
                              scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-300 scrollbar-track-transparent">
            {
              isSendingComment && uid && (
                <CommentSpinner uid={uid}/>
              )
            }
            {
              currentComments.map((comment, index) => (
                <CommentCard
                  key={comment.id}
                  tripId={trip?.id ?? ""}
                  comment={comment}
                  tooltipPosition={index === 0 ? "bottom" : "top"}
                  showReferencedWaypoint={!props.waypointId}
                />
              ))
            }
          </div>
        ) : (
          <div className="flex-grow flex items-center justify-center text-gray-500">
            Nothing here yet
          </div>
        )
      }
    </form>
  );
};

export default CommentsForm;
