import React, {FunctionComponent, useEffect, useRef, useState} from 'react';

import {InteractiveMap} from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

import DeckGL from '@deck.gl/react'; // npm i --save-dev @danmarshall/deckgl-typings
// add the following lines to deck.gl/index.d.ts typings
// <reference path="../deck.gl__extensions/index.d.ts" />
//   export {
//     PathStyleExtension
//   } from "@deck.gl/extensions"
import {PathStyleExtension} from '@deck.gl/extensions';
import {bbox, points} from '@turf/turf';
import {ArcLayer, PathLayer} from '@deck.gl/layers';
import {FlyToInterpolator, ScatterplotLayer, TextLayer, WebMercatorViewport} from 'deck.gl';
import useWindowSize, {Size} from "../../hooks/useWindowSize";
import {RGBAColor} from "@deck.gl/core/utils/color";
import {nestedArrayIndexOf} from "../../utils";
import Waypoint from "../../models/Waypoint";
import {useLocation} from "react-use";
import useIsMobile from "../../hooks/useIsMobile";
import useStore from "../../stores/store";
import {userTripsPath} from "../../helpers/constants";
import {cyanColor, darkPurpleColor, primaryColor} from "../../helpers/theme";

// this line is needed, because otherwise the react-scripts transpiler does some stuff with the mapbox-gl package
// that make the map disappear in the production build and throws some errors.
// see https://stackoverflow.com/questions/71750627/create-react-app-excluding-mapbox-gl-explicitly-from-transpilation
(mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line

const MAPBOX_TOKEN = 'pk.eyJ1IjoiaXRhaWNhc3BpIiwiYSI6ImNrcnJtNm9lMzVtOW4yd3A4MnlmNHRsbnEifQ.bJ7CXiiLtDAgx48xxWgO5w';

interface OwnProps {
}

type Props = OwnProps;

const TripMap: FunctionComponent<Props> = (props) => {
  const isMobile = useIsMobile()
  const mapRef = useRef<any>(null)
  const location = useLocation()
  const [
    allTrips,
    trip,
    selectedWaypoint,
    setSelectedWaypoint,
    selectedDay,
    setSelectedDay,
    setScrollingToWaypoint,
    setScrollingToDay,
    hoveredTripId
  ] = useStore(state => [
    state.allTrips,
    state.trip,
    state.selectedWaypoint,
    state.setSelectedWaypoint,
    state.selectedDay,
    state.setSelectedDay,
    state.setScrollingToWaypoint,
    state.setScrollingToDay,
    state.hoveredTripId
  ])
  // console.log(selectedDay)
  const windowSize: Size = useWindowSize();
  const [isHovered, setIsHovered] = useState(false);
  const [layers, setLayers] = useState<any>([]);
  const waypoints = trip?.waypoints ?? [] as Array<Waypoint>;
  const isUserTripsPage = location.pathname?.includes(userTripsPath);
  const [viewport, setViewport] = useState({
    longitude: -24.247955730253693,
    latitude: 34.21185337251136,
    zoom: 10,
    maxZoom: 16,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1500,
    transitionInterpolator: new FlyToInterpolator()
  });

  const focusMap = () => {
    let newViewport = {
      width: windowSize.width,
      height: windowSize.height,
      longitude: 0,
      latitude: 20,
      zoom: viewport.zoom,
      maxZoom: 16,
      pitch: 0,
      bearing: 0,
      transitionDuration: 1500,
      transitionInterpolator: new FlyToInterpolator()
    };

    if (isUserTripsPage || !trip || !trip.route || !trip.routeWaypoints || trip.route.length === 0 || trip.routeWaypoints.length === 0) {
      newViewport.zoom = 1.5;
      setViewport(newViewport)
      return;
    }

    let webMercatorViewport = new WebMercatorViewport(newViewport);
    let box;
    if (isUserTripsPage || selectedWaypoint === -1) {
      // zoom over the entire map
      box = bbox(points(trip.routeWaypoints))
    } else {
      // zoom over a specific day
      box = bbox(points(waypoints.filter((w) => w.tripDay === selectedDay).map(w => w.coordinates)))
    }
    const bounds = [[box[2], box[3]], [box[0], box[1]]]
    let extraRightPadding = isMobile ? 30 : 130;
    let extraLeftPadding = isMobile ? 30 : 130;
    let extraBottomPadding = 30;
    let extraTopPadding = 30;
    if (!isMobile) {
      extraLeftPadding += !isMobile && location.pathname?.includes("/trip") ? (windowSize.width ?? 700) * 0.4 : 0
    } else {
      extraBottomPadding += location.pathname?.includes("/trip") ? (windowSize.height ?? 700) * 0.2 : 0
    }
    const fittedViewport = webMercatorViewport.fitBounds(
      bounds,
      {
        padding: {top: extraTopPadding, bottom: extraBottomPadding, right: extraRightPadding, left: extraLeftPadding}
      }
    ) as any
    newViewport.zoom = fittedViewport.zoom
    newViewport.latitude = fittedViewport.latitude
    newViewport.longitude = fittedViewport.longitude
    setViewport(newViewport)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(focusMap, [selectedWaypoint, trip, location])

  const fillLayers = (coords: any, routeWaypoints: any, layers: Array<any>, forSelectedDay?: boolean,
                      layerType?: "route" | "waypoints", durations?: Array<number>, layerPrefix?: string) => {
    if (trip) {
      const routeColor: RGBAColor = layerPrefix === hoveredTripId ? primaryColor : darkPurpleColor
      const flightColor: RGBAColor = [66, 197, 167]
      let dayPathStart = 0;
      let waypointIndex = 0;
      for (const waypoint of routeWaypoints) {
        const dayPathEnd = nestedArrayIndexOf(coords, waypoint)// coords.coordinates.indexOf(waypoint)
        let formattedCoords: any = [coords.slice(dayPathStart, dayPathEnd)];
        // formattedCoords = [subsample(formattedCoords[0], 2)]
        // console.log(formattedCoords[0].length, subsample(formattedCoords[0], 5).length)

        // console.log(waypointIndex)
        const isSelectedDay = isUserTripsPage || waypoints[waypointIndex]?.tripDay === selectedDay;

        if ((isSelectedDay && forSelectedDay) || (!isSelectedDay && !forSelectedDay)) {
          const opacity = isUserTripsPage || selectedWaypoint === -1 || waypoints[waypointIndex]?.tripDay === selectedDay ? 1 : 0.05;
          const waypointOpacity = isUserTripsPage || selectedWaypoint === -1 || waypoints[waypointIndex]?.tripDay === selectedDay ? 1 : 0.2;

          let formattedWaypoints;
          if (waypoints.length > 0 && !isUserTripsPage) {
            formattedWaypoints = [{
              index: waypointIndex,
              position: waypoints[waypointIndex].coordinates.concat([waypointIndex === selectedWaypoint ? 100 : 0])
            }]
          } else {
            formattedWaypoints = [{
              index: waypointIndex,
              position: routeWaypoints[waypointIndex]
            }]
          }

          // route path line
          if (layerType === "route") {
            const isFlight = waypointIndex > 0 && trip.transportation
              && trip.transportation.length > waypointIndex && trip.transportation[waypointIndex - 1].type === "flight";
            const flightCoords = [[coords[dayPathStart], coords[dayPathEnd-1]]]

            if (false && isFlight) {
              console.log(flightCoords, isFlight)
              layers.push(
                new ArcLayer({
                  id: `${layerPrefix}-route-${waypointIndex}`,
                  data: flightCoords,
                  pickable: true,
                  opacity: opacity,
                  getWidth: 5,
                  widthUnits: "pixels",
                  getHeight: () => 0,
                  greatCircle: true,
                  getSourcePosition: (d: any) => d[0],
                  getTargetPosition: (d: any) => d[1],
                  getSourceColor: () => flightColor,
                  getTargetColor: () => flightColor,
                })
              )
            } else {
              layers.push(
                new PathLayer({
                  id: `${layerPrefix}-route-${waypointIndex}`,
                  data: isFlight ? flightCoords : formattedCoords,
                  opacity: opacity,
                  widthUnits: "pixels",
                  getPath: (d: any) => d,
                  getColor: (d: any) => isFlight ? flightColor : routeColor,
                  getWidth: (d: any) => isFlight ? 5 : 8,
                  pickable: true,
                  // @ts-ignore
                  jointRounded: true,
                  capRounded: true,
                  getDashArray: (d: any) => isFlight ? [5, 5] : [10, 0],
                  extensions: [new PathStyleExtension({dash: true})],
                  // onHover: (info: any, event: any) => console.log("hover", info, event),
                })
              )
            }

          }

          // waypoint circle
          if (layerType === "waypoints" && !isUserTripsPage) {
            // const map = mapRef.current?.getMap();
            // if (map) {
            //   const projectedPoint = map.project(waypoints[waypointIndex].coordinates)
            //   const water = map.queryRenderedFeatures(
            //     [projectedPoint.x, projectedPoint.y],
            //     {layers: ['water']}
            //   )
            //   if (water.length > 0) {
            //     console.log(waypointIndex, water)
            //   }
            // }
            layers.push(
              new ScatterplotLayer({
                id: `${layerPrefix}-waypoints-${waypointIndex}`,
                data: formattedWaypoints,
                pickable: true,
                opacity: waypointOpacity,
                stroked: true,
                filled: true,
                radiusScale: 6,
                radiusMinPixels: 1,
                radiusMaxPixels: 100,
                lineWidthMinPixels: 1,
                radiusUnits: "pixels",
                lineWidthUnits: "pixels",
                getPosition: (d: any) => d.position,
                getRadius: (d: any) => 2.5,
                getLineWidth: (d: any) => 3,
                getFillColor: (d: any) => d.index === selectedWaypoint ? routeColor : [255, 255, 255],
                getLineColor: (d: any) => d.index === selectedWaypoint ? [255, 255, 255] : routeColor,
                onClick: (info: any, event: any) => {
                  setSelectedDay(waypoints[info.object.index].tripDay)
                  setScrollingToDay(waypoints[info.object.index].tripDay)
                  setSelectedWaypoint(info.object.index);
                  setScrollingToWaypoint(info.object.index)
                },
                updateTriggers: {
                  getFillColor: [selectedWaypoint]
                },
                onHover: (info: any, event: any) => setIsHovered(info.index !== -1),
              })
            )

            // waypoint number
            layers.push(
              new TextLayer({
                id: `${layerPrefix}-waypoints-text-${waypointIndex}`,
                data: formattedWaypoints,
                pickable: true,
                opacity: waypointOpacity,
                getPosition: (d: any) => d.position,
                getText: (d: any) => `${d.index + 1}`,
                getColor: (d: any) => d.index === selectedWaypoint ? [255, 255, 255] : routeColor,
                getSize: 16,
                getAngle: 0,
                getTextAnchor: 'middle',
                getAlignmentBaseline: 'center',
                fontFamily: 'Tahoma, Arial, sans-serif',
                getPixelOffset: [0, 1],
                onClick: (info: any, event: any) => {
                  if (waypoints) {
                    setSelectedDay(waypoints[info.object.index].tripDay)
                    setScrollingToDay(waypoints[info.object.index].tripDay)
                    setSelectedWaypoint(info.object.index);
                    setScrollingToWaypoint(info.object.index)
                  }
                },
                updateTriggers: {
                  getFillColor: [selectedWaypoint]
                },
                onHover: (info: any, event: any) => setIsHovered(info.index !== -1),
              })
            )
          }

        }

        dayPathStart = dayPathEnd + 1;
        waypointIndex++;
      }
    }
  }

  const handleSetLayers = (coords: any, routeWaypoints: any, durations?: Array<number>, baseLayers?: Array<any>, layerPrefix?: string) => {
    const layers: Array<any> = baseLayers ?? [];
    // first set the layers that are not for the current day, and then layer the current day layers on top of
    // them, such that the current day will be in focus
    fillLayers(coords, routeWaypoints, layers, false, "route", durations, layerPrefix)
    fillLayers(coords, routeWaypoints, layers, false, "waypoints", undefined, layerPrefix)

    // current day
    fillLayers(coords, routeWaypoints, layers, true, "route", durations, layerPrefix)
    fillLayers(coords, routeWaypoints, layers, true, "waypoints", undefined, layerPrefix)
    setLayers(layers)
    return layers;
  }

  const updateLayers = () => {
    if (location.pathname?.includes(userTripsPath)) {
      // Show all user trips
      let layers: Array<any> = [];
      allTrips?.forEach(trip => {
        layers = handleSetLayers(trip.route, trip.routeWaypoints, trip.durations, layers, trip.id)
      })
    } else {
      // Show a specific trip
      if (trip) {
        if (trip.route && waypoints.length > 1) {
          handleSetLayers(trip.route, trip.routeWaypoints, trip.durations, undefined, "")
        } else if (waypoints.length === 1) {
          // Corner case where a trip has only a single waypoint
          const coords = waypoints.map(waypoint => waypoint.coordinates)
          handleSetLayers(coords, coords, undefined, undefined, "")
        }
      }
    }
  }

  // update the layers on trip load or the waypoints coloring on selection
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateLayers, [trip, selectedWaypoint, selectedDay, location, hoveredTripId]);

  // if (!trip) {
  //   return <Spinner/>
  // }

  return (
    <DeckGL
      layers={layers}
      initialViewState={viewport}
      controller={{doubleClickZoom: false}} // removes delay for onClick events
      pickingRadius={5}
      getCursor={() => isHovered ? "pointer" : "grab"}
    >
      <InteractiveMap
        ref={mapRef}
        mapStyle="mapbox://styles/mapbox/light-v10"
        mapboxApiAccessToken={MAPBOX_TOKEN}
      />
    </DeckGL>
  );
};

export default TripMap;
