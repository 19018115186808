export const getUserPath = (
  userId: string | undefined
) => `users/${userId}`

export const getUserStoragePath = (
  userId: string | undefined
) => `/users/${userId}`

export const getUserTripsPath = (
  userId: string | undefined
) => `${getUserPath(userId)}/trips`

export const getTripPath = (
  userId: string | undefined,
  tripId: string | undefined
) => `${getUserPath(userId)}/trips/${tripId}`

export const getWaypointPath = (
  userId: string | undefined,
  tripId: string | undefined,
  waypointId: string | undefined
) => `${getTripPath(userId, tripId)}/waypoints/${waypointId}`

export const getCommentsPath = (
  userId: string | undefined,
  tripId: string | undefined
) => `${getTripPath(userId, tripId)}/metadata/comments`


export const getWaypointCommentsPath = (
  userId: string | undefined,
  tripId: string | undefined,
  waypointId: string | undefined
) => `${getWaypointPath(userId, tripId, waypointId)}/comments`

