import React, {FunctionComponent, useEffect, useState} from 'react';
import {motion, useAnimation} from "framer-motion";

interface OwnProps {
  center?: boolean
  animateOnceInEveryMilliseconds?: number
}

type Props = OwnProps;

const ShinyWrapper: FunctionComponent<Props> = (props) => {
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);

  useEffect(() => {
    if (props.animateOnceInEveryMilliseconds) {
      const timer = setInterval(
        () => {
          if (!isAnimationPlaying) {
            setIsAnimationPlaying(true);
          }
        },
        props.animateOnceInEveryMilliseconds)
      return () => clearInterval(timer);
    }
  }, []);


  const drawBottom = {
    hidden: { pathLength: 0, opacity: 0, pathOffset: 0.5 },
    visible: (i: number) => {
      const delay = i * 0.5;
      return {
        pathLength: [0, 0.15, 0, 0],
        pathOffset: [0.5, 0.5, 1, 1],
        opacity: [0, 1, 1, 0],
        transition: {
          pathLength: { delay, type: "spring", duration: 1, bounce: 0 },
          opacity: { delay, duration: 0.65 }
        }
      };
    }
  };

  const drawTop = {
    hidden: { pathLength: 0, opacity: 0, pathOffset: 0 },
    visible: (i: number) => {
      const delay = i * 0.5;
      return {
        pathLength: [0, 0.15, 0, 0],
        pathOffset: [0, 0, 0.5, 0.5],
        opacity: [0, 1, 1, 0],
        transition: {
          pathLength: { delay, type: "spring", duration: 1, bounce: 0 },
          opacity: { delay, duration: 0.65 }
        }
      };
    }
  };

  return (
    <motion.div
      className={`relative overflow-visible cursor-pointer ${props.center && "mx-auto"} select-none`}
      initial="hidden"
      animate={isAnimationPlaying ? "visible" : "hidden"}
      // whileHover="visible"  // if you want to play the animation only while the hovering is happening
      // whileTap="visible"
      onTapStart={() => {
        if (!isAnimationPlaying) {
          setIsAnimationPlaying(true);
        }
      }}
      onHoverStart={() => {
        if (!isAnimationPlaying) {
          setIsAnimationPlaying(true);
        }
      }}
      onAnimationComplete={() => {
        setIsAnimationPlaying(false);
      }}
    >
      {/*1px blur*/}
      <motion.svg
        style={{transform: "translate3d(0,0,0)"}}
        preserveAspectRatio="none"
        className="absolute left-0 top-0 h-full w-full z-[4] overflow-visible pointer-events-none blur-[1px]"
        viewBox="0 0 160 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/*Bottom*/}
        <motion.rect variants={drawBottom} width="100%" height="100%" rx="15" stroke="#C9E9FF" strokeWidth="2"/>

        {/*Top*/}
        <motion.rect variants={drawTop} width="100%" height="100%" rx="15" stroke="#C9E9FF" strokeWidth="2"/>
      </motion.svg>
      {/*16px blur*/}
      <motion.svg
        style={{transform: "translate3d(0,0,0)"}}
        preserveAspectRatio="none"
        className="absolute left-0 top-0 h-full w-full z-[3] overflow-visible pointer-events-none blur-[16px]"
        viewBox="0 0 160 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/*Bottom*/}
        <motion.rect variants={drawBottom} width="100%" height="100%" rx="15" stroke="#C9E9FF" strokeWidth="6"/>

        {/*Top*/}
        <motion.rect variants={drawTop} width="100%" height="100%" rx="15" stroke="#C9E9FF" strokeWidth="6"/>
      </motion.svg>
      {/*4px blur*/}
      <motion.svg
        style={{transform: "translate3d(0,0,0)"}}
        preserveAspectRatio="none"
        className="absolute left-0 top-0 h-full w-full z-[2] overflow-visible pointer-events-none blur-[4px]"
        viewBox="0 0 160 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/*Bottom*/}
        <motion.rect variants={drawBottom} width="100%" height="100%" rx="15" stroke="#C9E9FF" strokeWidth="3"/>

        {/*Top*/}
        <motion.rect variants={drawTop} width="100%" height="100%" rx="15" stroke="#C9E9FF" strokeWidth="3"/>
      </motion.svg>
      {/*46px blur*/}
      <motion.svg
        style={{transform: "translate3d(0,0,0)"}}
        preserveAspectRatio="none"
        className="absolute left-0 top-0 h-full w-full z-[2] overflow-visible pointer-events-none blur-[46px]"
        viewBox="0 0 160 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/*Bottom*/}
        <motion.rect variants={drawBottom} width="100%" height="100%" rx="15" stroke="#C9E9FF" strokeWidth="10"/>

        {/*Top*/}
        <motion.rect variants={drawTop} width="100%" height="100%" rx="15" stroke="#C9E9FF" strokeWidth="10"/>
      </motion.svg>
      {props.children}
    </motion.div>
  );
};

export default ShinyWrapper;
