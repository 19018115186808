import React, { FunctionComponent } from 'react';

interface OwnProps {
  active?: boolean
  className?: string
}

type Props = OwnProps;

const Card: FunctionComponent<Props> = (props) => {
  return (
    <div className={`flex flex-col space-y-8 bg-white rounded-lg p-8 shadow-xl w-80
            ${props.active && "border-primary border-2 border-solid"} ${props.className}`}>
      {props.children}
    </div>
  );
};

export default Card;
