import {GetState, SetState, State} from 'zustand'
import Trip from "../models/Trip";
import {db} from "../firebase";
import Waypoint from "../models/Waypoint";
import MediaFile from "../models/MediaFile";
import Comment from "../models/Comment";
import Transportation from "../models/Transportation";
import produce from "immer";
import MediaOverview from "../models/MediaOverview";
import {sortByFrequency, unique} from "../utils";
import User from "../models/User";
import {StoreInterface} from "./store";
import {
  getCommentsPath,
  getTripPath,
  getUserPath,
  getUserTripsPath,
  getWaypointCommentsPath,
  getWaypointPath
} from "../helpers/paths";


export interface tripSliceInterface extends State {
  hoveredTripId: string | null
  setHoveredTripId: (hoveredTripId: string | null) => void
  selectedDay: number
  setSelectedDay: (selectedDay: number) => void
  selectedWaypoint: number
  setSelectedWaypoint: (selectedWaypoint: number) => void
  selectedTripIndex: number
  setSelectedTripIndex: (selectedTripIndex: number) => void
  scrollingToDay: number | null
  setScrollingToDay: (scrollingToDay: number | null) => void
  scrollingToWaypoint: number | null
  setScrollingToWaypoint: (scrollingToWaypoint: number | null) => void
  showCommentsForWaypoint: number
  setShowCommentsForWaypoint: (showCommentsForWaypoint: number) => void
  showTextEditorForWaypoint: number
  setShowTextEditorForWaypoint: (showTextEditorForWaypoint: number) => void
  trip?: Trip,
  loadTripFromDB: (tripId: string, loadMedia?: boolean, userId?: string) => Promise<void>
  allTrips?: Array<Trip>
  setAllTrips?: (allTrips: any) => void
  loadUserTripsFromDB: (userId: string, defaultTripId?: string) => void
  setTripMedia: (media: Array<MediaFile>) => void
  currentComments: Array<Comment>
  loadCommentsForWaypoint: (waypointIndex: number) => void
  unsubscribeFromComments?: () => void,
  setCurrentComments: (currentComments: Array<Comment>) => void
  loadMediaOverview: (tripId: string, tripPath: string) => Promise<{tripId: string, highlightedMedia: any, mediaOverview: any} | undefined>
  highlightedMedia?: Array<string>
  setHighlightedMedia: (highlightedMedia?: Array<string>) => void
  selectedFeature?: string
  setSelectedFeature: (selectedFeature?: string) => void,
  getWaypointLabels: (waypoint: Waypoint, labelsType: "features" | "weather") => Array<string>
  setWaypointLabels: (waypoint: Waypoint, labelsType: "features" | "weather", labels: Array<string>) => void
  isTripCommentsVisible: boolean
  setIsTripCommentsVisible: (isTripCommentsVisible: boolean) => void
  loadTripComments: () => void,
  userDetails: {[userId: string]: User}
  loadUserDetails:  (userId: string) => void
  fullScreenSlideShow: boolean
  setFullScreenSlideShow: (fullScreenSlideShow: boolean) => void
  resetTrips: () => void
  setCurrentTripPublicState: (isPublic: boolean) => void
  setCurrentTripWaypointContent: (waypointIndex: number, description: string) => void
}



const tripSlice = (set: SetState<StoreInterface>, get: GetState<StoreInterface>) => ({
  hoveredTripId: null,
  setHoveredTripId: (hoveredTripId: string | null) => set({ hoveredTripId }),
  selectedDay: 0,
  setSelectedDay: (selectedDay: number) => set({ selectedDay }),
  selectedWaypoint: -1,
  setSelectedWaypoint: (selectedWaypoint: number) => {
    if (selectedWaypoint === -1 && get().showCommentsForWaypoint !== -1) {
      set({showCommentsForWaypoint: -1})
    }
    set({ selectedWaypoint })
  },
  selectedTripIndex: 0,
  setSelectedTripIndex: (selectedTripIndex: number) => {
    const allTrips = get().allTrips ?? [];
    set({
      selectedTripIndex,
      trip: allTrips[selectedTripIndex]
    })
  },
  scrollingToDay: null,
  setScrollingToDay: (scrollingToDay: number | null) => set({ scrollingToDay }),
  scrollingToWaypoint: null,
  setScrollingToWaypoint: (scrollingToWaypoint: number | null) => set({ scrollingToWaypoint }),
  showCommentsForWaypoint: -1,
  setShowCommentsForWaypoint: (showCommentsForWaypoint: number) => set({ showCommentsForWaypoint }),
  showTextEditorForWaypoint: -1,
  setShowTextEditorForWaypoint: (showTextEditorForWaypoint: number) => set({ showTextEditorForWaypoint }),
  trip: undefined,
  setTripMedia: (media: Array<MediaFile>) => {
    const trip = get().trip ? {...get().trip} as Trip : {} as Trip;
    trip.media = media;
    set({trip})
  },
  loadTripFromDB: async (tripId: string, loadMedia?: boolean, userId?: string) => {
    let tripSnapshot;
    if (userId) {
      const tripPath = getTripPath(userId, tripId);
      tripSnapshot = await db.doc(tripPath).get()
    } else {
      const tripsSnapshot = await db.collectionGroup(`trips`).where("id", "==", tripId).get()
      tripSnapshot = tripsSnapshot.docs[0]
    }
    set({
      selectedFeature: undefined,
      highlightedMedia: undefined
    })
    if (!tripSnapshot) return;
    const tripPath = tripSnapshot.ref.path;
    let trip = tripSnapshot.data() as Trip;

    console.log("trip load")
    if (trip) {
      trip.path = tripPath
      trip.route = trip.route && Object.values(trip.route)
      trip.routeWaypoints = trip.routeWaypoints && Object.values(trip.routeWaypoints)
      trip.waypoints = [];
      trip.transportation = [];

      // get waypoints
      const waypointsSnapshot = await db.collection(`${tripPath}/waypoints`)
        .orderBy("startDate", "asc")
        .get()
      waypointsSnapshot.docs.forEach(doc => {
        trip && trip.waypoints?.push(doc.data() as Waypoint)
      })

      // get transportation
      const transportationSnapshot = await db.collection(`${tripPath}/transportation`)
        .orderBy("startDate", "asc")
        .get()
      transportationSnapshot.docs.forEach(doc => {
        trip && trip.transportation?.push(doc.data() as Transportation)
      })
      // get media overview
      const result = await get().loadMediaOverview(trip.id, tripPath);
      if (result) {
        trip.mediaOverview = result.mediaOverview;
        trip.highlightedMedia = result.highlightedMedia;
      }
      set({trip})
    }
    // console.log(loadMedia)
    if (loadMedia) {
      const media: Array<MediaFile> = []
      const mediaSnapshot = await db.collection(`${tripPath}/media`)
        .orderBy("creationDate", "asc")
        .get();
      mediaSnapshot.docs.forEach(doc => {
        if (doc.id !== "overview") {
          media.push(doc.data() as MediaFile)
        }
      })
      get().setTripMedia(media)
    }
  },
  currentComments: [],
  loadCommentsForWaypoint: (waypointIndex: number) => {
    // listen to comment changes
    const trip = get().trip ?? {} as Trip;
    const waypointId = trip.waypoints ? trip.waypoints[waypointIndex].id : undefined
    const unsubscribeFromComments = db.collection(
      getWaypointCommentsPath(trip.authorId, trip.id, waypointId)
    ).orderBy("creationDate", "desc")
      .onSnapshot(snapshot => {
      console.log("waypoint comments load")
      let currentComments: Array<Comment> = [];
      snapshot.docs.forEach(doc => {
        currentComments.push(doc.data() as Comment)
      })
      set({currentComments})
    })
    set({unsubscribeFromComments})
  },
  unsubscribeFromComments: undefined,
  setCurrentComments: (currentComments: Array<Comment>) => set({currentComments}),
  loadUserTripsFromDB: (userId: string, defaultTripId?: string) => {
    console.log("trips load")
    const tripsPath = getUserTripsPath(userId)

    db.collection(tripsPath)
      .where("stage", "==", "COMPLETE")
      .orderBy("startDate", "asc")
      .get().then(async (snapshot) => {
      const allTrips: { [tripId: string]: Trip } = {}
      const mediaOverviewPromises: Array<Promise<{tripId: string, highlightedMedia: any, mediaOverview: any} | undefined>> = []
      snapshot.docs.forEach(doc => {
        const trip = doc.data() as Trip;
        if (trip) {
          trip.route = trip.route && Object.values(trip.route)
          trip.routeWaypoints = trip.routeWaypoints && Object.values(trip.routeWaypoints)
          trip.waypoints = [];
          mediaOverviewPromises.push(get().loadMediaOverview(trip.id, `${tripsPath}/${trip.id}`))
          allTrips[trip.id] = trip;
        }
      })

      for (const promise of mediaOverviewPromises) {
        const result = await promise;
        if (result) {
          const trip = allTrips[result.tripId];
          trip.highlightedMedia = result?.highlightedMedia;
          trip.mediaOverview = result?.mediaOverview;
        }
      }

      let defaultTrip = Object.values(allTrips)[0];
      defaultTrip = defaultTripId ? allTrips[defaultTripId] ?? defaultTrip : defaultTrip

      set({
        allTrips: Object.values(allTrips),
        trip: defaultTrip
      })
    });
  },
  loadMediaOverview: async (tripId: string, tripPath: string) : Promise<{tripId: string, highlightedMedia: any, mediaOverview: any} | undefined> => {
    // get media overview
    console.log("media overview load")
    const mediaSnapshot = await db.doc(`${tripPath}/media/overview`).get();
    if (mediaSnapshot.exists) {
      const mediaOverview = mediaSnapshot.data() as MediaOverview;
      let highlightedMedia;
      if (mediaOverview.labels) {
        const sortedMedia = Object.keys(mediaOverview.labels)
          .filter(l => mediaOverview.labels[l].quality && !mediaOverview.labels[l].pano)
          .sort((a, b) => {
            const aScore = mediaOverview.labels[a].quality[0];// - mediaOverview.labels[a].quality[1];
            const bScore = mediaOverview.labels[b].quality[0];// - mediaOverview.labels[b].quality[1];
            return bScore - aScore;
          })
        // TODO: prevent photos from the same waypoint
        highlightedMedia = unique(sortedMedia, (key) => key, 10)
      }

      return {
        tripId,
        mediaOverview,
        highlightedMedia
      }
    }
  },
  allTrips: undefined,
  setAllTrips: (allTrips: any) => set({allTrips}),
  highlightedMedia: undefined,
  setHighlightedMedia: (highlightedMedia?: Array<string>) => set({ highlightedMedia }),
  selectedFeature: undefined,
  setSelectedFeature: (selectedFeature?: string) => set({ selectedFeature }),
  getWaypointLabels: (waypoint: Waypoint, labelsType: "features" | "weather") => {
    if (waypoint.labels && waypoint.labels[labelsType]) {
      return waypoint.labels[labelsType];
    } else {
      const trip = get().trip;
      const labels: any = trip?.mediaOverview?.labels ?? {}
      let features = waypoint?.media.map((m: string) => labels[m] && labels[m][labelsType])
        .flat().filter(l => l !== undefined && l !== "")
      features = sortByFrequency(features ?? [])
      return features
    }
  },
  setWaypointLabels: (waypoint: Waypoint, labelsType: "features" | "weather", labels: Array<string>) => {
    const state = get();
    const trip = state.trip;
    const waypointIndex = state.trip?.waypoints?.findIndex(w => w.id === waypoint.id);
    if (waypointIndex !== undefined) {
      set(produce(state => {
        if (!state.trip.waypoints[waypointIndex].labels) {
          state.trip.waypoints[waypointIndex].labels = {}
        }
        state.trip.waypoints[waypointIndex].labels[labelsType] = labels

        db.doc(getWaypointPath(trip?.authorId, trip?.id, waypoint.id)).update({
          labels: state.trip.waypoints[waypointIndex].labels
        })
      }))
    }
  },
  isTripCommentsVisible: false,
  setIsTripCommentsVisible: (isTripCommentsVisible: boolean) => set({isTripCommentsVisible}),
  loadTripComments: () => {
    const store = get();
    if (store && store.unsubscribeFromComments) {
      store.unsubscribeFromComments();
    }
    console.log("comments load")
    const trip = store.trip;
    const unsubscribeFromComments =
      db.doc(getCommentsPath(trip?.authorId, trip?.id))
      .onSnapshot(snapshot => {
      if (snapshot.exists) {
        const comments = snapshot.data()?.comments ?? {}
        set(produce(state => {
          state.trip.comments = comments
        }))
      }
    })
    set({unsubscribeFromComments})
  },
  userDetails: {},
  loadUserDetails:  (userId: string) => {
    console.log("user load")
    db.doc(getUserPath(userId)).get().then(snapshot => {
      if (snapshot.exists) {
        set(produce(store => {
          store.userDetails[userId] = snapshot.data() as User;
        }))
      }
    })
  },
  fullScreenSlideShow: false,
  setFullScreenSlideShow: (fullScreenSlideShow: boolean) => set({fullScreenSlideShow}),
  resetTrips: () => {
    const store = get();
    if (store && store.unsubscribeFromComments) {
      store.unsubscribeFromComments();
    }
    set({
      selectedDay: 0,
      selectedWaypoint: -1,
      selectedTripIndex: 0,
      scrollingToDay: null,
      scrollingToWaypoint: null,
      showCommentsForWaypoint: -1,
      trip: undefined,
      currentComments: [],
      unsubscribeFromComments: undefined,
      allTrips: undefined,
      highlightedMedia: undefined,
      selectedFeature: undefined,
      isTripCommentsVisible: false,
      userDetails: {},
      fullScreenSlideShow: false
    })
  },
  setCurrentTripPublicState: (isPublic: boolean) => {
    const currentTrip = get().trip;
    const uid = get().uid
    db.doc(getTripPath(uid, currentTrip?.id)).update({
      isPublic: isPublic
    })
    set(produce(state => {
      state.trip.isPublic = isPublic;
    }))
  },
  setCurrentTripWaypointContent: (waypointIndex: number, content: string) => {
    const {trip, uid} = get()
    const waypoint = trip?.waypoints && trip?.waypoints[waypointIndex]
    if (waypoint) {
      db.doc(getWaypointPath(uid, trip?.id, waypoint?.id)).update({
        content: content
      })
      set(produce(state => {
        state.trip.waypoints[waypointIndex].content = content;
      }))
    }
  }
})

export default tripSlice;