import React, {FunctionComponent} from 'react';
import Transition from "./Transition";
import create, {GetState, SetState, State} from "zustand";

interface OwnProps {
}

type Props = OwnProps;

export interface PopoverInterface extends State {
  isVisible: boolean,
  children: any,
  showPopover: (children: any, title?: string | undefined) => void,
  hidePopover: () => void,
  title?: string | undefined
}

export const usePopover = create((
  set: SetState<PopoverInterface>,
  get: GetState<PopoverInterface>
) => ({
  isVisible: false,
  children: null,
  showPopover: (children: any, title?: string | undefined) => set({isVisible: true, children, title}),
  hidePopover: () => set({isVisible: false, children: null}),
}));

const Popover: FunctionComponent<Props> = (props) => {
  const {isVisible, children, hidePopover} = usePopover()
  return (
    <Transition
      uniqueKey="popover-background"
      isVisible={isVisible}
      className="absolute inset-0 z-[60] bg-opacity-50 bg-black flex items-start pt-10 justify-center pointer-events-auto"
      onClick={hidePopover}
    >
      <Transition
        uniqueKey="popover"
        type="slide-down"
        isVisible={isVisible}
        className="bg-white rounded-lg p-4 w-[90vw] sm:w-[40vw] flex flex-col space-y-3"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </Transition>
    </Transition>
  );
};

export default Popover;
