import React, {FunctionComponent} from 'react';
import {useScroll} from "react-use";
import LazyImage from "../../components/LazyImage";
import useStore from "../../stores/store";
import HorizontalScroller from "../../components/HorizontalScroller";
import {useMediaViewer} from "../../components/FullScreenMediaViewer";

interface OwnProps {
  media: Array<string>
  lazyLoadingRoot?: any
}

type Props = OwnProps;

const MediaGallery: FunctionComponent<Props> = (props) => {
  const trip = useStore(state => state.trip)
  const setMediaAndShow = useMediaViewer(store => store.setMediaAndShow);
  const scrollRef = React.useRef<any>(null);
  const {x} = useScroll(scrollRef);
  const maxScroll = scrollRef.current?.scrollWidth - scrollRef.current?.clientWidth;

  const handleScroll = (offset: number) => {
    scrollRef?.current?.scrollTo({
      left: scrollRef?.current?.scrollLeft + offset,
      behavior: "smooth",
    })
  }
  const mediaHeight = "300px";

  return (
    <>
      <div className={`rounded-lg overflow-hidden cursor-pointer group relative select-none`} style={{height: mediaHeight}}>
        <div
          onClick={() => handleScroll(-250)}
          className={`opacity-0 ${x > 0 ? "opacity-100 sm:opacity-0 group-hover:opacity-100 pointer-events-auto" : "pointer-events-none"} z-10
                    transition-all transform duration-300 flex items-center justify-start rounded-lg
                  bg-gradient-to-l from-transparent to-[#00000077] absolute left-0 top-0 bottom-0 w-[10%]`}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </div>
        {
          props.media.length > 1 && (
            <div
              onClick={() => handleScroll(250)}
              className={`opacity-0 ${x !== maxScroll ? "opacity-100 sm:opacity-0 group-hover:opacity-100 pointer-events-auto" : "pointer-events-none"} 
                      transition-all transform duration-300 flex items-center justify-end bg-opacity-75 z-10  rounded-lg
                      bg-gradient-to-r from-transparent to-[#00000077] absolute right-0 top-0 bottom-0 w-[10%]`}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </div>
          )
        }
        <HorizontalScroller className="flex flex-row overflow-x-scroll" ref={scrollRef}>
          {
            props.media.map((mediaId: string, index: number) => (
              <LazyImage
                key={mediaId}
                src={trip?.mediaOverview?.thumbnailUrls[mediaId] ?? ""}
                alt="gallery"
                style={{height: mediaHeight}}
                className={`w-full object-cover 
                          ${index === 0 && "rounded-l-lg"} ${index === props.media.length - 1 && "rounded-r-lg"}`}
                onClick={() => setMediaAndShow(
                  props.media.map(m => trip?.mediaOverview?.urls[m] ?? ""),
                  index
                )}
                root={props.lazyLoadingRoot}
                // loading="lazy"
              />
            ))
          }
        </HorizontalScroller>
      </div>
    </>
  );
};

export default MediaGallery;
