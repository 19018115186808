import React, {FunctionComponent} from 'react';
import Transition from "./Transition";

interface OwnProps {
}

type Props = OwnProps;

const InputErrorPopover: FunctionComponent<Props> = (props) => {
  return (
    <Transition
      className="z-30 relative"
      uniqueKey="error"
      type="zoom-in"
      isVisible
    >
      <div
        className="absolute ml-2 mt-[0.35rem] top-full bg-white p-4 shadow-lg rounded-md z-10
                            text-black text-sm border border-gray-300 outline-none">
        <div className="rotate-45 bg-white border-t border-l border-gray-300 rounded-sm
                              h-4 w-4 -top-2 absolute transform"/>
        {props.children}
      </div>
    </Transition>
  );
};

export default InputErrorPopover;
