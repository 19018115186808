import React, { FunctionComponent } from 'react';

interface OwnProps {
  priority?: 1 | 2 | 3 | 4 | 5
  className?: string
}

type Props = OwnProps;

const Heading: FunctionComponent<Props> = (props) => {
  const priority = props.priority ?? 1
  let Element = `h${priority}`
  let classNames = {1: "text-3xl", 2: "text-2xl", 3: "text-xl", 4: "text-xl", 5: "text-lg"};
  return (
    // @ts-ignore
    <Element className={`${classNames[priority]} ${props.className}`}>
      {props.children}
    </Element>
  );
};

export default Heading;
