import React, { FunctionComponent } from 'react';
import Input from "../Input";

interface OwnProps {
  newCommentValue: string
  setNewCommentValue: (value: string) => void
  inputRef: React.Ref<any>
  onClose: () => void
  onKeyDown?: (e: React.KeyboardEvent) => void
  disabled?: boolean
}

type Props = OwnProps;

const CommentsInput: FunctionComponent<Props> = (props) => {
  return (
    <div className="flex flex-row w-full items-center space-x-2">
      <Input
        type="text"
        value={props.newCommentValue}
        onChange={(e) => props.setNewCommentValue(e.target.value)}
        placeholder={props.disabled ? "Sending..." : "Write a comment..."}
        maxLength={280}
        expandWidth
        autoFocus
        ref={props.inputRef}
        onKeyDown={props.onKeyDown}
        disabled={props.disabled}
      />
      <svg xmlns="http://www.w3.org/2000/svg"
           onClick={props.onClose}
           className="h-6 w-6 text-gray-500 cursor-pointer hover:text-primary"
           fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
  );
};

export default CommentsInput;
