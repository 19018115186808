import React, {FunctionComponent, useState} from 'react';
import Button from "./Button";

interface OwnProps {
  className?: string;
  hoverClassName?: string;
  outlined?: boolean;
  isChecked: boolean
  onClick?: (e: React.MouseEvent) => void
  onHover?: (e: React.MouseEvent) => void
  type?: "button" | "submit" | "reset"
  color?: string
  disabled?: boolean
  size?: "lg" | "sm"
  checkedLabel: string
  uncheckedLabel: string
  checkedHoverLabel?: string
  uncheckedHoverLabel?: string
}

type Props = OwnProps;

const ToggleButton: FunctionComponent<Props> = (props) => {
  const [isHovered, setIsHovered] = useState(false)
  const [allowHover, setAllowHover] = useState(false);

  let label;
  if (props.isChecked) {
    if (isHovered && allowHover) {
      label = props.checkedHoverLabel ?? props.checkedLabel
    } else {
      label = props.checkedLabel
    }
  } else {
    if (isHovered) {
      label = props.uncheckedHoverLabel ?? props.uncheckedLabel
    } else {
      label = props.uncheckedLabel
    }
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
    setAllowHover(true)
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
    setAllowHover(props.isChecked)
  }

  return (
    <Button
      size={props.size}
      className={`mt-0 ${props.isChecked && allowHover && `hover:bg-red-400 ${props.hoverClassName}`} ${props.className}`}
      outlined={!props.isChecked}
      onClick={props.onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {label}
    </Button>
  );
};

export default ToggleButton;
