import React, {FunctionComponent} from 'react';
import Transition from "../../components/Transition";
import CommentsForm from "../../components/Comments/CommentsForm";
import useStore from "../../stores/store";

interface OwnProps {}

type Props = OwnProps;

const TripComments: FunctionComponent<Props> = (props) => {
  const [
    isTripCommentsVisible,
  ] = useStore(state => [
    state.isTripCommentsVisible,
  ])

  return (
    <Transition
      uniqueKey="trip-comments"
      isVisible={isTripCommentsVisible}
      type="zoom-in"
      className="absolute inset-0 z-[70] sm:z-20"
    >
      <div className="bg-white rounded-lg w-full h-full relative">
        <CommentsForm/>
      </div>
    </Transition>
  );
};

export default TripComments;
