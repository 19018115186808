import React, {FunctionComponent} from 'react';
import CommentsForm from "../../components/Comments/CommentsForm";
import useStore from "../../stores/store";

interface OwnProps {
  isVisible: boolean
}

type Props = OwnProps;

const CommentsDrawer: FunctionComponent<Props> = (props) => {

  const [
    selectedWaypoint,
    showCommentsForWaypoint,
    trip,
  ] = useStore(state => [
    state.selectedWaypoint,
    state.showCommentsForWaypoint,
    state.trip
  ]);

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //
  //   // submit comment
  //   const waypointId = trip?.waypoints && trip?.waypoints[selectedWaypoint].id;
  //   const commentRef = db.collection(
  //     `users/${trip?.authorId}/trips/${trip?.id}/waypoints/${waypointId}/comments`
  //   ).doc()
  //   const comment: Comment = {
  //     id: commentRef.id,
  //     authorId: uid ?? "",
  //     authorName: user?.name ?? "",
  //     text: newCommentValue,
  //     creationDate: new Date(),
  //   }
  //   commentRef.set(comment)
  //
  //   // update the comments count for the waypoint
  //   db.doc(`users/${trip?.authorId}/trips/${trip?.id}/waypoints/${waypointId}`)
  //     .update({numComments:  firebase.firestore.FieldValue.increment(1)});
  //   if (trip) {
  //     const waypoint = trip.waypoints && trip.waypoints[selectedWaypoint];
  //     if (waypoint) {
  //       if (waypoint.numComments) {
  //         waypoint.numComments++;
  //       } else {
  //         waypoint.numComments = 1;
  //       }
  //     }
  //   }
  //
  //   setNewCommentValue("")
  // }

  let waypointId = trip?.waypoints && trip?.waypoints[selectedWaypoint]?.id;

  return (
    <div
      className={`fixed sm:top-0 bottom-0 h-[60%] sm:h-[100%] sm:w-[30%] bg-white z-10 shadow-xl hidden sm:flex flex-col transition-all transform
                duration-500 items-stretch overflow-hidden overscroll-none
                ${showCommentsForWaypoint === -1 ? "left-[10%] opacity-0 pointer-events-none" : "left-[40%] opacity-100"}`}
    >
      <CommentsForm waypointId={waypointId}/>
    </div>
  );
};

export default CommentsDrawer;
